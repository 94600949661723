/* MUI */
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function About() {
  return (
    <div className="About">
      <main>
        <Container maxWidth="lg">
          <Typography
            variant="h1"
            className="slant-header"
            sx={{pt: 3}}
          >
            About FLD
          </Typography>
          <Typography
            variant="h2"
          >
            Data is a four letter word
          </Typography>
          <p>
            Love Data? Hate Data? F*Data
          </p>
          <p>
            FourLetterData tools can help verify that your website or app is performing correctly so users and search engines can use your site.
          </p>
          <p>
            FourLetterData.com is developed by <a href="https://www.linkedin.com/in/the-kevin-montgomery/" target="_blank" rel="noreferrer">Kevin Montgomery</a>.
          </p>
          <p>
            Check out my <a href="https://github.com/myawesomebike" target="_blank" rel="noreferrer">github</a> for more crawling, NLP, and API integration tools.
          </p>
        </Container>
      </main>
    </div>
  );
}

export default About;
