import * as React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

function Footer() {
  return (
    <Box sx={{
      height: '3.25rem',
      backgroundColor: 'rgba(230,230,230,1)',
    }}>
      <Container maxWidth="lg">
        <Stack
          spacing={0.5} 
          direction="column"
        >
          <Stack
            spacing={0.5} 
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ flexGrow: 1,  }}
          >
            <MenuItem
              component={Link}
              to="/"
            >
              <Typography
                textAlign="center"
                sx={{
                  textDecoration: 'none',
                  fontSize: '0.9rem',
                  lineHeight: '2.5rem'
                }}
              >
                Home
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/terms"
            >
              <Typography
                textAlign="center"
                sx={{
                  textDecoration: 'none',
                  fontSize: '0.9rem',
                  lineHeight: '2.5rem'
                }}
              >
                Terms
              </Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              to="/about"
            >
              <Typography
                textAlign="center"
                sx={{
                  textDecoration: 'none',
                  fontSize: '0.9rem',
                  lineHeight: '2.5rem'
                }}
              >
                About
              </Typography>
            </MenuItem>
            <Typography
              textAlign="center"
              sx={{
                textDecoration: 'none',
                fontSize: '0.75rem',
              }}
            >
              &copy; 2024 FourLetterData
            </Typography>
            </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default Footer;
