import React, { useContext, useState } from 'react';
import { UserContext } from "../../global/context";
import { updateUserPreferences } from "../../api/user";
/* MUI */
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
/* Icons */
import AbcIcon from '@mui/icons-material/Abc';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CodeIcon from '@mui/icons-material/Code';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import StarsIcon from '@mui/icons-material/Stars';
/* MUI Colors */
import { grey } from '@mui/material/colors';
import { red } from '@mui/material/colors';
/* HTML Tags */
import defaultExtractors from '../../global/defaultExtractors.json';
import htmlAttributes from '../../global/htmlAttributes.json';
import htmlTags from '../../global/htmlTags.json';

function AccountExtractors() {
  const [user, setUser] = useContext(UserContext);
  const [extractorLabel, setExtractorLabel] = useState("");
  const [newHTMLTag, setNewHTMLTag] = useState("");
  const [showFilterEdit, setShowFilterEdit] = useState(false);
  const [newAttributeFilter, setNewAttributeFilter] = useState("");
  const [newAttributeValue, setNewAttributeValue] = useState("");
  const [attributeFilters, setAttributeFilters] = useState([]);
  const [dataExtractionType, setDataExtractionType] = useState("content");
  const [attributeMatch, setAttributeMatch] = useState("");

  const handleFilterAdd = () => {
    setAttributeFilters([
      ...attributeFilters,
      {
        field: newAttributeFilter,
        value: newAttributeValue,
      }
    ]);
    setNewAttributeFilter('');
    setNewAttributeValue('');
    setShowFilterEdit(false);
  };

  const handleFilterDelete = (removeFilter) => {
    setAttributeFilters([
      ...attributeFilters.filter((filter) => !(filter.field === removeFilter.field && filter.value === removeFilter.value))
    ]);
  };

  const handleNewExtractor = () => {
    const addedExtractor = {
      id: `custom-${extractorLabel.replace(" ", "_")}`,
      label: extractorLabel,
      tag: newHTMLTag,
      extractionType: dataExtractionType,
      attributeMatch: attributeMatch,
      attributeFilters: attributeFilters,
    }
    if(user.preferences && user.preferences.extractors) {
      user.preferences.extractors = [
        ...user.preferences.extractors, addedExtractor];
    }
    else {
      user.preferences['extractors'] = [addedExtractor];
    }
    updateUserPreferences(
      user.userid,
      user.preferences,
    )
    .then((data) => {
      if(data.status === 'success') {
        setUser({
          ...user,
          preferences: data.user.preferences,
        });
        setExtractorLabel("");
      }
    });
  };

  const handleDeleteExtractor = (extractorId) => {
    if(user.preferences && user.preferences.extractors) {
      user.preferences.extractors = user.preferences.extractors.filter((extractor) => {
        return extractor.id !== extractorId;
      });
      updateUserPreferences(
        user.userid,
        user.preferences,
      )
      .then((data) => {
        if(data.status === 'success') {
          setUser({
            ...user,
            preferences: data.user.preferences,
          });
          setExtractorLabel("");
        }
      });
    }
  };

  return (
    <>
      {user.loggedIn ? (
        <Box>
          <Box sx={{m: 2}}>
            <Stack direction="row">
              <ManageSearchIcon color="primary" />
              <Typography variant="h6">Custom HTML Extractors</Typography>
            </Stack>
            <Typography variant="p" sx={{fontSize: 14}}>
              Create a new extractor with the HTML tag you want to get data from. You can add additional filters for the tag's attributes and pull data from the tag's content or it's attributes.
            </Typography>
          </Box>
          <Box
            sx={{
              px: 2,
              py: 3,
              backgroundColor: grey[200],
              boxShadow: "inset 0px 5px 10px rgba(100,100,100,0.25)",
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1} sx={{mb: 3}}>
              <TextField
                error={user?.preferences?.extractors?.find((extractor) => extractor.label === extractorLabel)}
                label="Label"
                value={extractorLabel}
                size="small"
                sx={{
                  width: 250,
                  backgroundColor: grey[50],
                  borderRadius: 1,
                  boxShadow: "0px 5px 10px rgba(100,100,100,0.25)",
                }}
                onChange={(e) => setExtractorLabel(e.target.value || "")}
              />
              <ToggleButtonGroup
                color="primary"
                value={dataExtractionType}
                exclusive
                size="small"
                onChange={(e, dataType) => dataType !== null && setDataExtractionType(dataType)}
                sx={{backgroundColor: grey[50], boxShadow: "0px 5px 10px rgba(100,100,100,0.25)"}}
              >
                <ToggleButton value="content">
                  <AbcIcon />
                  Content
                </ToggleButton>
                <ToggleButton value="all">
                  <CodeIcon />
                  All Attributes
                </ToggleButton>
                <ToggleButton value="attribute">
                  <ManageSearchIcon />
                  Specific Attribute
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{height: 40}}>
              <Box sx={(theme) => ({display: 'inline', fontWeight: 900, color: theme.palette.primary.main})}>
                {`<`}
              </Box>
              <Autocomplete
                freeSolo
                clearOnBlur
                value={newHTMLTag}
                options={htmlTags}
                getOptionLabel={(option) => {return option.label || option || ""}}
                onChange={(e, newValue) => {
                  if (typeof newValue === 'string') {
                    setNewHTMLTag(newValue);
                  }
                  else if (newValue && newValue.inputValue) {
                    setNewHTMLTag(newValue.inputValue);
                  }
                  else if (newValue && newValue.label) {
                    setNewHTMLTag(newValue.label);
                  }
                }}
                onInputChange={(e, newValue) => {
                  setNewHTMLTag(newValue);
                }}
                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                size="small"
                renderInput={(params) => 
                  <TextField
                    placeholder="any tag"
                    variant="standard"
                    {...params}
                    sx={(theme) => ({
                      input: {
                        color: theme.palette.primary.main,
                        fontWeight: 900
                      }
                    })}
                  />
                }
                sx={{
                  width: 120,
                  m: 0.5,
                }}
              />
              {attributeFilters.map((filter, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  size="small"
                  onDelete={() => handleFilterDelete(filter)}
                  label={
                    <Box>
                      {`${filter.field}="${filter.value}"`}
                    </Box>
                  }
                />
              ))}
              {showFilterEdit ? (
                <Chip
                  variant="outlined"
                  size={showFilterEdit ? "large" : "small"}
                  avatar={showFilterEdit
                    ? <CancelIcon onClick={() => setShowFilterEdit(!showFilterEdit)} />
                    : <AddCircleIcon onClick={() => setShowFilterEdit(!showFilterEdit)} />
                  }
                  sx={{
                    backgroundColor: grey[50],
                    boxShadow: "0px 5px 10px rgba(100,100,100,0.25)",
                  }}
                  label={showFilterEdit ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Autocomplete
                        freeSolo
                        clearOnBlur
                        value={newAttributeFilter}
                        options={htmlAttributes}
                        getOptionLabel={(option) => {return option.label || option || ""}}
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            setNewAttributeFilter(newValue);
                          }
                          else if (newValue && newValue.inputValue) {
                            setNewAttributeFilter(newValue.inputValue);
                          }
                          else if (newValue && newValue.label) {
                            setNewAttributeFilter(newValue.label);
                          }
                        }}
                        onInputChange={(e, newValue) => {
                          setNewAttributeFilter(newValue);
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        size="small"
                        sx={{ width: 120}}
                        renderInput={(params) => 
                          <TextField
                            placeholder="attribute"
                            variant="standard"
                            size="small"
                            {...params}
                          />
                        }
                      />
                      <Typography>
                        =
                      </Typography>
                      <TextField
                        variant="standard"
                        placeholder="value"
                        value={newAttributeValue}
                        size="small"
                        sx={{width: 80}}
                        onChange={(e) => setNewAttributeValue(e.target.value || "")}
                      />
                      <IconButton
                        sx={{p: 0}}
                        disabled={newAttributeFilter === '' || newAttributeValue === ''}
                        onClick={handleFilterAdd}
                        color="primary"
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Stack>
                  ) : ""}
                  clickable
                  onClick={() => !showFilterEdit && setShowFilterEdit(true)}
                />
              ) : (
                <IconButton
                  sx={{
                    mx: 0.25,
                    p: 0.25,
                  }}
                  onClick={() => setShowFilterEdit(!showFilterEdit)}
                >
                  <AddCircleIcon fontSize="small" />
                </IconButton>
              )}
              {dataExtractionType === 'all' && (
                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={(theme) => ({
                    m: 0.25,
                    px: 0.5,
                    borderRadius: 1,
                    borderWidth: 1,
                    borderStyle: 'dotted',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: grey[50],
                    color: theme.palette.primary.main,
                    boxShadow: "0px 5px 10px rgba(100,100,100,0.25)",
                  })}
                >
                  <ManageSearchIcon fontSize="small" />
                  <Typography>
                    Tag Attributes...
                  </Typography>
                </Stack>
              )}
              {dataExtractionType === 'attribute' && (
                <Stack direction="row" alignItems="center">
                  {` `}
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.5}
                    sx={(theme) => ({
                      height: 30,
                      m: 0.25,
                      px: 0.5,
                      borderRadius: 1,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: attributeMatch !== '' ? theme.palette.primary.main : red[500],
                      display: 'flex',
                      backgroundColor: attributeMatch !== '' ? grey[50] : red[100],
                      fontStyle: attributeMatch !== '' ? 'normal' : 'italic',
                      boxShadow: "0px 5px 10px rgba(100,100,100,0.25)",
                    })}
                  >
                    <DataObjectIcon fontSize="small" color="primary" />
                    <Autocomplete
                      freeSolo
                      clearOnBlur
                      value={attributeMatch}
                      options={htmlAttributes}
                      getOptionLabel={(option) => {return option.label || option || ""}}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          setAttributeMatch(newValue);
                        }
                        else if (newValue && newValue.inputValue) {
                          setAttributeMatch(newValue.inputValue);
                        }
                        else if (newValue && newValue.label) {
                          setAttributeMatch(newValue.label);
                        }
                      }}
                      onInputChange={(e, newValue) => {
                        setAttributeMatch(newValue);
                      }}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      size="small"
                      sx={{ width: 180}}
                      renderInput={(params) => 
                        <TextField
                          placeholder="attribute name"
                          variant="standard"
                          size="small"
                          sx={{minWidth: 50, width: 180, height: 28}}
                          {...params}
                        />
                      }
                    />
                  </Stack>
                  <Typography sx={{px: 0.5, fontWeight: 900}}>
                    {`=`}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    sx={(theme) => ({
                      height: 22,
                      m: 0.25,
                      px: 0.5,
                      borderRadius: 1,
                      borderWidth: 1,
                      borderStyle: 'dotted',
                      borderColor: theme.palette.primary.main,
                      backgroundColor: grey[50],
                      color: theme.palette.primary.main,
                      boxShadow: "0px 5px 10px rgba(100,100,100,0.25)",
                    })}
                  >
                    <ManageSearchIcon fontSize="small" />
                    <Typography>
                      Value
                    </Typography>
                  </Stack>
                </Stack>
              )}
              <Box sx={(theme) => ({display: 'inline', fontWeight: 900, color: theme.palette.primary.main})}>
                {`>`}
              </Box>
              {dataExtractionType === 'content' ? (
                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={(theme) => ({
                    m: 0.25,
                    px: 0.5,
                    borderRadius: 1,
                    borderWidth: 1,
                    borderStyle: 'dotted',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: grey[50],
                    color: theme.palette.primary.main,
                    boxShadow: "0px 5px 10px rgba(100,100,100,0.25)",
                  })}
                >
                  <ManageSearchIcon fontSize="small" />
                  <Typography>
                    Tag Content
                  </Typography>
                </Stack>
              ) : ('...')}
              <Stack
                direction="row"
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  fontWeight: 900
                })}
              >
                <Box>{`</`}</Box>
                {newHTMLTag !== '' ? newHTMLTag : (
                  <Box sx={{fontStyle: 'italic'}}>any tag</Box>
                )}
                <Box>{`>`}</Box>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{mt: 2}} alignItems="center" spacing={2}>
              <Button
                disabled={
                  showFilterEdit
                  || extractorLabel === ''
                  || (dataExtractionType === 'attribute' && attributeMatch === '')
                  || user?.preferences?.extractors?.find((extractor) => extractor.label === extractorLabel)
                }
                variant="contained"
                onClick={handleNewExtractor}
                startIcon={<StarsIcon />}
              >
                Add New Extractor
              </Button>
              <Box sx={{
                flexGrow: 1,
                color: red[500],
              }}>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Stack sx={{p: 1, maxHeight: 300, overflowY: "auto"}} spacing={0.5}>
            {user.preferences &&
              user.preferences.extractors &&
              user.preferences.extractors.length > 0
            ? (
                user.preferences.extractors.map((extractor, index) => (
                  <Paper key={index} sx={{p: 1.25}} variant="outlined">
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={() => handleDeleteExtractor(extractor.id)}
                      >
                        Remove
                      </Button>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box>
                          {`${extractor.label} - `}
                        </Box>
                        <Box sx={(theme) => ({fontFamily: 'monospace', fontWeight: 900, color: theme.palette.primary.main})}>
                          {`<${extractor.tag === '' ? '*' : extractor.tag}`}
                          {extractor.attributeFilters?.map((attr) => (` ${attr.field}="${attr.value}"`))}
                          {`>`}
                        </Box>
                        <Box>
                          {` - Capture ${
                            extractor.extractionType === 'content' ? 'All Content' :
                            extractor.extractionType === 'all' ? 'All Attribute Values' :
                            `${extractor.attributeMatch} value`
                          }`}
                        </Box>
                      </Stack>
                    </Stack>
                  </Paper>
                ))
            ) : (
              <Paper sx={{p: 2, color: grey[500]}} variant="outlined">
                No Saved HTML Extractors
              </Paper>
            )}
            {defaultExtractors.map((extractor, index) => (
              <Paper key={index} sx={{p: 1.25}} variant="outlined">
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={true}
                  >
                    Built In
                  </Button>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box>
                      {`${extractor.label} - `}
                    </Box>
                    <Box sx={(theme) => ({fontFamily: 'monospace', fontWeight: 900, color: theme.palette.primary.main})}>
                      {`<${extractor.tag === '' ? '*' : extractor.tag}`}
                      {extractor.attributeFilters?.map((attr) => (` ${attr.field}="${attr.value}"`))}
                      {`>`}
                    </Box>
                    <Box>
                      {` - Capture ${
                        extractor.extractionType === 'content' ? 'tag content' :
                        extractor.extractionType === 'all' ? 'all attribute values' :
                        `${extractor.attributeMatch} value`
                      }`}
                    </Box>
                  </Stack>
                </Stack>
              </Paper>
            ))}
            </Stack>
          </Box>
        </Box>
      ) : ('')}
    </>
  );
}

export default AccountExtractors;
