import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiContext } from "../../global/context";
import { useNavigate, useParams } from "react-router-dom";
import { socket } from "../../api/socket";
import { createTasks } from "../../api/task";
/* FLD */
import BulkText from "../BulkText/BulkText";
import BulkUrls from "../BulkUrls/BulkUrls";
import LoaderButton from "../LoaderButton/LoaderButton";
import ScheduleSelect from "../ScheduleSelect/ScheduleSelect";
import TaskBox from "../TaskBox/TaskBox";
import TfidfContent from "./TfidfContent";
import TfidfGrid from "./TfidfGrid";
import ToolSteps from "../ToolSteps/ToolSteps";
import UrlField from "../UrlField/UrlField";
import UserAgentPicker from "../UserAgentPicker/UserAgentPicker";
/* MUI */
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import BackspaceIcon from '@mui/icons-material/Backspace';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import ShareIcon from '@mui/icons-material/Share';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

function Tfidf() {
  const { refreshTasks } = useContext(ApiContext);
  const [mode, setMode] = useState("crawl");
  const [pageData, setPageData] = useState({});
  const [requestURL, setRequestURL] = useState("");
  const [urlList, setUrlList] = useState([]);
  const [userAgent, setUserAgent] = useState({mode: "desktop"});
  const [textList, setTextList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState([]);
  const [toolTab, setToolTab] = useState(0);
  const [taskSchedule, setTaskSchedule] = useState({});
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [keywordInfo, setKeywordInfo] = useState({});
  const navigate = useNavigate();

  const toolSteps = [
    {'stage': 'start', 'label': 'Started'},
    {'stage': 'crawl', 'label': 'Crawling'},
    {'stage': 'content', 'label': 'Processing Content'},
    {'stage': 'keywords', 'label': 'Analyzing Keywords'},
  ];

  let taskId = useParams("taskId");

  useEffect(() => {
    socket.on("tfidf_started", data => {
      setProgress([]);
      setError("");
      setPageData({});
      setLoading(true);
    });
    socket.on("tfidf_complete", (data) => {
      setError("");
      if(data.success) {
        let updatedData = data;
        updatedData.tfidf = data.tfidf.map((row) => {
          const newRow = row;
          const url = data.pageData.find((url) => url.id === row['r']);
          newRow.r = url?.url || '';
          return newRow;
        });
        setPageData(updatedData);
      }
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("tfidf_failed", (data) => {
      setError("Couldn't process your request");
      setPageData({});
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("tfidf_progress", (data) => {
      setProgress(prev => [...prev, data]);
    });
    return () => {
      socket.off("tfidf_started");
      socket.off("tfidf_complete");
      socket.off("tfidf_failed");
    }
  }, []);

  useEffect(() => {
    socket.on('disconnect', (response) => {
      if(loading && (response === 'transport close' || response === 'transport error')) {
        setLoading(false);
        setError(() => (
          <Stack spacing={2}><Typography sx={{fontWeight: 900}}>Your request took too long to process</Typography>
            <Box>Create an account to crawl larger sites and run longer processes</Box>
            <Button
              variant="contained"
              component={Link}
              to={'/account/new'}
            >
              Create New Account
            </Button>
          </Stack>
        ));
      }
    });
  }, [loading]);

  useEffect(() => {
    if(taskId.taskId && taskId.taskId !== "" && Object.keys(pageData).length === 0) {
      setError("");
      setLoading(true);
      !socket.connected && socket.connect();
      socket.on("disconnect", (reason) => {
        setLoading(false);
        setProgress([]);
      });
      socket.emit("tfidf_start", {
        token: localStorage.getItem('jwt_token'),
        config: {mode: 'report'},
        task: taskId.taskId,
      });
    }
  }, [taskId, pageData]);

  const fetchTfidf = () => {
    setError("");
    setPageData({});
    setProgress([]);
    !socket.connected && socket.connect();
    socket.on("disconnect", (reason) => {
      //setError(reason);
      setLoading(false);
      setProgress([]);
    });
    socket.emit("tfidf_start", {
      token: localStorage.getItem('jwt_token'),
      config: {
        mode: mode,
        urls: mode === 'list' ? urlList : [{id: 0, url: requestURL}],
        docs: textList,
        userAgent: userAgent.userAgent ? userAgent.userAgent : '',
        notificationTypes: notificationTypes,
      }
    });
  };

  const handleNewSchedule = () => {
    createTasks(
      'tfidf',
      {
        mode: mode,
        urls: mode === 'list' ? urlList : [{id: 0, url: requestURL}],
        userAgent: userAgent.userAgent ? userAgent.userAgent : '',
        notificationTypes: notificationTypes,
      },
      taskSchedule,
    )
    .then((data) => {
      if(data.status === 'success') {
        refreshTasks();
        setToolTab(1);
      }
    });
  };

  const showKeywordInfo = (keyword) => {
    if(keyword !== '' && pageData.tfidf) {
      const kwData = pageData.tfidf.find((row) => row.n === keyword);
      let urlInfo = [];
      pageData.pageData.forEach((url) => {
        if(url.id in kwData) {
          urlInfo.push({
            url: url.url,
            score: kwData[url.id],
          });
        }
      });
      urlInfo.sort((a, b) => {
        if(a.score < b.score) {
          return 1;
        }
        if(a.score > b.score) {
          return -1;
        }
        return 0;
      });
      setKeywordInfo({
        keyword: kwData.n,
        frequency: kwData.f,
        urls: urlInfo,
      });
    }
    else {
      setKeywordInfo({});
    }
  };

  const handleScheduleChange = (newSchedule) => { setTaskSchedule(newSchedule); };
  const handleURL = (url) => { setRequestURL(url); };
  const handleUrlList = (urls) => { setUrlList(urls); };
  const handleTextList = (texts) => { setTextList(texts); };
  const handleUserAgent = (ua) => { setUserAgent(ua); };
  const handleNotificationTypes = (pref) => { setNotificationTypes(pref); };
  const handleReset = () => {
    setError("");
    setPageData({});
    setLoading(false);
    navigate("/tfidf");
  };

  return (
    <div className="Tfidf">
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Stack spacing={2}>
            <Box>
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
                sx={{m: 0.5}}
              >
                Start Over
              </Button>
            </Box>
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          </Stack>
        </Container>
      )}
      <Container maxWidth="lg">
        <ToolSteps progress={progress} toolSteps={toolSteps} direction="column-reverse" />
      </Container>
      {pageData && pageData.success && (
        <Container
          maxWidth="100%"
          disableGutters={true}
          sx={{m: 0, p: 0, height: {xs: 'calc(100vh - 6.75rem)', sm: 'calc(100vh - 7.25rem)'}, width: '100%'}}
          >
          <Stack direction="row">
            {pageData.pageData ? (
              <TfidfGrid
                data={pageData}
                showKeywordInfo={showKeywordInfo}
                handleReset={handleReset}
              />
            ) : ''}
            {keywordInfo.urls ? (
              <Drawer
                anchor="right"
                variant="permanent"
                open={Boolean(keywordInfo.urls)}
              >
                <Box sx={{p: 1, width: 300, textAlign: 'left'}}>
                  <Stack spacing={2}>
                    <Button variant="contained" onClick={() => {
                      setKeywordInfo({});
                    }}>
                      Close
                    </Button>
                    <Box>
                      {keywordInfo.keyword} <Chip label={`${keywordInfo.frequency} instance${keywordInfo.frequency !== 1 ? `s` : ``}`} />
                    </Box>
                    <Box sx={{height: 'calc(100vh - 24.5rem)', overflowY: 'auto'}}>
                      <Grid container>
                        <Grid container>
                          <Grid item xs={4}>
                            <Typography>
                              Relevancy
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography>
                              URL
                            </Typography>
                            <Chip size="small" label={`${keywordInfo.urls.length} pages`} />
                          </Grid>
                        </Grid>
                        {keywordInfo.urls.map((url, key) => (
                          <Grid
                            container
                            key={key}
                            columnSpacing={1}
                            sx={{alignItems: 'center', pb: 1}}
                          >
                            <Grid item xs={4}>
                              <LinearProgress
                                variant="determinate"
                                value={Math.round((url.score / keywordInfo.urls[0].score) * 100)}
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <Typography sx={{fontSize: 14}}>
                                {url.url}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Stack>
                </Box>
              </Drawer>
            ) : ('')}
          </Stack>
        </Container>
      )}
      {error === "" && loading === false && Object.keys(pageData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <TaskBox
            taskType="tfidf"
            icon={<TextSnippetIcon />}
            tab={toolTab}
          >
            <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{p: 2, flexGrow: 1, minWidth: 0}}>
              <Stack
                direction={{xs: 'column', md: 'row'}}
                spacing={1}
                sx={(theme) => ({
                  height: {xs: 'auto', md: 45},
                  p: 0.75,
                  border: 'solid',
                  borderWidth: 2,
                  borderRadius: 3,
                  borderColor: theme.palette.primary.main,
                  zIndex: 10,
                  flexGrow: 1,
                  minWidth: 0,
                })}
              >
                <ToggleButtonGroup
                  value={mode}
                  exclusive
                  size="small"
                  onChange={(e, mode) => mode !== null && setMode(mode)}
                >
                  <ToggleButton value="crawl">
                    <ShareIcon />
                  </ToggleButton>
                  <ToggleButton value="xml">
                    XML
                  </ToggleButton>
                  <ToggleButton value="list">
                    <PlaylistAddRoundedIcon />
                  </ToggleButton>
                  <ToggleButton value="text">
                    <TextSnippetIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                {(mode === "crawl" || mode === "xml") && (
                  <Box sx={{flexGrow: 1}}>
                    <UrlField urlChangeCallback={handleURL} />
                  </Box>
                )}
                {mode === "list" && (
                  <Box sx={{flexGrow: 1, minWidth: 0}}>
                    <BulkUrls urlChangeCallback={handleUrlList} />
                  </Box>
                )}
                {mode === "text" && (
                  <Box sx={{flexGrow: 1, minWidth: 0}}>
                    <BulkText textChangeCallback={handleTextList} />
                  </Box>
                )}
                {mode !== "text" && (
                  <UserAgentPicker
                    hideResolution={true}
                    userAgentCallback={handleUserAgent}
                  />
                )}
              </Stack>
              {mode === 'text' ? (
                <LoaderButton
                  onClickCallback={fetchTfidf}
                  loading={loading}
                  startIcon={<BubbleChartIcon />}
                >
                  Start Now
                </LoaderButton>
              ) : (
                <ScheduleSelect
                  startIcon={<BubbleChartIcon />}
                  scheduleChangeCallback={handleScheduleChange}
                  startNowCallback={fetchTfidf}
                  loading={loading}
                  setScheduleCallback={handleNewSchedule}
                  setNotificationsCallback={handleNotificationTypes}
                />
              )}
            </Stack>

          </TaskBox>
          <TfidfContent />
        </Container>
      )}
    </div>
  );
}

export default Tfidf;
