import React, { useContext, useState } from 'react';
import { UserContext } from "../../global/context";
/* FLD */
import LoaderButton from "../LoaderButton/LoaderButton";
import SchemaContent from "./SchemaContent";
/* MUI */
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BackspaceIcon from '@mui/icons-material/Backspace';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

function Schema() {
  const [user] = useContext(UserContext);
  const [schemaData, setSchemaData] = useState({});
  const [schemaColumns, setSchemaColumns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [schemaType, setSchemaType] = useState("all");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [toolTab, setToolTab] = useState(0);

  const ignoreColumns = ["cse_thumbnail","cse_image","metatags"];

  const fetchSchema = async () => {
    if (searchTerm !== '') {
      setLoading(true);
      setSchemaData({});
      fetch(`${process.env.REACT_APP_API}/schema?search=${encodeURIComponent(searchTerm)}&type=${encodeURIComponent(schemaType)}`)
      .then(response => {
        if (!response.ok) { throw response }
        return response.json();
      })
      .then((data => {
        setError("");
        setSchemaData(data);
        const columnNames = [];
        data.results.forEach(result => {
          Object.keys(result.pagemap).forEach((data, key) => {
            if (columnNames.indexOf(data) === -1 && ignoreColumns.indexOf(data) === -1) {
              columnNames.push(data);
            }
          });
        });
        setSchemaColumns(columnNames.sort());
        setLoading(false);
      }))
      .catch(error => {
        if (error.status === 429) {
          setError("Too many requests - please wait and try again later");
        }
        else {
          setError("Couldn't process your request");
        }
        setSchemaData({});
        setSchemaColumns([]);
        setLoading(false);
      });
    }
  }

  const handleSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanValue = event.target.value
      .replaceAll(/[^A-Za-z0-9 -]/gi, '')
      .substring(0, 100);
    setSearchTerm(cleanValue);
  };

  const handleSchemaType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSchemaType(event.target.value);
  };

  const handleReset = () => {
    setSearchTerm("");
    setSchemaData({});
    setSchemaColumns([]);
    setError('');
  };

  function SchemaCell(props: {rows: any}) {
    const { rows } = props;
    const [open, setOpen] = useState(false);
    const itemTypes = [];

    rows.forEach((data, dataKey) => {
      Object.keys(data).forEach((dataType) => {
        if (itemTypes.indexOf(dataType) === -1) {
          itemTypes.push(dataType)
        }
      })
    });

    const itemCounts = itemTypes
      .map(thisItem => ({
        type: thisItem,
        count: rows.filter((row) => {
                return thisItem in row;
              }).length
      }));

    return (
      <React.Fragment>
        <Stack direction="row" alignItems="flex-start" spacing={0.25}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <ExpandCircleDownRoundedIcon
              color="primary"
              sx={{transform: open ? '': 'rotate(-90deg)', transition: '100ms'}}
            />
          </IconButton>
          <Box>
          {open ?
            <Stack
              direction="column"
              divider={<Divider orientation="horizontal" flexItem />}
              spacing={0.25}
              sx={{
                px: 1,
                overflowY: 'auto',
                maxHeight: 300,
              }}
            >
              {rows.map((data, dataKey) => (
                Object.keys(data).map((dataType, itemKey) => {
                  return (
                    <Stack key={itemKey} spacing={1}>
                      <Typography
                        variant="dataLabel"
                        sx={{m: "auto", ml: 0, mr: 0}}
                      >
                        {dataType}
                      </Typography>
                    <Box>{data[dataType]}</Box>
                  </Stack>
                  )
                })
              ))}
            </Stack>
            : 
            <Stack direction="column" alignItems="flex-start" spacing={0.25}>
              {itemCounts.map((item, key) => (
                <Chip
                  key={key}
                  avatar={<Avatar>{item.count}</Avatar>}
                  label={item.type}
                  variant="outlined"
                />
              ))}
            </Stack>
          }
          </Box>
        </Stack>
      </React.Fragment>
    );
  }

  return (
    <div className="Schema">
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Stack spacing={2}>
            <Box>
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
                sx={{m: 0.5}}
              >
                Start Over
              </Button>
            </Box>
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          </Stack>
        </Container>
      )}
      {schemaData && schemaData.success && (
        <Container
          maxWidth="100%"
          disableGutters={true}
          sx={{m: 0, p: 0, height: {xs: 'calc(100vh - 6.75rem)', sm: 'calc(100vh - 7.25rem)'}, width: '100%'}}
        >
          <Stack direction="row" sx={{height: '3rem', alignItems: 'center'}} spacing={1}>
            <Button
              variant="outlined"
              onClick={handleReset}
              startIcon={<BackspaceIcon />}
              sx={{p: 1, pl: 1.5, pr: 0.5, minWidth: 30}}
            >
              <Box sx={{display: {xs: 'none', sm: 'inline-flex'}}}>Start Over</Box>
            </Button>
            <Chip
              variant="outlined"
              label={`Pages with schema containing "${schemaData.search}"`}
            />
          </Stack>
          <Paper variant="outlined">
            <TableContainer sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 10.5rem)'}}>
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 400 }}>Page</TableCell>
                    {schemaColumns && schemaColumns.map((column, key) => {
                      return (<TableCell align="center" key={key}>{column}</TableCell>)
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schemaData.results.length > 0 && schemaData.results.map((row, key) => (
                    <TableRow
                      key={key}
                      sx={(theme) => ({
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:nth-of-type(odd)': {
                          backgroundColor: theme.palette.action.hover,
                        },
                      })}
                    >
                      <TableCell sx={{ minWidth: 150, maxWidth: 550, verticalAlign: 'top' }}>
                        <Stack direction="row" spacing={1}>
                          <Box sx={{width: 300}}>
                          {row.pagemap['cse_thumbnail'] && (
                            <Box
                              component="img"
                              src={`${row.pagemap['cse_thumbnail'][0].src}`}
                              loading="lazy"
                              sx={{width: '100%', height: 'auto%', objectFit: 'cover'}}
                            />
                          )}
                          </Box>
                          <Stack spacing={0.5}>
                            <Typography
                              component="a"
                              href={row.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{textDecoration: 'none'}}
                            >
                              {row.title}
                            </Typography>
                            <Typography
                              component="a"
                              href={row.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{fontSize: 12, textDecoration: 'none'}}
                            >
                              {row.link}
                            </Typography>
                            <Box>{row.snippet}</Box>
                          </Stack>
                        </Stack>
                      </TableCell>
                      {schemaColumns && schemaColumns.map((column, columnKey) => (
                        <TableCell key={columnKey.toString()} sx={{py: 0, verticalAlign: 'top'}}>
                          {column.toString() in row.pagemap
                            ? <SchemaCell rows={row.pagemap[column.toString()]} />
                            : ""
                          }
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
      )}
      {loading === true && (
        <Container maxWidth="100%" sx={{height: 'calc(100vh - 7rem)'}}>
          <Stack>
            <Skeleton variant="rectangular" sx={{ my: 4 }} />
            <Stack spacing={3}>
              {[...Array(4)].map((item, key) => (
                <Stack key={key} direction="row" spacing={4}>
                  <Skeleton variant="circular" width={60} height={60} />
                  {[...Array(4)].map((item, key) => (
                    <Skeleton key={key} variant="text" sx={{ my: 2, mx: 1, flexGrow: 1}} />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Container>
      )}
      {error === "" && loading === false && Object.keys(schemaData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <Stack direction="row">
            <Stack direction="row"
              spacing={1}
              sx={(theme) => ({
                mx: .5,
                p: 1.5,
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                fontSize: 18,
                transform: "skewx(-7.5deg)"
              })}
            >
              <DataObjectIcon />
              <Typography>
                Schema Search
              </Typography>
            </Stack>
            {false && (<>
            {user.loggedIn ? (
              <Tabs
                value={toolTab}
                onChange={(e, tab) => setToolTab(tab)}
              >
                <Tab icon={<AddCircleIcon fontSize="small" />} iconPosition="start" label="New" sx={{p: 0.5, minHeight: 46}} />
                <Tab icon={<ScheduleIcon fontSize="small" />} iconPosition="start" label="Upcoming" sx={{p: 0.5, minHeight: 46}} />
                <Tab icon={<TaskAltIcon fontSize="small" />} iconPosition="start" label="Complete" sx={{p: 0.5, minHeight: 46}} />
              </Tabs>
            ) : (
              <Tabs value={0}>
                <Tab label="Search Now" />
                <Tab disabled label="Schedule Later with Account" />
              </Tabs>
            )}</>)}
          </Stack>
          <Paper variant="outlined" square>
            <Stack direction="row" spacing={2}>
              {(toolTab === 0) && (
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{p: 2, flexGrow: 1}}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={(theme) => ({
                      height: 45,
                      p: 0.75,
                      border: 'solid',
                      borderWidth: 2,
                      borderRadius: 3,
                      borderColor: theme.palette.primary.main,
                      zIndex: 10, 
                      flexGrow: 1,
                    })}
                  >
                    <Box>
                      <FormControl sx={{width: {xs: 90, md: 150}, backgroundColor: 'white', m: 0.5}} size="small">
                        <Select
                          value={schemaType}
                          onChange={handleSchemaType}
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"product"}>Products</MenuItem>
                          <MenuItem value={"video"}>Videos</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                      <FormControl fullWidth={true}>
                        <InputBase
                          value={searchTerm}
                          fullWidth={true}
                          placeholder="Search Keyword"
                          error={error !== ' '}
                          sx={{height: 45}}
                          onChange={handleSearchTerm}
                          inputProps={{maxLength: 100}}
                        />
                        <FormHelperText sx={{m: 0, mt: 1}}>{error}</FormHelperText>
                      </FormControl>
                    </Box>
                  </Stack>
                  <LoaderButton
                    onClickCallback={fetchSchema}
                    loading={loading}
                    startIcon={<ManageSearchIcon />}
                  >
                    Search
                  </LoaderButton>
                </Stack>
              )}
            </Stack>
          </Paper>
          <SchemaContent/>
        </Container>
      )}
    </div>
  );
}

export default Schema;
