import React, { useContext, useState } from 'react';
import { BackendContext, UserContext } from "../../global/context";
import { updateUserPreferences } from "../../api/user";
/* FLD */
import DataTree from "./DataTree";
import LoaderButton from "../LoaderButton/LoaderButton";
import StatusChip from "../StatusChip/StatusChip";
import UrlField from "../UrlField/UrlField";
/* MUI */
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PublishIcon from '@mui/icons-material/Publish';
import StarsIcon from '@mui/icons-material/Stars';
/* MUI Colors */
import { lightGreen } from '@mui/material/colors';
import { grey } from '@mui/material/colors';
/* HTTP Headers */
import defaultAPIs from '../../global/defaultAPIs.json';
import httpHeaders from '../../global/httpHeaders.json';

import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ApiConnect(props: { extractorData?: [] }) {
  const {
    extractorData = []
  } = props;
  const [user, setUser] = useContext(UserContext);
  const [backend] = useContext(BackendContext);
  const [open, setOpen] = useState(false);
  const [fetchType, setFetchType] = useState("post");
  const [requestURL, setRequestURL] = useState("");
  const [showURLParams, setShowURLParams] = useState(false);
  const [newURLParam, setNewURLParam] = useState("");
  const [newURLValue, setNewURLValue] = useState({
    label: '',
    type: 'string',
    value: ''
  });
  const [urlParams, setUrlParams] = useState([]);
  const [showRequestHeaders, setShowRequestHeaders] = useState(false);
  const [newHTTPHeader, setNewHTTPHeader] = useState("");
  const [newHTTPValue, setNewHTTPValue] = useState({
    label: '',
    type: 'string',
    value: ''
  });
  const [requestHeaders, setRequestHeaders] = useState([]);
  const [requestDataElements, setRequestDataElements] = useState([
    {
      id: -1,
      name: 'root',
      type: 'object',
      label: '',
      value: '',
      isExtractor: false,
      childIds: [],
    },
  ]);
  const [lastId, setLastId] = useState(-1);
  const [showPreview, setShowPreview] = useState(false);
  const [apiLabel, setApiLabel] = useState('');
  const [responseStatus, setResponseStatus] = useState(0);
  const [responseHeaders, setResponseHeaders] = useState([]);
  const [responseBody, setResponseBody] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedApiConfig, setSelectedApiConfig] = useState('');

  const addElement = (name: string = '', type: string = '', parent: number = -1) => {
    const newId = lastId + 1;
    setRequestDataElements([
      ...requestDataElements.map((element) => {
        if (element.id === parent) {
          return {
            ...element,
            childIds: [...element.childIds, newId]
          };
        }
        else {
          return element;
        }
      }),
      {
        id: newId,
        name: name,
        type: type,
        label: '',
        value: '',
        isExtractor: false,
        childIds: [],
      }
    ]);
    setLastId(newId);
  };

  const renameElement = (id: number, name: string = '') => {
    setRequestDataElements([
      ...requestDataElements.map((element) => {
        if (element.id === id) {
          return {
            ...element,
            name: name,
          };
        }
        else {
          return element;
        }
      })
    ]);
  };

  const updateElement = (id: number, value: string = '', isExtractor: boolean = false, label: string = '') => {
    setRequestDataElements([
      ...requestDataElements.map((element) => {
        if (element.id === id) {
          return {
            ...element,
            value: value,
            label: label,
            isExtractor: isExtractor,
          };
        }
        else {
          return element;
        }
      })
    ]);
  };

  const deleteElement = (id: number) => {
    setRequestDataElements([
      ...requestDataElements.map((element) => {
        if (element.childIds.includes(id)) {
          return {
            ...element,
            childIds: [...element.childIds.filter((child) => child !== id)]
          };
        }
        else {
          return element;
        }
      })
    ]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleParamAdd = () => {
    setUrlParams([
      ...urlParams,
      {
        field: newURLParam,
        value: newURLValue,
      }
    ]);
    setNewURLParam('');
    setNewURLValue('');
  };

  const handleParamDelete = (removeParam) => {
    setUrlParams([
      ...urlParams.filter((param) => !(param.field === removeParam.field && param.value === removeParam.value))
    ]);
  };

  const handleHeaderAdd = () => {
    setRequestHeaders([
      ...requestHeaders,
      {
        field: newHTTPHeader,
        value: newHTTPValue,
      }
    ]);
    setNewHTTPHeader('');
    setNewHTTPValue('');
  };

  const handleHeaderDelete = (removeHeader) => {
    setRequestHeaders([
      ...requestHeaders.filter((header) => !(header.field === removeHeader.field && header.value === removeHeader.value))
    ]);
  };

  const sendApiRequest = () => {
    setLoading(true);
    setResponseStatus(0);
    setResponseHeaders([]);
    setResponseBody('');
    const url = new URL(requestURL);
    urlParams.forEach((param) => {
      url.searchParams.append(
        param.field,
        param.value.type === 'string' ?
          param.value.value :
          extractorData?.find((item) => item.field === param.value.value)?.value[0] || ''
      );
    });
    fetch(
      url.href, {
        method: fetchType,
        headers: new Headers(requestHeaders.map((header) => [
          header.field,
          header.value.type === 'string' ?
            header.value.value :
            extractorData?.find((item) => item.field === header.value.value)?.value[0] || ''
        ])),
        body: fetchType === 'post' ? renderData(-1) : undefined
    })
    .then(response => {
      setResponseStatus(response.status);
      setResponseHeaders([...response.headers]);
      if (!response.ok) { throw response }
      //return response.json();
      return response.text();
    })
    .then((data) => {
      //setResponseData(JSON.parse(data));
      setResponseBody(data);
      setLoading(false);
    })
    .catch(error => {
      setResponseStatus(-1);
      setResponseBody('Error');
      setLoading(false);
    });
  };

  const handleSaveConfig = () => {
    const newConfig = {
      id: `custom-${apiLabel.replace(" ", "_")}`,
      label: apiLabel,
      fetchType: fetchType,
      requestURL: requestURL,
      params: urlParams,
      headers: requestHeaders,
      dataElements: requestDataElements,
    }
    if(user.preferences && user.preferences.apiConfigs) {
      user.preferences.apiConfigs = [
        ...user.preferences.apiConfigs, newConfig];
    }
    else {
      user.preferences['apiConfigs'] = [newConfig];
    }
    updateUserPreferences(
      user.userid,
      user.preferences,
    )
    .then((data) => {
      if(data.status === 'success') {
        setUser({
          ...user,
          preferences: data.user.preferences,
        });
        setApiLabel("");
        setSelectedApiConfig(newConfig.id);
      }
    });
  };

  const loadApiConfig = () => {
    if (selectedApiConfig !== '') {
      const selectedConfig = [
        ...user.preferences.apiConfigs,
        ...defaultAPIs,
      ].find((config) => config.id === selectedApiConfig)
      if (selectedConfig) {
        setApiLabel(selectedConfig.label);
        setFetchType(selectedConfig.fetchType);
        setRequestURL(selectedConfig.requestURL);
        setUrlParams(selectedConfig.params);
        setRequestHeaders(selectedConfig.headers);
        setRequestDataElements(selectedConfig.dataElements);
        setResponseStatus(0);
        setLastId(selectedConfig.dataElements[selectedConfig.dataElements.length - 1]?.id);
      }
    }
  };

  const handleDeleteApiConfig = () => {
    if(user.preferences && user.preferences.apiConfigs) {
      user.preferences.apiConfigs = user.preferences.apiConfigs.filter((config) => {
        return config.id !== selectedApiConfig;
      });
      updateUserPreferences(
        user.userid,
        user.preferences,
      )
      .then((data) => {
        if(data.status === 'success') {
          setUser({
            ...user,
            preferences: data.user.preferences,
          });
          resetConfig();
        }
      });
    }
  }

  const resetConfig = () => {
    setFetchType('post');
    setRequestURL('');
    setUrlParams([]);
    setRequestHeaders([]);
    setRequestDataElements([
      {
        id: -1,
        name: 'root',
        type: 'object',
        label: '',
        value: '',
        isExtractor: false,
        childIds: [],
      }
    ]);
    setLastId(-1);
    setResponseStatus(0);
    setApiLabel('');
    setSelectedApiConfig('');
  }

  const renderData = (elementId: number) => {
    // Add type checking
    const thisElement = requestDataElements.find((element) => element.id === elementId);
    if (thisElement.id === -1) {
      return `{${thisElement.childIds.map((child) => renderData(child)).join(',')}}`;
    }
    if (thisElement.type === 'field') {
      return `${thisElement.name !== '' ? `"${thisElement.name}": ` : ''}"${
        !thisElement.isExtractor ?
          thisElement.value :
          extractorData?.find((item) => item.field === thisElement.value)?.value[0] || ''
      }"`;
    }
    if (thisElement.type === 'list') {
      return `${thisElement.name !== '' ? `"${thisElement.name}": ` : ''}[${thisElement.childIds.map((child) => renderData(child)).join(',')}]`;
    }
    if (thisElement.type === 'object') {
      return `${thisElement.name !== '' ? `"${thisElement.name}": ` : ''}{${thisElement.childIds.map((child) => renderData(child)).join(',')}}`;
    }
  };

  return (
    <Box>
      <Button
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        sx={{display: {xs: 'none', sm: 'inline-flex'}}}
        onClick={handleOpen}
      >
        {backend.connecting ? (
          <Stack>
            <>Connecting</>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </Stack>
        ) : (
          !backend.connected ? (
            <>Unavailable</>
          ) : (
            <Box sx={{whiteSpace: 'nowrap', display: {sm: 'none', md: 'inline'}}}>
              Send to API
            </Box>
          )
        )}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          position="sticky"
          color="primary"
        >
          <Toolbar>
            <Stack sx={{flex: 1}} direction="row" alignItems="center">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              <Container maxWidth="lg">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ManageSearchIcon color="light" />
                  <Typography sx={{fontWeight: 600}}>
                    Send Data to API
                  </Typography>
                  <Stack direction="row" spacing={0.5}>
                    {extractorData.map((extractor, index) => (
                      <Box
                        key={index}
                        sx={(theme) => ({
                          p: 0.5,
                          borderRadius: 1,
                          borderWidth: 1,
                          borderStyle: 'dotted',
                          borderColor: theme.palette.primary.main,
                          backgroundColor: grey[50],
                          color: theme.palette.primary.main,
                        })}
                      >
                        {extractor.label}
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              </Container>
            </Stack>
          </Toolbar>
        </AppBar>
       {user.loggedIn && (
          <AppBar
            position="sticky"
            color="neutral"
            sx={{
              top: 'auto',
              top: 60,
              py: 1,
            }}
          >
            <Toolbar>
              <Container maxWidth="lg">
                <Stack direction="row" spacing={1} sx={{flex: 1}}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      p: 1.5,
                      flexGrow: 1,
                    }}
                  >
                    <Box>
                      <TextField
                        error={user?.preferences?.apiConfigs?.find((config) => config.label === apiLabel)}
                        size="small"
                        label="Name"
                        value={apiLabel}
                        onChange={(e) => setApiLabel(e.target.value)}
                        sx={{backgroundColor: grey[50]}}
                      />
                    </Box>
                    <Button
                      variant="outlined"
                      disabled={requestURL === '' || apiLabel === '' || user?.preferences?.apiConfigs?.find((config) => config.label === apiLabel)}
                      startIcon={<StarsIcon />}
                      onClick={handleSaveConfig}
                    >
                      Save Configuration
                    </Button>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      p: 1.5,
                    }}
                  >
                    <Box>
                      {selectedApiConfig !== '' && !selectedApiConfig.startsWith('default-') ? (
                        <IconButton
                          variant="outlined"
                          size="small"
                          onClick={handleDeleteApiConfig}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      ) : ''}
                      <Select
                        displayEmpty
                        value={selectedApiConfig}
                        renderValue={(selected) => (
                          selected === '' ? 'API Configs' :
                          [
                            ...user?.preferences?.apiConfigs,
                            ...defaultAPIs,
                          ].find((config) => config.id === selected)?.label ?? ''
                        )}
                        sx={{
                          minWidth: 170,
                          height: 40,
                          backgroundColor: grey[50],
                        }}
                        onChange={(e) => setSelectedApiConfig(e.target.value)}
                      >
                        {user?.preferences?.apiConfigs?.length !== 0 ? (
                          user?.preferences?.apiConfigs?.map((config, index) => (
                            <MenuItem
                              key={index}
                              value={config.id}
                            >
                              {config.label}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled value="">
                            No Saved Configs
                          </MenuItem>
                        )}
                        {defaultAPIs.length !== 0 ? (
                          defaultAPIs.map((config, index) => (
                            <MenuItem
                              key={index}
                              value={config.id}
                            >
                              {config.label} (Built-In)
                            </MenuItem>
                          ))
                        ) : ('')}
                      </Select>
                    </Box>
                    <Button
                      variant="outlined"
                      disabled={selectedApiConfig === ''}
                      startIcon={<PublishIcon />}
                      onClick={loadApiConfig}
                    >
                      Load
                    </Button>
                  </Stack>
                </Stack>
              </Container>
            </Toolbar>
          </AppBar>
        )}
        <Container maxWidth="lg" sx={{py: 2}}>
          <Stack spacing={2}>
            <Paper variant="outlined">
              <Stack
                direction={{xs: 'column', md: 'row'}}
                spacing={1}
                sx={(theme) => ({
                  m: 1,
                  mt: 1.5,
                  height: {xs: 'auto', md: 45},
                  p: 0.75,
                  border: 'solid',
                  borderWidth: 2,
                  borderRadius: 3,
                  borderColor: theme.palette.primary.main,
                  zIndex: 10, 
                  flexGrow: 1,
                })}
                alignItems="center"
              >
                <Box>
                  <ToggleButtonGroup
                    color="primary"
                    value={fetchType}
                    exclusive
                    size="small"
                    onChange={(e, httpType) => httpType !== null && setFetchType(httpType)}
                  >
                    <ToggleButton value="get">Get</ToggleButton>
                    <ToggleButton value="post">Post</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box sx={{flexGrow: 1}}>
                  <UrlField
                    value={requestURL}
                    hideSuggestions={true}
                    urlChangeCallback={(url) => setRequestURL(url)}
                    sx={{width: 250}}
                  />
                </Box>
              </Stack>
              <Stack direction="row" alignItems="center" sx={{px: 1}}>
                <IconButton
                  onClick={() => setShowURLParams(!showURLParams)}
                  sx={{width: 40, height: 40}}
                >
                  <ExpandCircleDownRoundedIcon
                    color={showURLParams ? "primary" : ""}
                    sx={{transform: showURLParams ? '': 'rotate(-90deg)', transition: '100ms'}}
                  />
                </IconButton>
                <Typography variant="h6">URL Parameters</Typography>
              </Stack>
              {showURLParams && (
                <TableContainer sx={{ maxHeight: 300 }}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{width: '50%'}}>
                          <TextField
                            fullWidth={true}
                            label="Parameter"
                            size="small"
                            value={newURLParam}
                            onChange={(e) => setNewURLParam(e.target.value)}
                          />
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            freeSolo
                            clearOnBlur
                            value={newURLValue.label}
                            options={[...extractorData]}
                            getOptionLabel={(option) => {return option.label || option || ""}}
                            onChange={(e, newValue) => {
                              if (typeof newValue === 'string') {
                                setNewURLValue({
                                  label: newValue,
                                  type: 'string',
                                  value: newValue
                                });
                              }
                              else if (newValue && newValue.inputValue) {
                                setNewURLValue({
                                  label: newURLValue.inputValue,
                                  type: 'string',
                                  value: newValue.inputValue
                                });
                              }
                              else if (newValue && newValue.field) {
                                setNewURLValue({
                                  label: newValue.label,
                                  type: 'field',
                                  value: newValue.field
                                });
                              }
                            }}
                            onInputChange={(e, newValue) => {
                              setNewURLValue({
                                label: newValue,
                                type: 'string',
                                value: newValue
                              });
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>
                                {option.type === 'string' ? (
                                  <Box>
                                    {option.label}
                                  </Box>
                                ) : (
                                  <Box
                                    sx={(theme) => ({
                                      p: 0.5,
                                      borderRadius: 1,
                                      borderWidth: 1,
                                      borderStyle: 'dotted',
                                      borderColor: theme.palette.primary.main,
                                      backgroundColor: grey[50],
                                      color: theme.palette.primary.main,
                                    })}
                                  >
                                    {option.label}
                                  </Box>
                                )}
                              </li>
                            )}
                            size="small"
                            renderInput={(params) => 
                              <TextField
                                {...params}
                                label="Field/Value"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ManageSearchIcon color={newURLValue.type === 'field' ? 'primary' : grey[800]} />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell sx={{width: 40}}>
                          <IconButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            disabled={newURLParam === '' || newURLValue === ''}
                            onClick={handleParamAdd}
                            sx={{height: "calc(3rem - 3px)", width: 50}}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {urlParams.map((param, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {param.field}
                          </TableCell>
                          <TableCell>
                            {param.value.type === 'string' ? (
                              <Box>
                                {param.value.label}
                              </Box>
                            ) : (
                              <Box
                                sx={(theme) => ({
                                  display: 'inline',
                                  p: 0.5,
                                  borderRadius: 1,
                                  borderWidth: 1,
                                  borderStyle: 'dotted',
                                  borderColor: theme.palette.primary.main,
                                  backgroundColor: grey[50],
                                  color: theme.palette.primary.main,
                                })}
                              >
                                {param.value.label}
                              </Box>
                            )}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              variant="outlined"
                              size="small"
                              onClick={() => handleParamDelete(param)}
                              sx={{height: "calc(3rem - 3px)", width: 50}}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
            <Paper variant="outlined">
              <Stack direction="row" alignItems="center" sx={{m: 1}}>
                <IconButton
                  onClick={() => setShowRequestHeaders(!showRequestHeaders)}
                  sx={{width: 40, height: 40}}
                >
                  <ExpandCircleDownRoundedIcon
                    color={showRequestHeaders ? "primary" : ""}
                    sx={{transform: showRequestHeaders ? '': 'rotate(-90deg)', transition: '100ms'}}
                  />
                </IconButton>
                <Typography variant="h6">Request Headers</Typography>
              </Stack>
              {showRequestHeaders && (
                <TableContainer sx={{ maxHeight: 300 }}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{width: '50%'}}>
                          <Autocomplete
                            freeSolo
                            clearOnBlur
                            value={newHTTPHeader}
                            options={httpHeaders}
                            getOptionDisabled={(option) => requestHeaders.some((header) => header.field === option.label)}
                            getOptionLabel={(option) => {return option.label || option || ""}}
                            onChange={(event, newValue) => {
                              if (typeof newValue === 'string') {
                                setNewHTTPHeader(newValue);
                              }
                              else if (newValue && newValue.inputValue) {
                                setNewHTTPHeader(newValue.inputValue);
                              }
                              else if (newValue && newValue.label) {
                                setNewHTTPHeader(newValue.label);
                              }
                            }}
                            onInputChange={(e, newValue) => {
                              setNewHTTPHeader(newValue);
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            size="small"
                            renderInput={(params) => 
                              <TextField
                                label="HTTP Header"
                                {...params}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            freeSolo
                            clearOnBlur
                            value={newHTTPValue.label}
                            options={[...extractorData]}
                            getOptionLabel={(option) => {return option.label || option || ""}}
                            onChange={(e, newValue) => {
                              if (typeof newValue === 'string') {
                                setNewHTTPValue({
                                  label: newValue,
                                  type: 'string',
                                  value: newValue
                                });
                              }
                              else if (newValue && newValue.inputValue) {
                                setNewHTTPValue({
                                  label: newValue.inputValue,
                                  type: 'string',
                                  value: newValue.inputValue
                                });
                              }
                              else if (newValue && newValue.field) {
                                setNewHTTPValue({
                                  label: newValue.label,
                                  type: 'field',
                                  value: newValue.field
                                });
                              }
                            }}
                            onInputChange={(e, newValue) => {
                              setNewHTTPValue({
                                label: newValue,
                                type: 'string',
                                value: newValue
                              });
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>
                                {option.type === 'string' ? (
                                  <Box>
                                    {option.label}
                                  </Box>
                                ) : (
                                  <Box
                                    sx={(theme) => ({
                                      p: 0.5,
                                      borderRadius: 1,
                                      borderWidth: 1,
                                      borderStyle: 'dotted',
                                      borderColor: theme.palette.primary.main,
                                      backgroundColor: grey[50],
                                      color: theme.palette.primary.main,
                                    })}
                                  >
                                    {option.label}
                                  </Box>
                                )}
                              </li>
                            )}
                            size="small"
                            renderInput={(params) => 
                              <TextField
                                {...params}
                                label="Field/Value"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ManageSearchIcon color={newHTTPValue.type === 'field' ? 'primary' : grey[800]} />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            }
                          />
                        </TableCell>
                        <TableCell sx={{width: 40}}>
                          <IconButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            disabled={newHTTPHeader === '' || newHTTPValue === ''}
                            onClick={handleHeaderAdd}
                            sx={{height: "calc(3rem - 3px)", width: 50}}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {requestHeaders.map((header, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {header.field}
                          </TableCell>
                          <TableCell>
                            {header.value.type === 'string' ? (
                              <Box>
                                {header.value.label}
                              </Box>
                            ) : (
                              <Box
                                sx={(theme) => ({
                                  display: 'inline',
                                  p: 0.5,
                                  borderRadius: 1,
                                  borderWidth: 1,
                                  borderStyle: 'dotted',
                                  borderColor: theme.palette.primary.main,
                                  backgroundColor: grey[50],
                                  color: theme.palette.primary.main,
                                })}
                              >
                                {header.value.label}
                              </Box>
                            )}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              variant="outlined"
                              size="small"
                              onClick={() => handleHeaderDelete(header)}
                              sx={{height: "calc(3rem - 3px)", width: 50}}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
            {fetchType === 'post' && (
              <Paper variant="outlined">
                <Typography sx={{m: 1}} variant="h6">
                  Data
                </Typography>
                <Box
                  sx={{
                    px: 0,
                    py: 2,
                    backgroundColor: grey[200],
                    boxShadow: "inset 0px 5px 10px rgba(100,100,100,0.25)",
                  }}
                >
                  <DataTree
                    extractorLabels={extractorData.map((extractor) => ({
                      label: extractor.label,
                      field: extractor.field
                    }))}
                    elementId={-1}
                    elementList={requestDataElements}
                    addElementCallback={addElement}
                    renameElementCallback={renameElement}
                    updateElementCallback={updateElement}
                    deleteElementCallback={deleteElement}
                  />
                </Box>
              </Paper>
            )}
            <Paper variant="outlined" sx={{p: 1}}>
              <Grid container rowSpacing={2} alignItems="center">
                <Grid item xs={showPreview || responseStatus !== 0 ? 12 : 6}>
                  <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row" alignItems="center">
                        <IconButton
                          onClick={() => setShowPreview(!showPreview)}
                          sx={{width: 40, height: 40}}
                        >
                          <ExpandCircleDownRoundedIcon
                            color={showPreview ? "primary" : ""}
                            sx={{transform: showPreview ? '': 'rotate(-90deg)', transition: '100ms'}}
                          />
                        </IconButton>
                        <Typography variant="h6">{showPreview ? 'Hide' : 'Show'} Request Preview</Typography>
                      </Stack>
                    </Stack>
                    {showPreview && (
                      <Paper variant="outlined" sx={{mx: 1}}>
                        <TextField
                          fullWidth
                          multiline
                          value={[
                            `curl -i \\`,
                            `-X ${fetchType} \\`,
                            [requestURL, urlParams.length > 0 ? 
                                [`?`,
                                urlParams.map((param) => `${param.field}=${
                                  param.value.type === 'string' ?
                                    param.value.value :
                                    extractorData?.find((item) => item.field === param.value.value)?.value[0] || ''
                                }`).join('&')
                                ].join('')
                            : '', ` \\`].join(''),
                            requestHeaders.map((header, index) => (
                              `-H "${header.field}: ${
                                header.value.type === 'string' ?
                                  header.value.value :
                                  extractorData?.find((item) => item.field === header.value.value)?.value[0] || ''
                              }" \\ `
                            )).join('\r'),
                            `-D '${renderData(-1)}' \\ `,
                          ]
                          .filter((item) => item !== '')
                          .join('\r')}
                          sx={{
                            whiteSpace: 'nowrap',
                            backgroundColor: grey[800],
                            textarea: {
                              color: lightGreen[400],
                              fontFamily: 'monospace',
                              fontSize: 12,
                              lineHeight: 1.3,
                            }
                          }}
                        />
                      </Paper>
                    )}
                  </Stack>
                </Grid>
                {responseStatus !== 0 && (
                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Stack sx={{m: 1}} spacing={1}>
                        <Typography variant="h6">API Response</Typography>
                        <Box>
                          <StatusChip status={responseStatus} />
                        </Box>
                        <Stack>
                          <Typography sx={{fontWeight: 900}}>Response Headers</Typography>
                          {responseHeaders?.map((header, index) => (
                            <Box key={index}>
                              {header[0]}: {header[1]}
                            </Box>
                          ))}
                        </Stack>
                        <Typography sx={{fontWeight: 900}}>Response Body</Typography>
                      </Stack>
                      <Box
                        sx={{
                          m: 0,
                          p: 1,
                          color: lightGreen[400],
                          fontFamily: 'monospace',
                          backgroundColor: grey[800],
                          maxHeight: 500,
                          overflow: 'auto',
                        }}
                      >
                        {responseBody}
                      </Box>
                    </Paper>

                  </Grid>
                )}
                <Grid item xs={showPreview || responseStatus !== 0 ? 12 : 6}>
                  <Stack direction="row" spacing={2}>
                    <Box sx={{flexGrow: 1}} />
                    {(
                      requestURL !== ''
                      || urlParams.length > 0
                      || requestHeaders.length > 0
                      || requestDataElements.length > 1
                      || apiLabel !== ''
                    ) && (
                      <Button
                        onClick={resetConfig}
                      >
                        Clear
                      </Button>
                    )}
                    <LoaderButton
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      loading={loading}
                      sx={{
                        width: 300,
                      }}
                      onClickCallback={sendApiRequest}
                      disabled={requestURL.length === 0}
                    >
                      Send to API
                    </LoaderButton>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </Container>
      </Dialog>
    </Box> 
  );
}

export default ApiConnect;
