import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from "../../global/context";
import { updateUserPreferences } from "../../api/user";
/* FLD */
import UrlField from "../UrlField/UrlField";
/* MUI */
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
/* Icons */
import HttpIcon from '@mui/icons-material/Http';
import StarsIcon from '@mui/icons-material/Stars';
/* MUI Colors */
import { grey } from '@mui/material/colors';

const filter = createFilterOptions();
const defaultCategories = [
    {'label': 'Primary'},
    {'label': 'Competitor'},
  ];

function AccountDomains() {
  const [user, setUser] = useContext(UserContext);
  const [newDomain, setNewDomain] = useState("");
  const [domainCategories, setDomainCategories] = useState(defaultCategories);
  const [newDomainCategory, setNewDomainCategory] = useState("");
  const [domainValid, setDomainValid] = useState(false);

  useEffect(() => {
    setDomainCategories([
      ...defaultCategories,
      ...new Set(user.preferences?.domains?.map((domain) => {return {label: domain.category}}))
    ]);
  }, [user]);

  const handleNewDomain = () => {
    if(!user.preferences?.domains?.some((url) => url.name === newDomain)) {
      const addedDomain = {
        name: newDomain,
        category: newDomainCategory,
      }
      if(user.preferences && user.preferences.domains) {
        user.preferences.domains = [
          ...user.preferences.domains, addedDomain];
      }
      else {
        user.preferences['domains'] = [addedDomain];
      }
      updateUserPreferences(
        user.userid,
        user.preferences,
      )
      .then((data) => {
        if(data.status === 'success') {
          setUser({
            ...user,
            preferences: data.user.preferences,
          });
          setNewDomainCategory("");
        }
      });
    }
    else {
      setDomainValid(false);
    }
  };

  const handleDeleteDomain = (domain) => {
    if(user.preferences && user.preferences.domains) {
      user.preferences.domains = user.preferences.domains.filter((url) => {
        return url !== domain;
      });
      updateUserPreferences(
        user.userid,
        user.preferences,
      )
      .then((data) => {
        if(data.status === 'success') {
          setUser({
            ...user,
            preferences: data.user.preferences,
          });
          setNewDomainCategory("");
        }
      });
    }
  };

  const domainValidateCallback = (valid: boolean) => {
    setDomainValid(valid);
  };

  const domainChangeCallback = (domain: string) => {
    setNewDomain(domain);
  };

  return (
    <>
      {user.loggedIn ? (
        <Box>
          <Box sx={{mb: 2}}>
            <Stack direction="row" spacing={0.5}>
              <HttpIcon color="primary" />
              <Typography variant="h6">Favorite Domains</Typography>
            </Stack>
            <Typography variant="p" sx={{fontSize: 14}}>
              Add your favorite domains and optional label. Favorite Domains are shown in input boxes.
            </Typography>
          </Box>
          <Stack
            direction={{xs: 'column', md: 'row'}}
            spacing={1}
            sx={(theme) => ({
              height: {xs: 'auto', md: 45},
              p: 0.75,
              border: 'solid',
              borderWidth: 2,
              borderRadius: 3,
              borderColor: theme.palette.primary.main,
              zIndex: 10, 
              flexGrow: 1,
            })}
          >
            <Box sx={{flexGrow: 1}}>
              <UrlField
                domainOnly={true}
                hideSuggestions={true}
                validateCallback={domainValidateCallback}
                urlChangeCallback={domainChangeCallback}
                sx={{width: 250}}
              />
            </Box>
            <Autocomplete
              freeSolo
              clearOnBlur
              value={newDomainCategory}
              options={domainCategories}
              getOptionLabel={(option) => {return option.label || option || ""}}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if(params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setNewDomainCategory(newValue);
                }
                else if (newValue && newValue.inputValue) {
                  setNewDomainCategory(newValue.inputValue);
                }
                else if (newValue && newValue.label) {
                  setNewDomainCategory(newValue.label);
                }
              }}
              renderOption={(props, option) => <li {...props}>{option.label}</li>}
              size="small"
              sx={{ width: 150, pt: 0.5}}
              renderInput={(params) => <TextField {...params} label="Domain Tag" />}
            />
            <Button
              disabled={!domainValid}
              variant="contained"
              onClick={handleNewDomain}
              startIcon={<StarsIcon />}
            >
              Add
            </Button>
          </Stack>
          <Box>
            {user.preferences &&
              user.preferences.domains &&
              user.preferences.domains.length > 0
            ? (
              <Stack sx={{mt: 1, maxHeight: 300, overflowY: "auto"}} spacing={0.5}>
                {user.preferences.domains.map((domain, index) => (
                  <Paper key={index} sx={{my: 0.25, p: 1.25}} variant="outlined">
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={() => handleDeleteDomain(domain)}
                      >
                        Remove
                      </Button>
                      <Typography>{domain.name}</Typography>
                      {domain.category && (<Chip size="small" label={domain.category} sx={{ml: 1}} />)}
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            ) : (
              <Paper sx={{my: 1, p: 2, color: grey[500]}} variant="outlined">
                No Saved Domains
              </Paper>
            )}
          </Box>
        </Box>
      ) : ('')}
    </>
  );
}

export default AccountDomains;
