import React, { useEffect, useState } from 'react';
/* FLD */
import StatusChip from "../StatusChip/StatusChip";
/* MUI */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
/* icons */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CrawlUrlTree(props: {
  url: string,
  urlList: {},
  status?: number,
  showURLCallback?: (url: string) => void | null
}) {
  const {
    url = '',
    urlList = {},
    status = -1,
    showURLCallback = null,
  } = props;
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState([]);

  useEffect(() => {
    setChildren(
      urlList
      .filter((page) => {
        const parentURL = page.url.replace(/^(http|https):\/\/|\/+$/g, '').split('/').slice(0,-1).join('/')
        return parentURL === url.replace(/^(http|https):\/\/|\/+$/g, '');
      })
    );
  }, [urlList, url]);

  const showDetails = (e, url: string) => {
    e.stopPropagation();
    showURLCallback && showURLCallback(url);
  };

  return (
    <>
      {url !== '' ? (
        children.length > 0 ? (
          <Accordion
            expanded={open}
            onChange={() => setOpen(!open)}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary>
              <IconButton sx={{p: 0}}>
                {open ? <ExpandMoreIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
              </IconButton>
              <StatusChip status={status} sx={{ml: 2.5, mr: 2}} />
              {url} ({children.length} sub items)
              <Button
                variant="outlined"
                size="small" sx={{ml: 'auto'}}
                onClick={(e) => showDetails(e, url)}
              >
                URL Details
              </Button>
            </AccordionSummary>
            <AccordionDetails sx={{pr: 0, py: 0}}>
              {children.sort((a, b) => a.url.localeCompare(b.url)).map((page, index) => (
                  <CrawlUrlTree
                    key={index}
                    url={page.url}
                    urlList={urlList}
                    status={page.status.current}
                    showURLCallback={showURLCallback}
                  />
                )
              )}
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion expanded={open} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary>
              <StatusChip status={status} sx={{ml: 5, mr: 2}} />
              {url}
              <Button
                variant="outlined"
                size="small" sx={{ml: 'auto'}}
                onClick={(e) => showDetails(e, url)}
              >
                URL Details
              </Button>
            </AccordionSummary>
          </Accordion>
        )
      ) : ('')}
    </>
  );
}

export default CrawlUrlTree;
