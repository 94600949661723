import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from "../../global/context";
/* MUI */
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import CheckIcon from '@mui/icons-material/Check';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
/* MUI Colors */
import { green } from '@mui/material/colors';
/* HTML Tags */
import defaultExtractors from '../../global/defaultExtractors.json';

function ExtractorPicker(props: {
  customExtractorsCallback: () => void,
}) {
  const {
    customExtractorsCallback = null,
  } = props;
  const [user] = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedExtractors, setSelectedExtractors] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleExtractor = (extractorId: string) => {
    let updatedExtractors = [];
    if (selectedExtractors.includes(extractorId)) {
      updatedExtractors = [...selectedExtractors.filter((extractor) => extractor !== extractorId)];
    }
    else {
      updatedExtractors = [
        ...selectedExtractors,
        extractorId
      ]
    }
    setSelectedExtractors(updatedExtractors);
    customExtractorsCallback && customExtractorsCallback([
      ...user.preferences.extractors.filter((extractor) => 
        updatedExtractors.includes(extractor.id)
      )
    ]);
  };

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
    >
      <ToggleButton
        value="custom"
        onClick={handleClick}
      >
        <Badge
          badgeContent={selectedExtractors.length === 0 ? ' ' : selectedExtractors.length}
          color="primary"
          variant={selectedExtractors.length === 0 ? 'dot' : 'standard'}
        >
        <ManageSearchIcon />
        </Badge>
      </ToggleButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
            width: 250,
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Typography sx={{fontSize: 12, mx:1}}>
          HTML Extractors
        </Typography>
        <MenuList dense>
          {user?.preferences?.extractors?.length > 0 &&
            user.preferences.extractors.map((extractor, index) => (
            <MenuItem
              disabled={!user.loggedIn}
              key={index}
              onClick={() => toggleExtractor(extractor.id)}
            >
              {selectedExtractors.includes(extractor.id) ? (
                <ListItemIcon>
                  <CheckIcon sx={{color: green[500]}} />
                </ListItemIcon>
              ) : (
                <ListItemIcon />
              )}
              {extractor.label}
            </MenuItem>
          ))}
          {defaultExtractors.map((extractor, index) => (
            <MenuItem
              key={index}
              disabled={true}
            >
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              {extractor.label}
            </MenuItem>
          ))}
        </MenuList>
        <Button
          variant="outlined"
          component={Link}
          size="small"
          to={'/account/'}
          sx={{m: 1, ml: 6, px: 5}}
        >
          Add more
        </Button>
      </Menu>
    </ToggleButtonGroup>
  );
}

export default ExtractorPicker;
