import React, { useContext } from 'react';
import { BackendContext } from "../../global/context";
/* MUI */
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

function LoaderButton(props: {
  startIcon?: any,
  loading?: boolean,
  disabled?: boolean,
  onClickCallback?: () => void,
  variant?: string,
  color?: string,
}) {
  const {
    startIcon = '',
    loading = false,
    disabled = false,
    onClickCallback = undefined,
    variant = 'contained',
    color = 'primary'
  } = props;
  const [backend] = useContext(BackendContext);

  return (
    <Box>
      <LoadingButton
        variant={variant}
        color={color}
        sx={{height: '3.5rem', ...props.sx}}
        loading={loading}
        onClick={onClickCallback}
        startIcon={startIcon}
        disabled={!backend.connected || disabled}
      >
        {backend.connecting ? (
          <Stack>
            <>Connecting</>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </Stack>
        ) : (
          !backend.connected ? (
            <>Unavailable</>
          ) : (
            <Box sx={{whiteSpace: 'nowrap'}}>
              {props.children}
            </Box>
          )
        )}
      </LoadingButton>
    </Box> 
  );
}

export default LoaderButton;
