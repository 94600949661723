import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CodeIcon from '@mui/icons-material/Code';
import LanIcon from '@mui/icons-material/Lan';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimerIcon from '@mui/icons-material/Timer';
/* Image */
import urlfetch from '../../assets/urlfetch.png';

function FetchContent() {
  return (
    <Stack sx={{mb: 5}}>
      <Grid container sx={{mx: {xs: 0, md: 5}, my: 3}}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <BrowserUpdatedIcon fontSize="large" sx={(theme) => ({ color: theme.palette.dark.main })} />
              <Typography variant="h1">
                URL Performance
              </Typography>
            </Stack>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Check and render your webpage or app to measure load time performance, rendering issues, and external resources. Verify URLs and redirects are configured properly and check HTTP response headers for different device types and user agents.
            </Typography>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Verify title, meta tags, and other on-page content is properly tagged. Get additional content insights for keywords and phrases used in your content.
            </Typography>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Create an account to add custom data extractors to find and extract content from web pages. Find content within specific tags or based on tag attributes. Send data and extracted content to any API with a fully-customizable API integration interface or export to CSV.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <Paper
            component="img"
            src={urlfetch}
            sx={{mx: {xs: 0, md: 5}, width: 400, height: 300}}
          />
        </Grid>
      </Grid>
      <Paper sx={(theme) => ({my: 3, px: 5, py: 2, color: 'white', backgroundColor: theme.palette.primary.main})} elevation={3}>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <ScheduleIcon fontSize="large" />
              <Typography variant="h2">
                Scheduled Monitoring
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul>
              <li>Check pages daily, weekly, and monthly</li>
              <li>Track loading and rendering performance over time</li>
              <li>Extract content and data from HTML tags</li>
              <li>Send extracted data to APIs</li>
              <li>Stay informed about site, technical, and content changes</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3} sx={{textAlign: 'center'}}>
            <Button
              variant="contained"
              color="light"
              component={Link}
              to="/account/new"
            >
              Create Account
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{m: 5}}>
        <Grid container spacing={5} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <AirlineStopsIcon color="primary" />
              <Typography variant="h5">
                Redirects & Response Headers
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Check redirects and HTTP status</li>
                <li>View HTTP request response header and fields</li>
                <li>Verify canonical URL</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <TimerIcon color="primary" />
              <Typography variant="h5">
                Performance & Rendering
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Monitor performance and rendering timeline of a webpage</li>
                <li>Find bottlenecks in server connection and response time</li>
                <li>View page rendering and final paint timeline</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <LanIcon color="primary" />
              <Typography variant="h5">
                External Resources
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>View response timeline and size of external resources</li>
                <li>Search for specific domains and filetypes</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <CodeIcon color="primary" />
              <Typography variant="h5">
                Title & Meta Data
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Check title and meta description</li>
                <li>View inline meta fields and values</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <AccountTreeIcon color="primary" />
              <Typography variant="h5">
                Page Headings and Keywords
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>View H1 and H2 tags</li>
                <li>Get page keywords and density</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <ManageSearchIcon color="primary" />
              <Typography variant="h5">
                Data and Content Extraction
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Scrape content and data from HTML tags</li>
                <li>Find content based on HTML tag or attribute</li>
                <li>Send extracted data and content to APIs automatically</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}

export default FetchContent;
