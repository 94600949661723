import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiContext } from "../../global/context";
import { useNavigate, useParams } from "react-router-dom";
import {socket} from "../../api/socket";
import { createTasks } from "../../api/task";
import { CSVLink } from "react-csv";
/* FLD */
import ApiConnect from "../ApiConnect/ApiConnect";
import ExtractorPicker from "../ExtractorPicker/ExtractorPicker";
import FetchContent from "./FetchContent";
import FetchComparison from "./FetchComparison";
import FetchExtractor from "./FetchExtractor";
import FetchKeywordGrid from "./FetchKeywordGrid";
import FetchResources from "./FetchResources";
import FetchUrlStep from "./FetchUrlStep";
import ScheduleSelect from "../ScheduleSelect/ScheduleSelect";
import SiteSpeedGraph from '../SiteSpeedGraph/SiteSpeedGraph';
import SummaryChip from "../SummaryChip/SummaryChip";
import TaskBox from "../TaskBox/TaskBox";
import ToolSteps from "../ToolSteps/ToolSteps";
import UrlField from "../UrlField/UrlField";
import UserAgentPicker from "../UserAgentPicker/UserAgentPicker";
/* MUI */
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
/* MUI Icons */
import BackspaceIcon from '@mui/icons-material/Backspace';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import SpeedIcon from '@mui/icons-material/Speed';

function Fetch() {
  const { refreshTasks } = useContext(ApiContext);
  const [pageData, setPageData] = useState({});
  const [requestURL, setRequestURL] = useState("");
  const [userAgent, setUserAgent] = useState({mode: "desktop"});
  const [customExtractors, setCustomExtractors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState([]);
  const [toolTab, setToolTab] = useState(0);
  const [taskSchedule, setTaskSchedule] = useState({});
  const [notificationTypes, setNotificationTypes] = useState([]);
  const toolSteps = [
    {'stage': 'start', 'label': 'Started'},
    {'stage': 'crawl', 'label': 'Crawling'},
    {'stage': 'render', 'label': 'Rendering'},
    {'stage': 'content', 'label': 'Analyzing Content'},
  ];
  const [cols, setCols] = useState([]);
  const [showResources, setShowResources] = useState(false);
  const [showMetaTags, setShowMetaTags] = useState(false);
  const [showKeywords, setShowKeywords] = useState(false);
  const navigate = useNavigate();

  let taskId = useParams("taskId");

  useEffect(() => {
    socket.on("fetch_started", data => {
      setProgress([]);
      setError("");
      setPageData({});
      setLoading(true);
    });
    socket.on("fetch_complete", (data) => {
      setError("");
      setPageData(data);
      data.success && setCols([
        {key: 'title', label: 'Title'},
        {key: 'description', label: 'Meta Description'},
        {key: 'h1', label: 'H1'},
        {key: 'h2', label: 'H2'},
        ...data?.customColumns?.map((col) => ({
          key: col.id,
          label: col.label
        }))
      ]);
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("fetch_failed", (data) => {
      setError("Couldn't process your request");
      setPageData({});
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("fetch_progress", (data) => {
      setProgress(prev => [...prev, data]);
    });
    return () => {
      socket.off("fetch_started");
      socket.off("fetch_progress");
      socket.off("fetch_complete");
      socket.off("fetch_failed");
    }
  }, []);

  useEffect(() => {
    socket.on('disconnect', (response) => {
      if(loading && (response === 'transport close' || response === 'transport error')) {
        setLoading(false);
        setError(() => (
          <Stack spacing={2}><Typography sx={{fontWeight: 900}}>Your request took too long to process</Typography>
            <Box>Create an account to crawl larger sites and run longer processes</Box>
            <Button
              variant="contained"
              component={Link}
              to={'/account/new'}
            >
              Create New Account
            </Button>
          </Stack>
        ));
      }
    });
  }, [loading]);

  useEffect(() => {
    if(taskId.taskId && taskId.taskId !== "" && Object.keys(pageData).length === 0) {
      setError("");
      setLoading(true);
      !socket.connected && socket.connect();
      socket.on("disconnect", (reason) => {
        setLoading(false);
        setProgress([]);
      });
      socket.emit("fetch_start", {
        token: localStorage.getItem('jwt_token'),
        config: {mode: 'report'},
        task: taskId.taskId,
      });
    }
  }, [taskId, pageData]);

  const fetchUrl = () => {
    if(requestURL !== '' && requestURL !== 'https://') {
      setError("");
      setPageData({});
      setProgress([]);
      setLoading(true);
      !socket.connected && socket.connect();
      socket.on("disconnect", (reason) => {
        setLoading(false);
        setProgress([]);
      });
      socket.emit("fetch_start", {
        token: localStorage.getItem('jwt_token'),
        config: {
          mode: userAgent.mode,
          url: requestURL,
          height: userAgent.height ? userAgent.height : '',
          width: userAgent.width ? userAgent.width : '',
          userAgent: userAgent.userAgent ? userAgent.userAgent : '',
          extractors: customExtractors,
          notificationTypes: notificationTypes,
        }
      });
    }
  };

  const handleNewSchedule = () => {
    if(requestURL !== '' && requestURL !== 'https://') {
      createTasks(
        'urlfetch',
        {
          mode: userAgent.mode,
          url: requestURL,
          height: userAgent.height ? userAgent.height : '',
          width: userAgent.width ? userAgent.width : '',
          userAgent: userAgent.userAgent ? userAgent.userAgent : '',
          extractors: customExtractors,
          notificationTypes: notificationTypes,
        },
        taskSchedule,
      )
      .then((data) => {
        if(data.status === 'success') {
          refreshTasks();
          setToolTab(1);
        }
      });
    }
  };

  const handleScheduleChange = (newSchedule) => { setTaskSchedule(newSchedule); };
  const handleURL = (url: string) => { setRequestURL(url); };
  const handleUserAgent = (ua) => { setUserAgent(ua); };
  const handleCustomExtractors = (extractors) => { setCustomExtractors(extractors); };
  const handleNotificationTypes = (pref) => { setNotificationTypes(pref); };

  const handleReset = () => {
    setError("");
    setPageData({});
    setLoading(false);
    navigate("/url-fetch");
  };

  return (
    <Container maxWidth="lg" sx={{mt: 1}}>
      {error && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <Stack spacing={2}>
            <Box>
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
                sx={{m: 0.5}}
              >
                Start Over
              </Button>
            </Box>
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          </Stack>
        </Container>
      )}
      <ToolSteps progress={progress} toolSteps={toolSteps} />
      {pageData && pageData.success === false && (
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={handleReset}
            startIcon={<BackspaceIcon />}
          >
            Start Over
          </Button>
          <Alert variant="outlined" severity="error">
            Couldn't fetch - check URL
          </Alert>
        </Stack>
      )}
      {pageData && pageData.success && (
        <Stack spacing={0.5} direction="column" sx={{m: 1}}>
          <Stack direction="row">
            <Stack sx={{flex: 1}} direction="row" spacing={1} alignItems="center">
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
                sx={{pl: 1.5, pr: 1, minWidth: 30}}
              >
                <Box sx={{display: {xs: 'none', sm: 'inline-flex'}}}>Start Over</Box>
              </Button>
              <SummaryChip
                label={pageData.url}
                startTime={pageData.startTime}
                endTime={pageData.endTime}
                userAgent={pageData.mode}
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <CSVLink
                headers={cols}
                data={[pageData?.extractorData?.current]}
                filename={"url-fetch.csv"}
                target="_blank"
              >
                <Button
                  variant="outlined"
                  startIcon={<DownloadForOfflineIcon />}
                  sx={{display: {xs: 'none', sm: 'inline-flex'}}}
                >
                  Download CSV
                </Button>
              </CSVLink>
              <ApiConnect
                extractorData={cols.map((col) => ({
                  label: col.label,
                  field: col.key,
                  value: [pageData?.extractorData?.current?.[col.key]]
                }))}
              />
            </Stack>
          </Stack>
          {pageData?.performance?.current?.navigation && (
            <Box>
              <Stack direction={{xs: 'column', md: 'row'}} spacing={1} sx={{alignItems: 'center'}}>
                <Paper elevation={2} sx={{mb: 1, width: {xs: '75%', sm: "50%"}, minHeight: 200, maxHeight: '50vh', overflowY: 'auto'}}>
                  <Box sx={{position: 'relative'}}>
                    <Box
                      component="img"
                      src={`data:image/png;base64,${pageData.screenshot}`}
                      alt=""
                      sx={{width: '100%', height: 'auto%', objectFit: 'cover'}}
                    />
                    {pageData.screenshot === '' && (
                      <Box sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}>
                        <Stack sx={{flexGrow: 1, p: 5, textAlign: 'center'}} spacing={3}>
                          <Box sx={{fontWeight: 700}}>
                            Please create an account to render your page and measure performance
                          </Box>
                          <Button
                            component={Link}
                            to={'/account/new'}
                            variant="contained"
                          >
                            Create Account
                          </Button>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                </Paper>
                <Box sx={{maxWidth: '100vw', overflow: {xs: 'auto', md: 'visible'}}}>
                  <SiteSpeedGraph data={pageData?.performance?.current?.navigation[0]} />
                  <Stack direction="row" sx={{fontSize: 10}} spacing={0.5} justifyContent="flex-end">
                    <a href="https://www.w3.org/TR/navigation-timing-2/" target="_blank" rel="noreferrer">Navigation Timing</a>
                    <a href="https://www.w3.org/TR/resource-timing-2/" target="_blank" rel="noreferrer">Resource Timing</a>
                  </Stack>
                </Box>
              </Stack>
              {pageData?.performance?.current?.resources && (
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">External Resources</Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{pl: 5}}>First Paint</Typography>
                    </Grid>
                    <Grid item xs>
                      <FetchComparison
                        newValue={pageData?.performance?.current?.navigation[0].domInteractive.toFixed(0)}
                        oldValue={pageData?.performance?.previous?.navigation[0].domInteractive.toFixed(0)}
                        type="number"
                        label="ms"
                        target="lower"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{pl: 5}}>Load Time</Typography>
                    </Grid>
                    <Grid item xs>
                      <FetchComparison
                        newValue={pageData?.performance?.current?.navigation[0].loadEventEnd.toFixed(0)}
                        oldValue={pageData?.performance?.previous?.navigation[0].loadEventEnd.toFixed(0)}
                        type="number"
                        label="ms"
                        target="lower"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={4}>
                      <Stack direction="row" alignItems="center">
                        <IconButton
                          onClick={() => setShowResources(!showResources)}
                          sx={{width: 40, height: 40}}
                        >
                          <ExpandCircleDownRoundedIcon
                            color={showResources ? "primary" : ""}
                            sx={{transform: showResources ? '': 'rotate(-90deg)', transition: '100ms'}}
                          />
                        </IconButton>
                        <Typography variant="h6">External Resources</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs>
                      {`${pageData?.performance?.current.resources.length} URLs`}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {showResources && (
                      <Paper sx={{width: '100%', overflow: 'auto'}} variant="outlined">
                        <FetchResources data={pageData?.performance?.current.resources} />
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          <Typography variant="h5" sx={{textAlign: 'left'}}>URL Resolution</Typography>
          <Box>
            <FetchUrlStep data={pageData?.request} />
          </Box>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Meta Data</Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" sx={{pl: {xs: 0, sm: 5}}}>Canonical URL</Typography>
              </Grid>
              <Grid item xs>
                <FetchComparison
                  newValue={pageData?.extractorData?.current?.canonical?.[0]}
                  oldValue={pageData?.extractorData?.previous?.canonical?.[0]}
                  type="string"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" sx={{pl: {xs: 0, sm: 5}}}>Robots Tag</Typography>
              </Grid>
              <Grid item xs>
                <FetchComparison
                  newValue={pageData?.extractorData?.current?.robots?.[0]}
                  oldValue={pageData?.extractorData?.previous?.robots?.[0]}
                  type="string"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" sx={{pl: {xs: 0, sm: 5}}}>Title</Typography>
              </Grid>
              <Grid item xs>
                <FetchComparison
                  newValue={pageData?.extractorData?.current?.title?.[0]}
                  oldValue={pageData?.extractorData?.previous?.title?.[0]}
                  type="string"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" sx={{pl: {xs: 0, sm: 5}}}>Meta Description</Typography>
              </Grid>
              <Grid item xs>
                <FetchComparison
                  newValue={pageData?.extractorData?.current?.description?.[0]}
                  oldValue={pageData?.extractorData?.previous?.description?.[0]}
                  type="string"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={4}>
                <Stack direction="row" alignItems="center">
                  <IconButton
                    onClick={() => setShowMetaTags(!showMetaTags)}
                    sx={{width: 40, height: 40}}
                  >
                    <ExpandCircleDownRoundedIcon
                      color={showMetaTags ? "primary" : ""}
                      sx={{transform: showMetaTags ? '': 'rotate(-90deg)', transition: '100ms'}}
                    />
                  </IconButton>
                  <Typography variant="h6">Meta Tags</Typography>
                </Stack>
              </Grid>
              <Grid item xs>
                {`${pageData?.meta?.current?.length} Tags`}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {showMetaTags && (
                <Paper sx={{ height: 300, width: '100%' }} elevation={0}>
                  <DataGrid
                    density="compact"
                    hideFooter
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'count', sort: 'desc' }],
                      },
                    }}
                    columns={[
                      { field: 'name', headerName: 'Name', width: 200 },
                      { field: 'content', headerName: 'Content', flex: 1 }
                    ]}
                    rows={pageData.meta.current}
                    disableColumnMenu={true}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Content</Typography>
            </Grid>
            <FetchExtractor extractorTitle="H1 Title" extractorItems={pageData?.extractorData?.current?.h1} />
            <FetchExtractor extractorTitle="H2 Title" extractorItems={pageData?.extractorData?.current?.h2} />
            {pageData.customColumns.map((col, index) => (
              <FetchExtractor
                key={index}
                extractorTitle={col.label}
                extractorItems={pageData.extractorData.current[col.id]}
              />
            ))}
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={4}>
                <Stack direction="row" alignItems="center">
                  <IconButton
                    onClick={() => setShowKeywords(!showKeywords)}
                    sx={{width: 40, height: 40}}
                  >
                    <ExpandCircleDownRoundedIcon
                      color={showKeywords ? "primary" : ""}
                      sx={{transform: showKeywords ? '': 'rotate(-90deg)', transition: '100ms'}}
                    />
                  </IconButton>
                  <Typography variant="h6">Keywords</Typography>
                </Stack>
              </Grid>
              <Grid item xs>
                {`${pageData.ngrams.length} keywords`}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {showKeywords && (
                <FetchKeywordGrid data={pageData.ngrams} />
              )}
            </Grid>
          </Grid>
        </Stack>
      )}
      {error === "" && loading === false && Object.keys(pageData).length === 0 && (
        <Box sx={{mt: 2, textAlign: 'left'}}>
          <TaskBox
            taskType="urlfetch"
            icon={<BrowserUpdatedIcon />}
            tab={toolTab}
          >
            <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{p: 2, flexGrow: 1}}>
              <Stack
                direction={{xs: 'column', md: 'row'}}
                spacing={1}
                sx={(theme) => ({
                  height: {xs: 'auto', md: 45},
                  p: 0.75,
                  border: 'solid',
                  borderWidth: 2,
                  borderRadius: 3,
                  borderColor: theme.palette.primary.main,
                  zIndex: 10, 
                  flexGrow: 1,
                })}
              >
                <Box sx={{flexGrow: 1}}>
                  <UrlField
                    urlChangeCallback={handleURL}
                  />
                </Box>
                <Stack direction="row" spacing={0.5}>
                  <UserAgentPicker
                    hideResolution={true}
                    userAgentCallback={handleUserAgent}
                  />
                  <ExtractorPicker
                    customExtractorsCallback={handleCustomExtractors}
                  />
                </Stack>
              </Stack>
              <ScheduleSelect
                startIcon={<SpeedIcon />}
                scheduleChangeCallback={handleScheduleChange}
                startNowCallback={fetchUrl}
                loading={loading}
                setScheduleCallback={handleNewSchedule}
                setNotificationsCallback={handleNotificationTypes}
              />
            </Stack>
          </TaskBox>
          <FetchContent />
        </Box>
      )}
    </Container>
  );
}

export default Fetch;
