import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiContext } from "../../global/context";
import { useNavigate, useParams } from "react-router-dom";
import { socket } from "../../api/socket";
import { createTasks } from "../../api/task";
/* FLD */
import ChangeContent from "./ChangeContent";
import LoaderButton from "../LoaderButton/LoaderButton";
import ScheduleSelect from "../ScheduleSelect/ScheduleSelect";
import SummaryChip from "../SummaryChip/SummaryChip";
import TaskBox from "../TaskBox/TaskBox";
import TextCompare from "../TextCompare/TextCompare";
import ToolSteps from "../ToolSteps/ToolSteps";
import UrlField from "../UrlField/UrlField";
import UserAgentPicker from "../UserAgentPicker/UserAgentPicker";
/* MUI */
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import BackspaceIcon from '@mui/icons-material/Backspace';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DifferenceIcon from '@mui/icons-material/Difference';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import HttpIcon from '@mui/icons-material/Http';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
/* MUI Colors */
import { green, red } from '@mui/material/colors';
/* react-compare-slider */
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';


function Change() {
  const { refreshTasks } = useContext(ApiContext);
  const [mode, setMode] = useState("crawl");
  const [pageData, setPageData] = useState({});
  const [originalURL, setOriginalURL] = useState("");
  const [modifiedURL, setModifiedURL] = useState("");
  const [userAgent, setUserAgent] = useState({mode: "desktop"});
  const [compareType, setCompareType] = useState("first");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState([]);
  const [showHighlight, setShowHighlight] = useState(true);
  const originalTextField = useRef('');
  const modifiedTextField = useRef('');
  const [originalTextFocus, setOriginalTextFocus] = useState(false);
  const [modifiedTextFocus, setModifiedTextFocus] = useState(false);
  const [toolTab, setToolTab] = useState(0);
  const [taskSchedule, setTaskSchedule] = useState({});
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [compareTab, setCompareTab] = useState(0);
  const navigate = useNavigate();

  let taskId = useParams("taskId");

  const toolSteps = [
    {'stage': 'start', 'label': 'Started'},
    {'stage': 'crawl', 'label': 'Crawling'},
    {'stage': 'original', 'label': 'Processing Original'},
    {'stage': 'modified', 'label': 'Processing Modified'},
    {'stage': 'content', 'label': 'Analyzing Content'},
  ];

  useEffect(() => {
    socket.on("change_started", data => {
      setProgress([]);
      setError("");
      setPageData({});
      setLoading(true);
    });
    socket.on("change_complete", (data) => {
      setError("");
      setPageData(data);
      setLoading(false);
      setProgress([]);
      socket.disconnect();
      data.imageDiffs?.diff === '' && setCompareTab(1);
      data.codeDiffs?.diffs.length === 0 && setCompareTab(2);
    });
    socket.on("change_failed", (data) => {
      setError("Couldn't process your request");
      setPageData({});
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("change_progress", (data) => {
      setProgress(prev => [...prev, data]);
    });
    return () => {
      socket.off("change_started");
      socket.off("change_complete");
      socket.off("change_failed");
    }
  }, []);

  useEffect(() => {
    socket.on('disconnect', (response) => {
      if(loading && (response === 'transport close' || response === 'transport error')) {
        setLoading(false);
        setError(() => (
          <Stack spacing={2}><Typography sx={{fontWeight: 900}}>Your request took too long to process</Typography>
            <Box>Create an account to crawl larger sites and run longer processes</Box>
            <Button
              variant="contained"
              component={Link}
              to={'/account/new'}
            >
              Create New Account
            </Button>
          </Stack>
        ));
      }
    });
  }, [loading]);

  useEffect(() => {
    if(taskId.taskId && taskId.taskId !== "" && Object.keys(pageData).length === 0) {
      setError("");
      setLoading(true);
      !socket.connected && socket.connect();
      socket.on("disconnect", (reason) => {
        setLoading(false);
        setProgress([]);
      });
      socket.emit("change_start", {
        token: localStorage.getItem('jwt_token'),
        config: {mode: 'report'},
        task: taskId.taskId,
      });
    }
  }, [taskId, pageData]);

  const fetchChange = () => {
    setError("");
    setPageData({});
    setProgress([]);
    !socket.connected && socket.connect();
    socket.on("disconnect", (reason) => {
      //setError(reason);
      setLoading(false);
      setProgress([]);
    });
    socket.emit("change_start", {
      token: localStorage.getItem('jwt_token'),
      config: {
        mode: mode,
        original: mode === "crawl" ? originalURL : originalTextField.current.value,
        modified: mode === "crawl" ? modifiedURL : modifiedTextField.current.value,
        height: userAgent.height ? userAgent.height : '',
        width: userAgent.width ? userAgent.width : '',
        userAgent: userAgent.userAgent ? userAgent.userAgent : '',
        notificationTypes: notificationTypes,
      }
    });
  };

  const handleNewSchedule = () => {
    createTasks(
      'change',
      {
        mode: 'schedule',
        original: originalURL,
        modified: modifiedURL,
        height: userAgent.height ? userAgent.height : '',
        width: userAgent.width ? userAgent.width : '',
        userAgent: userAgent.userAgent ? userAgent.userAgent : '',
        compareType: compareType,
        notificationTypes: notificationTypes,
      },
      taskSchedule,
    )
    .then((data) => {
      if(data?.status === 'success') {
        refreshTasks();
        setToolTab(1);
      }
    });
  };

  const handleScheduleChange = (newSchedule) => { setTaskSchedule(newSchedule); };
  const handleUserAgent = (ua) => { setUserAgent(ua); };
  const handleNotificationTypes = (pref) => { setNotificationTypes(pref); };

  const handleReset = () => {
    setError("");
    setPageData({});
    setLoading(false);
    navigate("/change");
  };

  const toggleHighlight = () => {
    setShowHighlight(!showHighlight);
  };

  return (
    <div className="Change">
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Stack spacing={2}>
            <Box>
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
                sx={{m: 0.5}}
              >
                Start Over
              </Button>
            </Box>
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          </Stack>
        </Container>
      )}
      <Container maxWidth="lg">
        <ToolSteps progress={progress} toolSteps={toolSteps} direction="column-reverse" />
      </Container>
      {pageData && pageData.success && (
        <Container maxWidth="lg" sx={{my: 1, textAlign: 'left'}}>
          <Box>
            <Stack direction={{xs: 'column', md: 'row'}} sx={{alignItems: {xs: 'left', md: 'center'}}} spacing={1}>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={handleReset}
                  startIcon={<BackspaceIcon />}
                  sx={{p: 1, pl: 1.5, minWidth: 30}}
                >
                  <Box sx={{display: {xs: 'none', sm: 'inline-flex'}}}>Start Over</Box>
                </Button>
                <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                  <SummaryChip
                    label="Changes"
                    startTime={pageData.startTime}
                    endTime={pageData.endTime}
                    icon={pageData.mode === 'text' ? <TextSnippetIcon /> : <HttpIcon />}
                  />
                </Box>
              </Stack>
              <Box>
                <Tabs
                  value={compareTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={(e, tab) => setCompareTab(tab)}
                >
                  <Tab label="Render Changes" disabled={pageData.imageDiffs.diff === ""} />
                  <Tab label="HTML Changes" disabled={pageData.codeDiffs.diffs.length === 0} />
                  <Tab label="Content Changes" />
                </Tabs>
              </Box>
              <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                <SummaryChip
                  label="Changes"
                  startTime={pageData.startTime}
                  endTime={pageData.endTime}
                  icon={pageData.mode === 'text' ? <TextSnippetIcon /> : <HttpIcon />}
                />
              </Box>
            </Stack>
            <Paper variant="outlined">
              {compareTab === 0 && (
                <Box>
                  <Stack direction="row" sx={{m: 2, alignItems: 'center'}}>
                    <Box sx={{flexGrow: 1}}>
                      <FormGroup>
                        <FormControlLabel control={<Switch defaultChecked value={showHighlight} onChange={toggleHighlight} />} label="Highlight Changes" />
                      </FormGroup>
                    </Box>
                    <Typography>
                      Similarity
                    </Typography>
                    <Box sx={{ml: 1, position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={pageData.imageDiffs.ratio * 100}
                        color={pageData.imageDiffs.ratio < 0.66 ? pageData.imageDiffs.ratio < 0.33 ? "error" : "warning" : "success" }
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div">
                          {`${Math.round(pageData.imageDiffs.ratio * 100)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                  <Box sx={{mt: 2}}>
                    <ReactCompareSlider
                      itemOne={<ReactCompareSliderImage src={`data:image/png;base64,${pageData.imageDiffs.original}`} alt="Original" />}
                      itemTwo={<ReactCompareSliderImage src={`data:image/png;base64,${showHighlight ? pageData.imageDiffs.diff : pageData.imageDiffs.modified}`} alt="Modified" />}
                    />
                  </Box>
                  <Stack direction="row" spacing={1}>
                    <Stack sx={{width: "32%"}}>
                      <Typography sx={{textAlign: "center"}}>Original</Typography>
                      <img src={`data:image/png;base64,${pageData.imageDiffs.original}`} alt="original" />
                    </Stack>
                    <Stack sx={{width: "32%"}}>
                      <Typography sx={{textAlign: "center"}}>Modified</Typography>
                      <img src={`data:image/png;base64,${pageData.imageDiffs.modified}`} alt="modified" />
                    </Stack>
                    <Stack sx={{width: "32%"}}>
                      <Typography sx={{textAlign: "center"}}>Changes</Typography>
                      <img src={`data:image/png;base64,${pageData.imageDiffs.diff}`} alt="changes" />
                    </Stack>
                  </Stack>
                </Box>
              )}
              {compareTab === 1 && (
                <Box>
                  <Stack direction="row" sx={{m: 2, alignItems: 'center'}}>
                    {Object.keys(pageData.codeDiffs.diffs).length !== 0 && (
                      <Stack direction="row" sx={{flexGrow: 1}}>
                        <Typography sx={{pr: 3, color: green[700],}}>
                          {pageData.codeDiffs.diffs.filter((diff) => {return diff.type === "+"}).length} Added
                        </Typography>
                        <Typography sx={{pr: 3, color: red[700],}}>
                          {pageData.codeDiffs.diffs.filter((diff) => {return diff.type === "-"}).length} Removed
                        </Typography>
                      </Stack>
                    )}
                    <Typography>
                      Similarity
                    </Typography>
                    <Box sx={{ml: 1, position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={pageData.codeDiffs.ratio * 100}
                        color={pageData.codeDiffs.ratio < 0.66 ? pageData.codeDiffs.ratio < 0.33 ? "error" : "warning" : "success" }
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div">
                          {`${Math.round(pageData.codeDiffs.ratio * 100)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                  <TextCompare diffs={pageData.codeDiffs.diffs} />
                </Box>
              )}
              {compareTab === 2 && (
                <Box>
                  <Stack direction="row" sx={{m: 2, alignItems: 'center'}}>
                    {Object.keys(pageData.textDiffs.diffs).length !== 0 && (
                      <Stack direction="row" sx={{flexGrow: 1}}>
                        <Typography sx={{pr: 3, color: green[700],}}>
                          {pageData.textDiffs.diffs.filter((diff) => {return diff.type === "+"}).length} Added
                        </Typography>
                        <Typography sx={{pr: 3, color: red[700],}}>
                          {pageData.textDiffs.diffs.filter((diff) => {return diff.type === "-"}).length} Removed
                        </Typography>
                      </Stack>
                    )}
                    <Typography>
                      Similarity
                    </Typography>
                    <Box sx={{ml: 1, position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={pageData.textDiffs.ratio * 100}
                        color={pageData.textDiffs.ratio < 0.66 ? pageData.textDiffs.ratio < 0.33 ? "error" : "warning" : "success" }
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div">
                          {`${Math.round(pageData.textDiffs.ratio * 100)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                  <TextCompare diffs={pageData.textDiffs.diffs} />
                </Box>
              )}
            </Paper>
          </Box>
        </Container>
      )}
      {error === "" && loading === false && Object.keys(pageData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <TaskBox
            taskType="change"
            icon={<DifferenceIcon />}
            tab={toolTab}
          >
            <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{p: 2, flexGrow: 1}}>
              <Stack
                direction={{xs: 'column', md: 'row'}}
                spacing={1}
                sx={(theme) => ({
                  height: {xs: 'auto', md: 45},
                  p: 0.75,
                  border: 'solid',
                  borderWidth: 2,
                  borderRadius: 3,
                  borderColor: theme.palette.primary.main,
                  zIndex: 10, 
                  flexGrow: 1,
                })}
              >
                {toolTab === 0 && (
                  <ToggleButtonGroup
                    value={mode}
                    exclusive
                    size="small"
                    onChange={(e, mode) => mode !== null && setMode(mode)}
                  >
                    <ToggleButton value="crawl">
                      <HttpIcon />
                    </ToggleButton>
                    <ToggleButton value="text">
                      <TextSnippetIcon />
                    </ToggleButton>
                    <ToggleButton value="schedule">
                      <MoreTimeIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                {mode === "crawl" && (
                  <Stack direction={{xs: 'column', md: 'row'}} sx={{flexGrow: 1}} spacing={1}>
                    <Box sx={{flexGrow: 1, width: {xs: '100%', md: '50%'}}}>
                      <UrlField
                        urlChangeCallback={(url) => setOriginalURL(url)}
                      />
                    </Box>
                    <Box sx={{flexGrow: 1, width: {xs: '100%', md: '50%'}}}>
                      <UrlField
                        urlChangeCallback={(url) => setModifiedURL(url)}
                        startAdornment={<CompareArrowsIcon color="primary" sx={{mr: 1}} />}
                      />
                    </Box>
                    <UserAgentPicker userAgentCallback={handleUserAgent} />
                  </Stack>
                )}
                {mode === "schedule" && (
                  <Stack direction={{xs: 'column', md: 'row'}} sx={{flexGrow: 1}}>
                    <FormControl sx={{width: 150, backgroundColor: 'white', m: 0.5}} size="small">
                      <Select
                        value={compareType}
                        onChange={(e) => setCompareType(e.target.value)}
                      >
                        <MenuItem value={"first"}>
                          <Stack direction="row">
                            <FirstPageIcon fontSize="small" />
                            <Box>To First</Box>
                          </Stack>
                        </MenuItem>
                        <MenuItem value={"previous"}>
                          <Stack direction="row">
                            <ChevronLeftIcon fontSize="small" />
                            <Box>To Previous</Box>
                          </Stack>
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Box sx={{flexGrow: 1, mr: 1}}>
                      <UrlField
                        urlChangeCallback={(url) => setOriginalURL(url)}
                        startAdornment={<CompareArrowsIcon color="primary" sx={{mr: 1}} />}
                      />
                    </Box>
                    <UserAgentPicker userAgentCallback={handleUserAgent} />
                  </Stack>
                )}
                {mode === "text" && (
                  <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{pb: 3, flexGrow: 1}}>
                    <Box sx={{flexGrow: 1}}>
                      <Paper
                        elevation={originalTextFocus ? 3 : 0}
                        sx={{height: originalTextFocus ? '5rem' : '2.5rem'}}
                      >
                        <FormControl sx={{width: '100%'}}>
                          <InputLabel sx={{top: -3, left: -10}}>Original Text</InputLabel>
                          <InputBase
                            inputRef={originalTextField}
                            multiline
                            onFocus={() => setOriginalTextFocus(true)}
                            onBlur={() => setOriginalTextFocus(false)}
                            rows={originalTextFocus ? 3 : 1}
                          />
                        </FormControl>
                      </Paper>
                    </Box>
                    <CompareArrowsIcon color="primary" sx={{mr: 1, pt: 1.5}} />
                    <Box sx={{flexGrow: 1}}>
                      <Paper
                        elevation={modifiedTextFocus ? 3 : 0}
                        sx={{height: modifiedTextFocus ? '5rem' : '2.5rem'}}
                      >
                        <FormControl sx={{width: '100%'}}>
                          <InputLabel sx={{top: -3, left: -10}}>Modified Text</InputLabel>
                          <InputBase
                            label="Modified Text"
                            inputRef={modifiedTextField}
                            multiline
                            onFocus={() => setModifiedTextFocus(true)}
                            onBlur={() => setModifiedTextFocus(false)}
                            rows={modifiedTextFocus ? 3 : 1}
                          />
                        </FormControl>
                      </Paper>
                    </Box>
                  </Stack>
                )}
              </Stack>
              {mode !== 'schedule' && (
                <LoaderButton
                  loading={loading}
                  onClickCallback={fetchChange}
                  startIcon={<FindReplaceIcon />}
                >
                  Find Changes
                </LoaderButton>
              )}
              {mode === 'schedule' && (
                <ScheduleSelect
                  startIcon={<FindReplaceIcon />}
                  scheduleChangeCallback={handleScheduleChange}
                  loading={loading}
                  setScheduleCallback={handleNewSchedule}
                  setNotificationsCallback={handleNotificationTypes}
                />
              )}
            </Stack>
          </TaskBox>
          <ChangeContent/>
        </Container>
      )}
    </div>
  );
}

export default Change;
