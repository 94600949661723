import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  createUser,
  confirmUser,
} from "../../api/user";
/* FLD */
import LoaderButton from "../LoaderButton/LoaderButton";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoginIcon from '@mui/icons-material/Login';
/* MUI Colors */
import { green, red } from '@mui/material/colors';

function AccountCreate(props: { logout: () => void }) {
  const [accountName, setAccountName] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [accountPassword, setAccountPassword] = useState("");
  const [accountCode, setAccountCode] = useState("");
  const [accountError, setAccountError] = useState("");
  const [accountMessage, setAccountMessage] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showCreated, setShowCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  const handleAccount = () => {
    setLoading(true);
    createUser(accountEmail, accountPassword)
    .then((data) => {
      if(data?.status === 'success') {
        setAccountError("");
        setShowConfirm(true);
        setLoading(false);
      }
      else {
        setLoading(false);
        setAccountError(data.error);
      }
    });
  };

  const handleConfirm = () => {
    setLoading(true);
    confirmUser(accountEmail, accountName, accountCode)
    .then((data) => {
      if(data?.status === 'success') {
        setShowCreated(true);
        setAccountError("");
        setAccountMessage("Account confirmed - you can login");
        setLoading(false);
        setTimeout(() => {
          navigate('/account/login');
        }, 5000);
      }
      else {
        setLoading(false);
        setAccountError(data.error);
      }
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h1"
        className="slant-header"
        sx={{py: 3}}
      >
        Create a new account
      </Typography>
      {showConfirm ? (
        <Card variant="outlined" sx={{p: 3, textAlign: 'center'}}>
          {showCreated ? (
            <Stack spacing={4}>
              <Box sx={{color: green[700]}}>{accountMessage}</Box>
              <Box sx={{color: green[700]}}>Going to login page...</Box>
              <Button
                variant="outlined"
                component={Link}
                to={'/account/login'}
              >
                Login Now
              </Button>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Box sx={{mb: 2, textAlign: 'left'}}>
                Confirm your account
              </Box>
              <Box>
                <TextField
                  autoComplete="off"
                  id="account-email"
                  label="Email"
                  type="email"
                  value={accountEmail}
                  InputProps={{
                    readOnly: accountEmail !== "",
                    disabled: accountEmail !== "",
                  }}
                  onChange={(e) => {
                    setAccountEmail(e.target.value);
                  }}
                  sx={{width: 300}}
                />
              </Box>
              <Box>
                <TextField
                  autoComplete="off"
                  id="account-name"
                  label="Name"
                  value={accountName}
                  onChange={(e) => {
                    setAccountName(e.target.value);
                  }}
                  sx={{width: 300}}
                />
              </Box>
              <Box>
                <TextField
                  autoComplete="off"
                  id="account-code"
                  label="Confirmation Code"
                  onChange={(e) => {
                    setAccountCode(e.target.value);
                  }}
                  sx={{width: 300}}
                />
              </Box>
              <Box>
                <Box sx={{mb: 2, fontSize: 10, color: red[700]}}>{accountError}</Box>
                <Box sx={{mb: 2, fontSize: 10, color: green[700]}}>{accountMessage}</Box>
                <LoadingButton
                  variant="outlined"
                  onClick={handleConfirm}
                  startIcon={<AddCircleIcon />}
                  loading={loading}
                  sx={{width: 300}}
                >
                  Confirm Account
                </LoadingButton>
              </Box>
            </Stack>
          )}
        </Card>
      ) : (
        <Stack spacing={5} sx={{mb: 3}}>
          <Card variant="outlined" sx={{p: 3, textAlign: 'center'}}>
            <Stack spacing={4}>
              <Box sx={{textAlign: 'left'}}>
                Please enter a valid email and password. You'll need to confirm your email to activate your account.
              </Box>
              <Box>
                <TextField
                  autoComplete="off"
                  id="account-email"
                  label="Email"
                  type="Email"
                  onChange={(e) => {
                    setAccountEmail(e.target.value);
                  }}
                sx={{width: 300}}
                />
              </Box>
              <Box>
                <TextField
                  helperText={accountPassword.length < 6 || accountPassword.length > 20 ? 'Password should be 6-20 characters' : ''}
                  autoComplete="off"
                  id="account-password"
                  label="Password"
                  type="password"
                  onChange={(e) => {
                    setAccountPassword(e.target.value);
                  }}
                sx={{width: 300}}
                />
              </Box>
              <Box>
                <Box sx={{mb: 2, fontSize: 10, color: red[700]}}>{accountError}</Box>
                <Box sx={{mb: 2, fontSize: 10, color: green[700]}}>{accountMessage}</Box>
                <LoaderButton
                  variant="contained"
                  onClickCallback={handleAccount}
                  startIcon={<AddCircleIcon />}
                  loading={loading}
                  sx={{width: 300}}
                >
                  Create Account
                </LoaderButton>
              </Box>
            </Stack>
          </Card>
          <Card variant="outlined" sx={{p:3, textAlign: 'center'}}>
            <Typography sx={{mb: 2}}>
              Already have an account?
            </Typography>
            <Button
              variant="outlined"
              component={Link}
              to={'/account/login'}
              startIcon={<LoginIcon />}
            >
              Login Now
            </Button>
          </Card>
        </Stack>
      )}
    </Container>
  );
}

export default AccountCreate;
