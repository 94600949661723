import React, { useContext } from 'react';
import { UserContext } from "../../global/context";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
/* MUI Colors */
import { grey } from '@mui/material/colors';
/* APIs */
import defaultAPIs from '../../global/defaultAPIs.json';

function AccountApis() {
  const [user] = useContext(UserContext);

  return (
    <>
      {user.loggedIn ? (
        <Box>
          <Stack direction="row" spacing={0.5}>
            <CloudUploadIcon color="primary" />
            <Typography variant="h6">Favorite APIs</Typography>
          </Stack>
          <Typography variant="p" sx={{fontSize: 14}}>
            Add and configure APIs from the URL Fetch and Crawl tools
          </Typography>
          <Box>
            {user.preferences &&
              user.preferences.apiConfigs &&
              user.preferences.apiConfigs.length > 0
            ? (
              <Stack sx={{mt: 1, maxHeight: 300, overflowY: "auto"}} spacing={0.5}>
                {user.preferences.apiConfigs.map((config, index) => (
                  <Paper key={index} sx={{my: 0.25, p: 1.25}} variant="outlined">
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        disabled={true}
                      >
                        Custom
                      </Button>
                      <Typography>{config.label}</Typography>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            ) : (
              <Paper sx={{my: 1, p: 2, color: grey[500]}} variant="outlined">
                No Saved API Configs
              </Paper>
            )}
            {defaultAPIs.length > 0
            ? (
              <Stack sx={{mt: 1, maxHeight: 300, overflowY: "auto"}} spacing={0.5}>
                {defaultAPIs.map((config, index) => (
                  <Paper key={index} sx={{my: 0.25, p: 1.25}} variant="outlined">
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={true}
                      >
                        Built In
                      </Button>
                      <Typography>{config.label}</Typography>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            ) : (
              <Paper sx={{my: 1, p: 2, color: grey[500]}} variant="outlined">
                No Saved API Configs
              </Paper>
            )}
          </Box>
        </Box>
      ) : ('')}
    </>
  );
}

export default AccountApis;
