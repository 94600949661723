import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from "../../global/context";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TuneIcon from '@mui/icons-material/Tune';

const desktopMode = {
    'mode': 'desktop',
    'userAgent': 'default',
    'width': 1024,
    'height': 768,
};

const mobileMode = {
    'mode': 'mobile',
    'userAgent': 'default',
    'width': 750,
    'height': 1334,
};

const userAgents = [
  {label: 'Chrome', value: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36'},
  {label: 'Firefox', value: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:108.0) Gecko/20100101 Firefox/108.0'},
  {label: 'Edge', value: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36 Edg/108.0.1462.54'},
  {label: 'Safari', value: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Safari/605.1.15'},
];

function UserAgentPicker(props: {
  hideResolution: boolean,
  userAgentCallback: () => void,
}) {
  const [user] = useContext(UserContext);
  const [mode, setMode] = useState("desktop");
  const [anchorEl, setAnchorEl] = useState(null);
  const [screenWidth, setScreenWidth] = useState(desktopMode.width);
  const [screenHeight, setScreenHeight] = useState(desktopMode.height);
  const [selectedUserAgent, setSelectedUserAgent] = useState(userAgents[0]);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserAgent = (ua) => {
    setSelectedUserAgent(ua);
    props.userAgentCallback && props.userAgentCallback({
      'mode': 'custom',
      'userAgent': ua.value,
      'width': screenWidth,
      'height': screenHeight,
    });
    setAnchorEl(null);
  };

  const handleCustomUserAgent = (ua) => {
    setSelectedUserAgent(ua);
    setScreenHeight(ua.height);
    setScreenWidth(ua.width);
    props.userAgentCallback && props.userAgentCallback({
      'mode': 'custom',
      'userAgent': ua.userAgent,
      'width': ua.width,
      'height': ua.height,
    });
    setAnchorEl(null);
  };

  const setToggleMode = (mode) => {
    setMode(mode);
    if(mode === 'desktop') {
      props.userAgentCallback && props.userAgentCallback(desktopMode);
    }
    if(mode === 'mobile') {
      props.userAgentCallback && props.userAgentCallback(mobileMode);
    }
  };

  return (
    <ToggleButtonGroup
      value={mode}
      exclusive
      size="small"
      onChange={(e, mode) => mode !== null && setToggleMode(mode)}
    >
      <ToggleButton value="desktop">
        <ComputerIcon />
      </ToggleButton>
      <ToggleButton value="mobile">
        <PhoneIphoneIcon />
      </ToggleButton>
      <ToggleButton
        value="custom"
        onClick={handleClick}
      >
        <TuneIcon />
      </ToggleButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
            width: 250,
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {!props.hideResolution && (
          <Box>
            <Typography sx={{fontSize: 12, mx:1}}>
              Resolution
            </Typography>
            <TextField
              label="Screen Width"
              value={screenWidth}
              size="small"
              sx={{ m: 1, width: 100 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              onChange={(e) => setScreenWidth(parseInt(e.target.value) || 0)}
            />
            <TextField
              label="Screen Height"
              value={screenHeight}
              size="small"
              sx={{ m: 1, width: 100 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              onChange={(e) => setScreenHeight(parseInt(e.target.value) || 0)}
            />
          </Box>
        )}
        <Typography sx={{fontSize: 12, mx:1}}>
          User Agent
        </Typography>
        {userAgents.map((ua, key) => (
          <MenuItem
            key={key}
            onClick={() => {handleUserAgent(ua);}}
            disableRipple
          >
            <ListItemIcon>
              {selectedUserAgent.label === ua.label && (<CheckCircleIcon />)}
            </ListItemIcon>
            <ListItemText>{ua.label}</ListItemText>
          </MenuItem>
        ))}
        {user.preferences?.userAgents && user.preferences?.userAgents.map((ua, key) => (
          <MenuItem
            key={key}
            onClick={() => {handleCustomUserAgent(ua);}}
            disableRipple
          >
            <ListItemIcon>
              {selectedUserAgent.label === ua.label && (<CheckCircleIcon />)}
            </ListItemIcon>
            <ListItemText>{ua.label}</ListItemText>
          </MenuItem>
        ))}
        <Button
          variant="outlined"
          component={Link}
          size="small"
          to={'/account/'}
          sx={{m: 1, ml: 6, px: 5}}
        >
          Add more
        </Button>
      </Menu>
    </ToggleButtonGroup>
  );
}

export default UserAgentPicker;
