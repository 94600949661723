import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import CodeIcon from '@mui/icons-material/Code';
import DifferenceIcon from '@mui/icons-material/Difference';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
/* Image */
import change from '../../assets/change.png';

function ChangeContent() {
  return (
    <Stack sx={{mb: 5}}>
      <Grid container sx={{mx: {xs: 0, md: 5}, my: 3}}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <DifferenceIcon fontSize="large" sx={(theme) => ({ color: theme.palette.dark.main })} />
              <Typography variant="h1">
                Change Detection
              </Typography>
            </Stack>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Find changes and differences between webpages and content. View the visual rendering differences between webpages as well as line-by-line code changes and additions, removals, and overall changes to page content.
            </Typography>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Schedule change detection to monitor and track changes for a web page. Compare changes over time or look for changes compared to the first time the page was originally tracked.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <Paper
            component="img"
            src={change}
            sx={{mx: 5, width: 400, height: 300}}
          />
        </Grid>
      </Grid>
      <Paper sx={(theme) => ({my: 3, px: 5, py: 2, color: 'white', backgroundColor: theme.palette.primary.main})} elevation={3}>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <ScheduleIcon fontSize="large" />
              <Typography variant="h2">
                Scheduled Detection
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul>
              <li>Check pages daily, weekly, and monthly</li>
              <li>Track changes to rendering, code, and content over time</li>
              <li>Stay informed about site, technical, and content changes</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3} sx={{textAlign: 'center'}}>
            <Button
              variant="contained"
              color="light"
              component={Link}
              to="/account/new"
            >
              Create Account
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{m: 5}}>
        <Grid container spacing={5} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <CodeIcon color="primary" />
              <Typography variant="h5">
                Compare Page Source
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Find changes, updates, and deletions to source code</li>
                <li>Find changes to inline page content</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <InsertPhotoIcon color="primary" />
              <Typography variant="h5">
                Page Rendering
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Find differences between rendered versions of a page</li>
                <li>See on-page changes</li>
                <li>Check overall similarity between renders</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <TextSnippetIcon color="primary" />
              <Typography variant="h5">
                Text
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Find changes between two pieces of text content</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}

export default ChangeContent;
