import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
/* FLD */
import ApiConnect from "../ApiConnect/ApiConnect";
import FilterInput from "../FilterInput/FilterInput";
import StatusChip from "../StatusChip/StatusChip";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

function CrawlGrid(props: {
  data: {},
}) {
  const {
    data = {},
  } = props;
  const [cols, setCols] = useState([]);
  const [statusFilters, setStatusFilters] = useState([-1]);
  const [filteredStatuses, setFilteredStatuses] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'url',
      sort: 'asc',
    },
  ]);
  
  const formatSize = (filesize) => {
    if (filesize > (1024 * 1024)) {
      return `${(filesize / (1024 * 1024)).toPrecision(2)} mb`;
    }
    if (filesize > 1024) {
      return `${(filesize / 1024).toFixed(2)} kb`;
    }
    return `${filesize} b`;
  };

  const columnCompare = (a, b, m) => {
    const x = a?.[0]?.current || '';
    const y = b?.[0]?.current || '';
    if(m.api.getSortModel()[0]?.sort === 'asc') {
      if(x === '') return 1;
      if(y === '') return -1;
    }
    return x.localeCompare(y, undefined, { numeric: 'true' });
  };
  
  const urlCompare = (a, b, m) => {
    const x = a || '';
    const y = b || '';
    if(m.api.getSortModel()[0]?.sort === 'asc') {
      if(x === '') return 1;
      if(y === '') return -1;
    }
    return x.localeCompare(y, undefined, { numeric: 'true' });
  };

  const statusCompare = (a, b) => {
    const x = a?.current || 0;
    const y = b?.current || 0;
    if(x === y) return 0;
    if(x < y) return 1;
    if(x > y) return -1;
  };

  const numberCompare = (a, b) => {
    if(a === b) return 0;
    if(a < b) return 1;
    if(a > b) return -1;
  };

  const defaultCols = [
    {
      field: 'status',
      headerName: 'Status',
      width: 90,
      key: 'status',
      label: 'Status',
      renderCell: StatusCell,
      sortComparator: statusCompare
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 300,
      key: 'url',
      label: 'Page',
      renderCell: UrlCell,
      sortComparator: (a, b) => a.localeCompare(b)
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 350,
      key: 'title',
      label: 'Title',
      renderCell: CharacterCountCell,
      sortComparator: columnCompare
    },
    {
      field: 'description',
      headerName: 'Meta Description',
      width: 350,
      key: 'description',
      label: 'Meta Description',
      renderCell: CharacterCountCell,
      sortComparator: columnCompare
    },
    {
      field: 'h1',
      headerName: 'H1',
      width: 300,
      key: 'h1',
      label: 'H1',
      renderCell: TextCell,
      sortComparator: columnCompare
    },
    {
      field: 'h2',
      headerName: 'H2',
      width: 300,
      key: 'h2',
      label: 'H2',
      renderCell: TextCell,
      sortComparator: columnCompare
    }
  ];
  const summaryCols = [
    {
      field: 'canonical',
      headerName: 'Canonical URL',
      width: 300,
      key: 'canonical',
      label: 'Canonical URL',
      renderCell: TextCell,
      sortComparator: columnCompare
    },
    {
      field: 'redirect',
      headerName: 'Redirect',
      width: 300,
      key: 'redirect',
      label: 'Redirect',
      renderCell: RedirectCell,
      sortComparator: urlCompare
    },
    {
      field: 'responseTime',
      headerName: 'Response Time',
      width: 150,
      key: 'responseTime',
      label: 'Response Time',
      renderCell: TimeCell,
      type: 'number',
      sortComparator: numberCompare
    },
    {
      field: 'size',
      headerName: 'Size',
      width: 150,
      key: 'size',
      label: 'Size',
      renderCell: SizeCell,
      type: 'number',
      sortComparator: numberCompare
    },
  ];

  useEffect(() => {
    setStatusFilters([...new Set(data.crawlData?.map((row) => { return row.status.current; }))]);
    if (data?.customColumns?.length > 0) {
      setCols([
        ...defaultCols,
        ...data.customColumns.map((column) => 
          ({
            field: column.id,
            headerName: column.label,
            width: 300,
            key: column.id,
            label: column.label,
            renderCell: CustomCell,
            sortComparator: columnCompare
          })
        ),
        ...summaryCols
      ]);
    }
    else {
      setCols([...defaultCols, ...summaryCols]);
    }
  }, [data]);

  function StatusCell (props: {value: any}) {
    const { value } = props;
    const status = value;
    return (
      <React.Fragment>
        {status && (<StatusChip status={status.current} previousStatus={status.previous} />)}
      </React.Fragment>
    );
  };

  function UrlCell (props: {value: any}) {
    const { value } = props;
    let label = value;
    let link = false;
    try {
      const urlObj = new URL(value);
      if (urlObj['pathname']) {
        label = urlObj['pathname'];
        link = true;
      };
    } catch {};
    return (
      <React.Fragment>
        {link
          ? <a href={value} target="_blank" rel="noreferrer">{value}</a>
          : <>{label}</>
        }
      </React.Fragment>
    );
  };

  function CharacterCountCell (props: {value: any}) {
    const { value } = props;
    return (
      <React.Fragment>
        {value?.[0]?.current && (
          <Stack direction="row" sx={{flexGrow: 1, textAlign: 'left'}} alignItems="center">
            {value?.[0]?.current === value?.[0]?.previous && (
              <ChangeCircleIcon color="warning" />
            )}
            <Typography sx={{
              maxWidth: 290,
              fontSize: 14,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flexGrow: 1,
            }}>
              {value[0].current}
            </Typography>
            <Chip size="small" sx={{ml: 0.5}} label={value[0].current.length} />
          </Stack>
        )}
      </React.Fragment>
    );
  };

  function TextCell (props: {value: any}) {
    const { value } = props;
    return (
      <React.Fragment>
        {value?.[0]?.current && (
          <Stack direction="row" sx={{flexGrow: 1, textAlign: 'left'}} alignItems="center">
            {value?.[0]?.current === value?.[0]?.previous && (
              <ChangeCircleIcon color="warning" />
            )}
            <Typography sx={{
              maxWidth: 290,
              fontSize: 14,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flexGrow: 1,
            }}>
              {value[0].current}
            </Typography>
          </Stack>
        )}
      </React.Fragment>
    );
  };

  function CustomCell (props: {value: any}) {
    const { value } = props;
    return (
      <React.Fragment>
        {value.length > 0 && (
          <Stack direction="row" sx={{flexGrow: 1, textAlign: 'left'}} alignItems="center">
            <Typography sx={{
              maxWidth: 290,
              fontSize: 14,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flexGrow: 1,
            }}>
              {value
                .map((item) => item.current)
                .filter((item) => item !== '')
                .join(', ')
              }
            </Typography>
          </Stack>
        )}
      </React.Fragment>
    );
  };

  function RedirectCell (props: {value: any}) {
    const { value } = props;
    return (
      <React.Fragment>
        {value && (
          <Stack direction="row" sx={{flexGrow: 1, textAlign: 'left'}}>
            <Typography sx={{
              maxWidth: 290,
              fontSize: 14,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flexGrow: 1,
            }}>
              {value}
            </Typography>
          </Stack>
        )}
      </React.Fragment>
    );
  };

  function TimeCell (props: {value: any}) {
    const { value } = props;
    return (
      <React.Fragment>
        {value > 1 ? `${value.toFixed(3)} s` : `${(value * 1000).toFixed(0)} ms`}
      </React.Fragment>
    );
  };

  function SizeCell (props: {value: any}) {
    const { value } = props;
    return (
      <React.Fragment>
        {formatSize(value)}
      </React.Fragment>
    );
  };

  return (
    <Stack sx={{flexGrow: 1}}>
      <Stack direction="row" sx={{m: 0.5, mb: 0, height: '3rem'}}>
        <Stack direction="row" sx={{flexGrow: 1}} spacing={1}>
          <Select
            size="small"
            sx={{width: 90, height: 40, fontSize: 14}}
            multiple
            displayEmpty
            value={filteredStatuses}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return `Status`;
              }
              return selected.join(', ');
            }}
            onChange={(e) => {
              setFilteredStatuses(e.target.value);
            }}
          >
            <MenuItem disabled value="">
              <>Response Status</>
            </MenuItem>
            {statusFilters.sort().map((item, key) => (
              <MenuItem key={key} value={item}>
                <Checkbox checked={filteredStatuses.indexOf(item) > -1} />
                <StatusChip status={item} />
              </MenuItem>
            ))}
          </Select>
          <FilterInput
            label="Find"
            value={filterString}
            filterChangeCallback={(value) => setFilterString(value)}
          />
        </Stack>
        {cols && (
          <Stack direction="row" spacing={1}>
            <CSVLink
              headers={cols}
              data={data.crawlData.map((row) => {
                let cleanRow = {};
                Object.entries(row).forEach((item, key) => {
                  if (item[0] === 'status') {
                    cleanRow[item[0]] = item[1].current;
                  }
                  else if (Array.isArray(item[1])) {
                    cleanRow[item[0]] = item[1]
                      .map((item) => item.current)
                      .filter((item) => item !== '')
                      .join(', ');
                  }
                  else {
                    cleanRow[item[0]] = item[1];
                  }
                });
                return cleanRow;
              })}
              enclosingCharacter={`"`}
              filename={"site-crawl.csv"}
              target="_blank"
            >
              <Button
                variant="outlined"
                startIcon={<DownloadForOfflineIcon />}
                sx={{display: {xs: 'none', sm: 'inline-flex'}}}
              >
                Download CSV
              </Button>
            </CSVLink>
            <ApiConnect
              extractorData={cols.map((col) => ({
                label: col.headerName,
                field: col.field,
                value: Array.isArray(data?.crawlData?.[0]?.[col.field]) ?
                    [data?.crawlData?.[0]?.[col.field]
                      .map((item) => item.current)
                      .filter((item) => item !== '')
                      .join(', ')]
                  : [data?.crawlData?.[0]?.[col.field]?.current] || [data?.crawlData?.[0]?.[col.field]] || []
              }))}
            />
          </Stack>
        )}
      </Stack>
      <Box sx={{flexGrow: 1, height: 'calc(100vh - 13rem)', overflowY: 'auto', backgroundColor: '#FFF'}}>
        <DataGrid
          density="compact"
          columns={cols}
          rows={
            data.crawlData
            .filter((row) => {
              if(filterString !== '') {
                return Object.entries(row).some((elem) => {
                  if (typeof elem?.[1] === "string") {
                    return elem?.[1]?.toLowerCase().includes(filterString.toLowerCase());
                  }
                  if (Array.isArray(elem)) {
                    return elem?.[1]?.[0]?.current?.toLowerCase().includes(filterString.toLowerCase());
                  }
                });
              }
              else {
                return true;
              }
            })
            .filter((row) => {
              if(filteredStatuses.length > 0) {
                return filteredStatuses.includes(row.status.current);
              }
              return true;
            })
          }
          filterModel={filterModel}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          disableColumnMenu={true}
        />
      </Box>
    </Stack>
  );
}

export default CrawlGrid;
