import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiContext } from "../../global/context";
import { useNavigate, useParams } from "react-router-dom";
import { socket } from "../../api/socket";
import { createTasks } from "../../api/task";
import './flags.css';
/* FLD */
import AltLangContent from "./AltLangContent";
import BulkUrls from "../BulkUrls/BulkUrls";
import FilterInput from "../FilterInput/FilterInput";
import Grid from '@mui/material/Grid';
import ScheduleSelect from "../ScheduleSelect/ScheduleSelect";
import SummaryChip from "../SummaryChip/SummaryChip";
import TaskBox from "../TaskBox/TaskBox";
import ToolSteps from "../ToolSteps/ToolSteps";
import UrlField from "../UrlField/UrlField";
import UserAgentPicker from "../UserAgentPicker/UserAgentPicker";
import WorldMap from "../WorldMap/WorldMap";
/* MUI */
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
/* MUI Icons */
import BackspaceIcon from '@mui/icons-material/Backspace';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import HelpIcon from '@mui/icons-material/Help';
import LanguageIcon from '@mui/icons-material/Language';
import RuleIcon from '@mui/icons-material/Rule';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import PublicIcon from '@mui/icons-material/Public';
import ShareIcon from '@mui/icons-material/Share';
/* MUI Colors */
import { green, grey, red } from '@mui/material/colors';

function AltLang() {
  const { refreshTasks } = useContext(ApiContext);
  const [mode, setMode] = useState("crawl");
  const [pageData, setPageData] = useState({});
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [requestURL, setRequestURL] = useState("");
  const [urlList, setUrlList] = useState([]);
  const [userAgent, setUserAgent] = useState({mode: "desktop"});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState([]);
  const [toolTab, setToolTab] = useState(0);
  const [taskSchedule, setTaskSchedule] = useState({});
  const [notificationTypes, setNotificationTypes] = useState([]);
  const navigate = useNavigate();
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterValues: [],
  });
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'country',
      sort: 'asc',
    },
  ]);

  const toolSteps = [
    {'stage': 'start', 'label': 'Started'},
    {'stage': 'crawl', 'label': 'Crawling'},
    {'stage': 'tags', 'label': 'Analyzing Tags'},
  ];
  let taskId = useParams("taskId");

  useEffect(() => {
    socket.on("altlang_started", data => {
      setProgress([]);
      setError("");
      setPageData({});
      setLoading(true);
    });
    socket.on("altlang_complete", (data) => {
      setError("");
      setPageData(data);
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("altlang_failed", (data) => {
      setError("Couldn't process your request");
      setPageData({});
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("altlang_progress", (data) => {
      setProgress(prev => [...prev, data]);
    });
    return () => {
      socket.off("altlang_started");
      socket.off("altlang_complete");
      socket.off("altlang_failed");
      socket.off("altlang_progress");
    }
  }, []);

  useEffect(() => {
    socket.on('disconnect', (response) => {
      if(loading && (response === 'transport close' || response === 'transport error')) {
        setLoading(false);
        setError(() => (
          <Stack spacing={2}><Typography sx={{fontWeight: 900}}>Your request took too long to process</Typography>
            <Box>Create an account to crawl larger sites and run longer processes</Box>
            <Button
              variant="contained"
              component={Link}
              to={'/account/new'}
            >
              Create New Account
            </Button>
          </Stack>
        ));
      }
    });
  }, [loading]);

  useEffect(() => {
    if(taskId.taskId && taskId.taskId !== "" && Object.keys(pageData).length === 0) {
      setError("");
      setLoading(true);
      !socket.connected && socket.connect();
      socket.on("disconnect", (reason) => {
        setLoading(false);
        setProgress([]);
      });
      socket.emit("altlang_start", {
        token: localStorage.getItem('jwt_token'),
        config: {mode: 'report'},
        task: taskId.taskId,
      });
    }
  }, [taskId, pageData]);

  const fetchUrl = () => {
    setError("");
    setPageData({});
    setProgress([]);
    !socket.connected && socket.connect();
    socket.on("disconnect", (reason) => {
      //setError(reason);
      setLoading(false);
      setProgress([]);
    });
    socket.emit("altlang_start", {
      token: localStorage.getItem('jwt_token'),
      config: {
        mode: mode,
        urls: mode === 'list' ? urlList : [{id: 0, url: requestURL}],
        userAgent: userAgent.userAgent ? userAgent.userAgent : '',
        notificationTypes: notificationTypes,
      }
    });
  };

  const handleNewSchedule = () => {
    createTasks(
      'altlang',
      {
        mode: mode,
        urls: mode === 'list' ? urlList : [{id: 0, url: requestURL}],
        userAgent: userAgent.userAgent ? userAgent.userAgent : '',
        notificationTypes: notificationTypes,
      },
      taskSchedule,
    )
    .then((data) => {
      if(data.status === 'success') {
        refreshTasks();
        setToolTab(1);
      }
    });
  };

  const handleScheduleChange = (newSchedule) => { setTaskSchedule(newSchedule); };
  const handleNotificationTypes = (pref) => { setNotificationTypes(pref); };

  const countryCompare = (a, b) => {
    const x = a['name'] || '';
    const y = b['name'] || '';
    if(sortModel[0].sort === 'asc') {
      if(x === '') return 1;
      if(y === '') return -1;
    }
    return x.localeCompare(y, undefined, { numeric: 'true' });
  };

  const urlCompare = (a, b) => {
    const x = a['urlData'][0]['alturl'] || '';
    const y = b['urlData'][0]['alturl'] || '';
    if(sortModel[0].sort === 'asc') {
      if(x === '') return 1;
      if(y === '') return -1;
    }
    return x.localeCompare(y, undefined, { numeric: 'true' });
  };

  useEffect(() => {
    const cols = [
      {
        field: 'country',
        headerName: 'Country',
        width: 250,
        renderCell: CountryCell,
        sortComparator: countryCompare,
        label: 'Country',
        key: 'country'
      },
      {
        field: 'language',
        headerName: 'Language',
        width: 150,
        label: 'Language',
        key: 'language'
      },
      {
        field: 'tag',
        headerName: 'Tag',
        width: 100,
        label: 'Tag',
        key: 'tag'
      },
    ];
    pageData.analysis && pageData.analysis.forEach((group) => {
      cols.push(
        {
          field: `group${group.group}`,
          headerName: `Page Group ${group.group + 1}`,
          width: 300,
          renderCell: PageCell,
          sortComparator: urlCompare,
          label: `Page Group ${group.group + 1}`,
          key: `group${group.group}`,
          getApplyQuickFilterFn: (value: string) => {
            return (params: GridCellParams): boolean => {
              return params.value.urlData[0].alturl.includes(value);
            };
          },
        }
      );
    });
    setColumns(cols);

    const data = [];
    const countries = [];
    const languages = [];
    pageData.hreflangs && pageData.hreflangs.forEach((target, key) => {
      const pageGroups = {};
      pageData.analysis && pageData.analysis.forEach((group) => {
        pageGroups[`group${group.group}`] = 
          {
            urlData: group.alternatePages.filter((page) => {return page.hreflang === target.tag})
          };
      });
      data.push({
        id: key,
        country: {
          name: target.country,
          tag: target.tag,
        },
        language: target.language,
        tag: target.tag,
        ...pageGroups,
      });
      countries.filter((country) => {return country.name === target.country}).length === 0
      && countries.push({
        name: target.country,
        code: target.tag.split('-')[1],
      });
      languages.filter((language) => {return language.name === target.language}).length === 0
      && languages.push({
        name: target.language,
        code: target.tag.split('-')[0],
      });
    });
    setTableData(data);
    setSelectedCountries(countries);
    setSelectedLanguages(languages);

  }, [pageData]);

  const verifyURL = (page) => {
    const crawled = pageData.pages.find((crawled) => {
      return crawled.url.split('/').filter(Boolean).join('/') === page.alturl.split('/').filter(Boolean).join('/')
    });
    if (crawled === undefined) {
      return (
        <Badge variant="dot" color="error">
          <Chip
            variant="outlined"
            avatar={
              <Avatar sx={{
                bgcolor: grey[100],
                height: 24,
                width: 24,
                m: 0.25,
              }}>
                <HelpIcon />
              </Avatar>
            }
            label={
              <a
                href={page.alturl}
                target="_blank"
                rel="noreferrer"
              >
                {page.alturl}
              </a>
            }
          />
        </Badge>
      );
    }
    else {
      if (crawled && crawled.status === 200) {
        if (page.errors.length > 0) {
          return (
            <Badge badgeContent={page.errors.length} color="error">
              <Chip
                variant="outlined"
                avatar={
                  <Avatar sx={{
                    bgcolor: grey[100],
                    height: 24,
                    width: 24,
                    m: 0.25,
                  }}>
                    <CheckCircleIcon sx={{color: green[700]}} />
                  </Avatar>
                }
                label={
                  <a
                    href={page.alturl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {page.alturl}
                  </a>
                }
              />
            </Badge>
          );
        }
        else {
          return (
            <Chip
              variant="outlined"
              avatar={
                <Avatar sx={{
                  bgcolor: grey[100],
                  height: 24,
                  width: 24,
                  m: 0.25,
                }}>
                  <CheckCircleIcon sx={{color: green[700]}} />
                </Avatar>
              }
              label={
                <a
                  href={page.alturl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {page.alturl}
                </a>
              }
            />
          );
        }
      }
      else {
        return (
          <Badge variant="dot" color="error">
            <Chip
              variant="outlined"
              avatar={
                <Avatar sx={{
                  bgcolor: grey[100],
                  height: 24,
                  width: 24,
                  m: 0.25,
                }}>
                  <HelpIcon sx={{color: red[500]}}/>
                </Avatar>
              }
              label={
                <a
                  href={page.alturl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {page.alturl}
                </a>
              }
            />
          </Badge>
        );
      }
    }
  };

  const handleURL = (url) => {
    setRequestURL(url);
  };

  const handleUrlList = (urls) => {
    setUrlList(urls);
  };

  const handleUserAgent = (ua) => {
    setUserAgent(ua);
  };

  const handleReset = () => {
    setError("");
    setPageData({});
    setLoading(false);
    navigate("/alt-lang");
  };

  const PageCell = (props: {value: any}) => {
    const { value } = props;
    const page = value.urlData[0];
    return (
      <React.Fragment>
        <Stack sx={{my: 1}}>
          <Stack direction="row" spacing={1.5} sx={{mt: 1}}>
            {verifyURL(page)}
          </Stack>
          <Stack
            direction="column"
            alignItems="flex-start"
            spacing={0.25}
          >
            {pageData.pages.find((crawled) => {
                return crawled.url.split('/').filter(Boolean).join('/') === page.alturl.split('/').filter(Boolean).join('/')
              })
            ? page.errors.length > 0 && (
              <Stack
                direction="column"
                alignItems="flex-start"
                spacing={0.25}
                sx={{ml: 2}}
              >
                {page.errors.slice(0,5).map((error, index) => (
                  <Chip
                    key={index}
                    size="small"
                    variant="outlined"
                    avatar={
                      <Avatar>
                        {error['type'] === 'missing' && (<CodeOffIcon fontSize="small" />)}
                        {error['type'] === 'self' && (<CompareArrowsIcon fontSize="small" />)}
                        {error['type'] === 'mismatch' && (<RuleIcon fontSize="small" />)}
                      </Avatar>
                    }
                    label={`${error['msg']} "${error['hreflang']}"`}
                  />
                ))}
                {page.errors.length > 5 && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={`${page.errors.length - 5} more errors`}
                  />
                )}
              </Stack>
            ) : (
              <Chip
                size="small"
                variant="outlined"
                avatar={
                  <Avatar>
                    <CloudOffIcon fontSize="small" />
                  </Avatar>
                }
                label={"Couldn't fetch page"}
                sx={{ml: 2}}
              />
            )
          }
          </Stack>
        </Stack>
      </React.Fragment>
    );
  };

  const CountryCell = (props: {value: any}) => {
    const { value } = props;
    return (
      <React.Fragment>
        <Box className="f16" sx={{mr: 1}}>
          <div className={`flag ${value.tag.split('-')[1]}`} />
        </Box>
        {value.name}
      </React.Fragment>
    );
  };

  return (
    <div className="AltLang">
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Stack spacing={2}>
            <Box>
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
                sx={{m: 0.5}}
              >
                Start Over
              </Button>
            </Box>
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          </Stack>
        </Container>
      )}
      <Container maxWidth="lg">
        <ToolSteps progress={progress} toolSteps={toolSteps} direction="column-reverse" />
      </Container>
      {pageData && pageData.success === false && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={handleReset}
              startIcon={<BackspaceIcon />}
            >
              Start Over
            </Button>
            <Alert variant="outlined" severity="error">
              Couldn't fetch - check URL
            </Alert>
          </Stack>
        </Container>
      )}
      {pageData && pageData.success && (
        <Container
          maxWidth="100%"
          disableGutters={true}
          sx={{m: 0, p: 0, height: {xs: 'calc(100vh - 6.75rem)', sm: 'calc(100vh - 7.25rem)'}, width: '100%'}}
        >
          <Container maxWidth="100%" disableGutters={true}>
            <Grid container>
              <Grid item container direction="column" xs={8} sx={{p: 1}}>
                <Grid item sx={{flexGrow: 1}}>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
                      <Button
                        variant="outlined"
                        onClick={handleReset}
                        startIcon={<BackspaceIcon />}
                        sx={{p: 1, pl: 1.5, pr: 0.5, minWidth: 30}}
                      >
                        <Box sx={{display: {xs: 'none', sm: 'inline-flex'}}}>Start Over</Box>
                      </Button>
                      <SummaryChip
                        label="Pages"
                        count={Object.keys(pageData).length}
                        startTime={pageData.startTime}
                        endTime={pageData.endTime}
                      />
                    </Stack>
                    <Stack direction="row" sx={{alignItems: 'center'}}>
                      <AvatarGroup>
                        {selectedCountries.slice(0,4).map((country, key) => (
                          <Avatar key={key} sx={{height: 30, width: 30}}>
                            <span className="f16">
                              <div className={`flag ${country.code}`} />
                            </span>
                          </Avatar>
                        ))}
                        {selectedCountries.length > 4 && (
                          <Avatar sx={{height: 30, width: 30}}>
                            +
                          </Avatar>
                        )}
                      </AvatarGroup>
                      {selectedCountries.length} Countries
                    </Stack>
                    <Stack direction="row" sx={{alignItems: 'center'}}>
                      <AvatarGroup>
                        {selectedLanguages.slice(0,4).map((language, key) => (
                          <Avatar key={key} sx={{height: 30, width: 30, fontSize: 12}}>
                            {language.code}
                          </Avatar>
                        ))}
                        {selectedLanguages.length > 4 && (
                          <Avatar sx={{height: 30, width: 30}}>
                            +
                          </Avatar>
                        )}
                      </AvatarGroup>
                      {selectedLanguages.length} Languages
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item>
                  <FilterInput
                    label="Find"
                    value={filterModel.quickFilterValues?.length ? filterModel.quickFilterValues[0] : ''}
                    filterChangeCallback={(value) => {
                      setFilterModel({items: filterModel.items, quickFilterValues: [value]});
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{display: {xs: 'flex', sm: 'none'}}}>
                  <WorldMap countries={selectedCountries} size="small" />
                </Box>
                <Box sx={{display: {xs: 'none', sm: 'flex'}}}>
                  <WorldMap countries={selectedCountries} />
                </Box>
              </Grid>
            </Grid>
          </Container>
          <DataGrid
            density="compact"
            columns={columns}
            rows={tableData}
            filterModel={filterModel}
            getRowHeight={() => 'auto'}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            disableColumnMenu={true}
            sx={{height: {xs: 'calc(100vh - 18rem)', md: 'calc(100vh - 23rem)'}, backgroundColor: '#FFF'}}
          />
        </Container>
      )}
      {error === "" && loading === false && Object.keys(pageData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <TaskBox
            taskType="altlang"
            icon={<LanguageIcon />}
            tab={toolTab}
          >
            <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{p: 2, flexGrow: 1, minWidth: 0}}>
              <Stack
                direction={{xs: 'column', md: 'row'}}
                spacing={1}
                sx={(theme) => ({
                  height: {xs: 'auto', md: 45},
                  p: 0.75,
                  border: 'solid',
                  borderWidth: 2,
                  borderRadius: 3,
                  borderColor: theme.palette.primary.main,
                  zIndex: 10, 
                  flexGrow: 1,
                  minWidth: 0,
                })}
              >
                <ToggleButtonGroup
                  value={mode}
                  exclusive
                  size="small"
                  onChange={(e, mode) => mode !== null && setMode(mode)}
                >
                  <ToggleButton value="crawl">
                    <ShareIcon />
                  </ToggleButton>
                  <ToggleButton value="xml">
                    xml
                  </ToggleButton>
                  <ToggleButton value="list">
                    <PlaylistAddRoundedIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                {(mode === "crawl" || mode === "xml") && (
                  <Box sx={{flexGrow: 1}}>
                    <UrlField urlChangeCallback={handleURL} />
                  </Box>
                )}
                {mode === "list" && (
                  <Box sx={{flexGrow: 1, minWidth: 0}}>
                    <BulkUrls urlChangeCallback={handleUrlList} />
                  </Box>
                )}
                <UserAgentPicker
                  hideResolution={true}
                  userAgentCallback={handleUserAgent}
                />
              </Stack>
              <ScheduleSelect
                startIcon={<PublicIcon />}
                scheduleChangeCallback={handleScheduleChange}
                startNowCallback={fetchUrl}
                loading={loading}
                setScheduleCallback={handleNewSchedule}
                setNotificationsCallback={handleNotificationTypes}
              />
            </Stack>
          </TaskBox>
          <AltLangContent/>
        </Container>
      )}
    </div>
  );
}

export default AltLang;
