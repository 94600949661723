import React, { useState } from 'react';
/* FLD */
import StatusChip from "../StatusChip/StatusChip";
/* MUI */
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
/* MUI Icons */
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';

function FetchUrlStep(props: {
  data: {},
}) {
  const {
    data = {},
  } = props;
  const [showUrlHeaders, setShowUrlHeaders] = useState([]);

  return (
    <Stepper
      orientation="vertical" 
      sx={{
        '& .MuiStepConnector-line': {
           minHeight: '5px',
           ml: 0.8,
         },
         '& .MuiStepContent-root': {
           ml: 2.3,
         }
      }}
    >
      {data.map((item, key) => (
        <Step key={key} active={showUrlHeaders.includes(key)}>
          <StepLabel StepIconComponent={() => {return <StatusChip status={item['status']} />}}>
            <IconButton
              sx={{p: 0}}
              onClick={() => {
                if(showUrlHeaders.includes(key)) {
                  setShowUrlHeaders(showUrlHeaders.filter((item) => item !== key));
                }
                else {
                  setShowUrlHeaders([...showUrlHeaders, key]);
                }
              }}
            >
              <ExpandCircleDownRoundedIcon
                fontSize="small"
                color={showUrlHeaders.includes(key) ? "primary" : ""}
                sx={{transform: showUrlHeaders.includes(key) ? '': 'rotate(-90deg)', transition: '100ms'}}
              />
            </IconButton>
            {item['url']}
          </StepLabel>
          <StepContent>
            <Paper sx={{ height: 250, width: '100%' }} elevation={0}>
              <DataGrid
                density="compact"
                hideFooter
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'count', sort: 'desc' }],
                  },
                }}
                columns={[
                  { field: 'header', headerName: 'Header', width: 200 },
                  { field: 'content', headerName: 'Content', minWidth: 400, flex: 1 }
                ]}
                rows={item['headers']}
                disableColumnMenu={true}
              />
            </Paper>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

export default FetchUrlStep;
