import React, { useContext, useState } from 'react';
import { ApiContext, UserContext } from "../../global/context";
import { updateUserPreferences } from "../../api/user";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
/* Icons */
import SmartToyIcon from '@mui/icons-material/SmartToy';
import StarsIcon from '@mui/icons-material/Stars';
/* MUI Colors */
import { grey } from '@mui/material/colors';

function AccountUserAgents() {
  const [user, setUser] = useContext(UserContext);
  const { refreshTasks } = useContext(ApiContext);
  const [userAgentString, setUserAgentString] = useState("");
  const [userAgentLabel, setUserAgentLabel] = useState("");
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  const handleNewUserAgent = () => {
    const addedUserAgent = {
      userAgent: userAgentString,
      label: userAgentLabel,
      height: screenHeight,
      width: screenWidth,
    }
    if(user.preferences && user.preferences.userAgents) {
      user.preferences.userAgents = [
        ...user.preferences.userAgents, addedUserAgent];
    }
    else {
      user.preferences['userAgents'] = [addedUserAgent];
    }
    updateUserPreferences(
      user.userid,
      user.preferences,
    )
    .then((data) => {
      if(data.status === 'success') {
        setUser({
          ...user,
          preferences: data.user.preferences,
        });
        refreshTasks();
        setUserAgentString("");
        setUserAgentLabel("");
        setScreenHeight(0);
        setScreenWidth(0);
      }
    });
  };

  const handleDeleteUserAgent = (userAgent) => {
    if(user.preferences && user.preferences.userAgents) {
      user.preferences.userAgents = user.preferences.userAgents.filter((ua) => {
        return ua !== userAgent;
      });
      updateUserPreferences(
        user.userid,
        user.preferences,
      )
      .then((data) => {
        if(data.status === 'success') {
          setUser({
            ...user,
            preferences: data.user.preferences,
          });
          setUserAgentString("");
          setUserAgentLabel("");
          setScreenHeight(0);
          setScreenWidth(0);
        }
      });
    }
  };

  return (
    <>
      {user.loggedIn ? (
        <Box>
          <Box sx={{mb: 2}}>
            <Stack direction="row" spacing={0.5}>
              <SmartToyIcon color="primary" />
              <Typography variant="h6">Custom User Agents</Typography>
            </Stack>
            <Typography variant="p" sx={{fontSize: 14}}>
              Create a custom user agent for crawling pages and websites. Add optional device height and width for rendering features such as URL Performance and visual Change Detection.
            </Typography>
          </Box>
          <Stack direction={{xs: 'column', md: 'row'}} spacing={1} sx={{mb: 1}}>
            <TextField
              helperText={userAgentLabel === '' ? 'Add a label' : ''}
              label="Label"
              value={userAgentLabel}
              sx={{width: 250}}
              onChange={(e) => setUserAgentLabel(e.target.value || "")}
              error={user?.preferences?.userAgents?.find((userAgent) => userAgent.label === userAgentLabel)}
            />
            <TextField
              helperText={userAgentString === '' ? 'Add a user agent' : ''}
              label="User Agent String"
              value={userAgentString}
              sx={{minWidth: 300, flexGrow: 1}}
              onChange={(e) => setUserAgentString(e.target.value || "")}
            />
            <TextField
              label="Width"
              value={screenWidth}
              sx={{width: 120}}
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              onChange={(e) => setScreenWidth(parseInt(e.target.value) || 0)}
            />
            <TextField
              label="Height"
              value={screenHeight}
              sx={{width: 120}}
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              onChange={(e) => setScreenHeight(parseInt(e.target.value) || 0)}
            />
            <Button
              disabled={
                userAgentString === ''
                || userAgentLabel === ''
                || user?.preferences?.userAgents?.find((userAgent) => userAgent.label === userAgentLabel)
              }
              variant="contained"
              onClick={handleNewUserAgent}
              sx={{height: "3.5rem"}}
              startIcon={<StarsIcon />}
            >
              Add
            </Button>
          </Stack>
          <Box>
            {user.preferences &&
              user.preferences.userAgents &&
              user.preferences.userAgents.length > 0
            ? (
              <Stack sx={{maxHeight: 300, overflowY: "auto"}} spacing={0.5}>
                {user.preferences.userAgents.map((userAgent, index) => (
                  <Paper key={index} sx={{p: 1.25}} variant="outlined">
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={() => handleDeleteUserAgent(userAgent)}
                      >
                        Remove
                      </Button>
                      <Typography>
                        {userAgent.label} - {userAgent.height}px x {userAgent.width}px
                      </Typography>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            ) : (
              <Paper sx={{my: 1, p: 2, color: grey[500]}} variant="outlined">
                No Saved User Agents
              </Paper>
            )}
          </Box>
        </Box>
      ) : ('')}
    </>
  );
}

export default AccountUserAgents;
