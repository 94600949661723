import React from 'react';
/* MUI */
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
/* MUI Icons */
import DataArrayIcon from '@mui/icons-material/DataArray';
import DataObjectIcon from '@mui/icons-material/DataObject';
import OutputIcon from '@mui/icons-material/Output';
/* MUI Colors */
import { grey } from '@mui/material/colors';

function AddObjects(props: {
  parent: number,
  addElementCallback?: (name: string, type: string, parent: number) => void | null,
}) {
  const {
    parent = -1,
    addElementCallback = null,
  } = props;

  return (
    <Stack direction="row" spacing={0.5}>
      <Chip
        variant="outlined"
        size="small"
        clickable
        avatar={<DataArrayIcon />}
        label={
          <Box>
            List
          </Box>
        }
        onClick={() => addElementCallback && addElementCallback('', 'list', parent)}
        sx={{backgroundColor: grey[50]}}
      />
      <Chip
        variant="outlined"
        size="small"
        clickable
        avatar={<DataObjectIcon />}
        label={
          <Box>
            Object
          </Box>
        }
        onClick={() => addElementCallback && addElementCallback('', 'object', parent)}
        sx={{backgroundColor: grey[50]}}
      />
      <Chip
        variant="outlined"
        size="small"
        clickable
        avatar={<OutputIcon />}
        label={
          <Box>
            Key/Value
          </Box>
        }
        onClick={() => addElementCallback && addElementCallback('', 'field', parent)}
        sx={{backgroundColor: grey[50]}}
      />
    </Stack>
  );
}

export default AddObjects;
