import React, { useRef, useState } from 'react';
/* MUI */
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
/* MUI Icons */
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

function FilterInput(props: {
  label?: string,
  value?: string,
  filterChangeCallback?: (value: any) => void,
}) {
  const {
    label = '',
    value = ''
  } = props;
  const filter = useRef();
  const [loading, setLoading] = useState(false);

  return (
    <TextField
      placeholder={label}
      size="small"
      inputRef={filter}
      sx={{backgroundColor: '#FFF'}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {value === "" ? (
               <SearchIcon />
            ) : (
              <IconButton
                onClick={() => {
                  if (props.filterChangeCallback) {
                    setLoading(true);
                    props.filterChangeCallback("", setLoading(false));
                  }
                }}
                sx={{p: 0}}
              >
                <CancelIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="outlined"
              size="small"
              disabled={loading}
              onClick={() => { props.filterChangeCallback && props.filterChangeCallback(filter.current?.value); }}
            >
              {label}
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default FilterInput;
