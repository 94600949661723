import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AbcIcon from '@mui/icons-material/Abc';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VerifiedIcon from '@mui/icons-material/Verified';
/* Image */
import crawl from '../../assets/crawl.png';

function CrawlContent() {
  return (
    <Stack sx={{mb: 5}}>
      <Grid container sx={{mx: {xs: 0, md: 5}, my: 3}}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <CloudDownloadIcon fontSize="large" sx={(theme) => ({ color: theme.palette.dark.main })} />
              <Typography variant="h1">
                Site Crawler
              </Typography>
            </Stack>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Crawl your website and get URL status, meta data, content insights, and more. Discover and verify page content, keywords, and site structure for your website or list of pages.
            </Typography>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              You can crawl through a site using on-page links, check URLs in an XML sitemap, or crawl a list of URLs. Crawl as a desktop and mobile device or using custom user agents.
            </Typography>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Create an account to add custom data extractors to find and extract content from web pages. Find content within specific tags or based on tag attributes. Send data and extracted content to any API with a fully-customizable API integration interface or export to CSV.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <Paper
            component="img"
            src={crawl}
            sx={{mx: 5, width: 400, height: 300}}
          />
        </Grid>
      </Grid>
      <Paper sx={(theme) => ({my: 3, px: 5, py: 2, color: 'white', backgroundColor: theme.palette.primary.main})} elevation={3}>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <ScheduleIcon fontSize="large" />
              <Typography variant="h2">
                Scheduled Crawling
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul>
              <li>Check sites daily, weekly, and monthly</li>
              <li>Track content and URL changes over time</li>
              <li>Extract content and data from HTML tags</li>
              <li>Send extracted data to APIs</li>
              <li>Stay informed about site, technical, and content changes</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3} sx={{textAlign: 'center'}}>
            <Button
              variant="contained"
              color="light"
              component={Link}
              to="/account/new"
            >
              Create Account
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{m: 5}}>
        <Grid container spacing={5} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <AccountTreeIcon color="primary" />
              <Typography variant="h5">
                Site Crawling
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Crawl a site from a starting URL</li>
                <li>Crawl a list of URLs</li>
                <li>Fetch an XML sitemap and crawl linked sitemaps and URLs</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <VerifiedIcon color="primary" />
              <Typography variant="h5">
                URL Status
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Get URL response status</li>
                <li>Find redirects</li>
                <li>Identify missing and mismatched canonical URLs</li>
                <li>View overall site structure</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <AbcIcon color="primary" />
              <Typography variant="h5">
                Content Analysis
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>View page title tags and meta descriptions</li>
                <li>Check character lengths</li>
                <li>Export site crawl data to CSV</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <ManageSearchIcon color="primary" />
              <Typography variant="h5">
                Data and Content Extraction
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Scrape content and data from HTML tags</li>
                <li>Find content based on HTML tag or attribute</li>
                <li>Send extracted data and content to APIs automatically</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}

export default CrawlContent;
