import React, { useContext, useState } from 'react';
import { UserContext, BackendContext } from "../../global/context";
import { Link } from "react-router-dom";
import './Nav.css';
/* MUI */
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import MenuIcon from '@mui/icons-material/Menu';

function Nav(props: { logout: () => void }) {
  const [user] = useContext(UserContext);
  const [backend] = useContext(BackendContext);
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <header>
      <AppBar position="static">
        <Toolbar
          disableGutters
          sx={{
            margin: 0
          }}
        >
          <MenuItem
            component={Link}
            to="/"
          >
            <div className="slantbar">F</div>
            <div className="slantbar lightblue">*</div>
            <div className="slantbar">D</div>
            <div className="slantbar lightblue">A</div>
            <div className="slantbar">T</div>
            <div className="slantbar lightblue">A</div>
          </MenuItem>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: 'none',
                md: 'flex'
              }
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{flexGrow: 1}}
            >
              <MenuItem component={Link} to="/url-fetch/">
                <Typography
                  textAlign="center"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  URL Performance
                </Typography>
              </MenuItem>
              <MenuItem component={Link} to="/crawl/">
                <Typography
                  textAlign="center"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  Crawl
                </Typography>
              </MenuItem>
              <MenuItem component={Link} to="/change/">
                <Typography
                  textAlign="center"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  Change Detector
                </Typography>
              </MenuItem>
              <MenuItem component={Link} to="/tfidf/">
                <Typography
                  textAlign="center"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  Content Analysis
                </Typography>
              </MenuItem>
              <MenuItem component={Link} to="/alt-lang/">
                <Typography
                  textAlign="center"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  AltLang Check
                </Typography>
              </MenuItem>
              <MenuItem component={Link} to="/schema-search/">
                <Typography
                  textAlign="center"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  Find Schema
                </Typography>
              </MenuItem>
              <MenuItem component={Link} to="/itunes-search/">
                <Typography
                  textAlign="center"
                  sx={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  iTunes Search
                </Typography>
              </MenuItem>
            </Stack>
            <Stack sx={{flexGrow: 0, alignItems: 'center'}} direction="row">
              {backend.connecting && (
                <CircularProgress size={20} color="secondary" variant="indeterminate" />
              )}
              {backend.unavailable ? (
                <MenuItem>Service Unavailable</MenuItem>
              ) : (
                user.loggedIn ? (
                  <MenuItem component={Link} to="/account">{user.username}</MenuItem>
                ) : (
                  <MenuItem component={Link} to="/account/new">Create Account</MenuItem>
                )
              )}
            </Stack>
          </Box>
          <Box sx={{display: {xs: 'flex', md: 'none'}, flexGrow: 1, justifyContent: 'right'}}>
            <Box>
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem component={Link} onClick={handleCloseNavMenu} to="/url-fetch/">
                  <Typography textAlign="center">
                    URL Performance
                  </Typography>
                </MenuItem>
                <MenuItem component={Link} onClick={handleCloseNavMenu} to="/crawl/">
                  <Typography textAlign="center">
                    Crawl
                  </Typography>
                </MenuItem>
                <MenuItem component={Link} onClick={handleCloseNavMenu} to="/change/">
                  <Typography textAlign="center">
                    Change Detector
                  </Typography>
                </MenuItem>
                <MenuItem component={Link} onClick={handleCloseNavMenu} to="/tfidf/">
                  <Typography textAlign="center">
                    Content Analysis
                  </Typography>
                </MenuItem>
                <MenuItem component={Link} onClick={handleCloseNavMenu} to="/alt-lang/">
                  <Typography textAlign="center">
                    AltLang Check
                  </Typography>
                </MenuItem>
                <MenuItem component={Link} onClick={handleCloseNavMenu} to="/schema-search/">
                  <Typography textAlign="center">
                    Find Schema
                  </Typography>
                </MenuItem>
                <MenuItem component={Link} onClick={handleCloseNavMenu} to="/itunes-search/">
                  <Typography textAlign="center">
                    iTunes Search
                  </Typography>
                </MenuItem>
                <Divider />
                {backend.connecting && (
                  <CircularProgress size={20} color="secondary" variant="indeterminate" />
                )}
                {backend.unavailable ? (
                  <MenuItem>Service Unavailable</MenuItem>
                ) : (
                  user.loggedIn ? (
                    <MenuItem component={Link} to="/account">{user.username}</MenuItem>
                  ) : (
                    <MenuItem component={Link} to="/account/new">Create Account</MenuItem>
                  )
                )}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </header>
  );
}

export default Nav;
