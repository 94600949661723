
export const checkBackend = async () => {
  const response = fetch(`${process.env.REACT_APP_API}/`, {method: 'get'})
  .then(response => {
    if (!response.ok) { throw response }
    return response.json();
  })
  .then((data) => { return data; })
  .catch(error => {
    return {
      status: 'failed',
      error: "Can't connect to backend",
    };
  });
  return response;
};

export const authUser = async () => {
  let userError = "";
  const token = localStorage.getItem('jwt_token');

  if (token) {
    const response = fetch(
      `${process.env.REACT_APP_API}/user/auth`, {
          method: 'get',
          headers: {'Authorization': `Bearer ${token}`},
    })
    .then(response => {
      if (!response.ok) { throw response }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch(error => {
      if (error.status === 429) {
        userError = "Too many requests - please wait and try again later";
      }
      else if (error.status === 403) {
        userError = "Invalid JWT";
      }
      else {
        userError = "Couldn't process your request";
      }
      return {
        status: 'failed',
        error: userError,
      };
    });
    return response;
  }
  else {
    const response = {
      status: 'failed',
      error: 'Missing JWT',
    }
    return response;
  }
};

export const fetchUser = async (email, password) => {
  let userError = "";
  let login = {
    'username': email,
    'password': password
  }

  const response = fetch(
    `${process.env.REACT_APP_API}/user/login`, {
      method: 'post',
      body: JSON.stringify(login)
  })
  .then(response => {
    if (!response.ok) { throw response }
    return response.json();
    })
  .then((data) => {
    localStorage.setItem('jwt_token', data.access_token);
    return data;
  })
  .catch(error => {
    localStorage.setItem('jwt_token', '');
    if (error.status === 429) {
      userError = "Too many requests - please wait and try again later";
    }
    else {
      userError = "Couldn't process your request";
    }
    return {
      status: 'failed',
      error: userError
    };
  });
  return response;
};

export const fetchUserTasks = async () => {
  let userError = "";
  const token = localStorage.getItem('jwt_token');

  if (token) {
    const response = fetch(`${process.env.REACT_APP_API}/user/tasks`, {
      method: 'get',
      headers: {'Authorization': `Bearer ${token}`},
    })
    .then(response => {
      if (!response.ok) { throw response }
      return response.json();
      })
    .then((data) => {
      return data;
    })
    .catch(error => {
      if (error.status === 429) {
        userError = "Too many requests - please wait and try again later";
      }
      else {
        userError = "Couldn't process your request";
      }
      return {
        status: 'failed',
        error: userError
      };
    });
    return response;
  }
  else {
    const response = {
      status: 'failed',
      error: 'Missing JWT',
    }
    return response;
  }
};

export const createUser = async (email, password) => {
  let userError = "";
  let login = {
    'username': email,
    'password': password
  }
  const response = fetch(
    `${process.env.REACT_APP_API}/user/new`, {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(login)
  })
  .then((response) => {
    if (!response.ok) { throw response }
    return response.json();
  })
  .then((data) => {
    //localStorage.setItem('jwt_token', data.user.access_token);
    return data;
  })
  .catch(error => {
    localStorage.setItem('jwt_token', '');
    if (error.status === 429) {
      userError = "Too many requests - please wait and try again later";
    }
    else {
      userError = "Couldn't process your request";
    }
    return {
      status: 'failed',
      error: userError
    };
  });
  return response;
};

export const confirmUser = async (email, username, code) => {
  let userError = "";
  let confirm = {
    'email': email,
    'username': username,
    'code': code,
  }
  const response = fetch(
    `${process.env.REACT_APP_API}/user/confirm`, {
        method: 'post',
        headers: {
          'Content-Type':'application/json',
        },
        body: JSON.stringify(confirm)
  })
  .then(response => {
    if (!response.ok) { throw response }
    return response.json();
    })
  .then((data => {
    return data;
  }))
  .catch(error => {
    if (error.status === 429) {
      userError = "Too many requests - please wait and try again later";
    }
    else {
      userError = "Couldn't process your request";
    }
    return {
      status: 'failed',
      error: userError
    };
  });
  return response;
};

export const updateUserPreferences = async (id, preferences) => {
  let userError = "";
  let prefUpdate = {
    'id': id,
    'preferences': preferences
  }
  const token = localStorage.getItem('jwt_token');
  if (token) {
    const response = fetch(
      `${process.env.REACT_APP_API}/user/preferences/update`, {
          method: 'post',
          headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(prefUpdate)
    })
    .then(response => {
      if (!response.ok) { throw response }
      return response.json();
      })
    .then((data => {
      return data;
    }))
    .catch(error => {
      if (error.status === 429) {
        userError = "Too many requests - please wait and try again later";
      }
      else {
        userError = "Couldn't process your request";
      }
      return {
        status: 'failed',
        error: userError
      };
    });
    return response;
  }
  else {
    const response = {
      status: 'failed',
      error: 'Missing JWT',
    }
    return response;
  }
};