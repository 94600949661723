import React, { useState } from 'react';
/* MUI */
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ComputerIcon from '@mui/icons-material/Computer';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TuneIcon from '@mui/icons-material/Tune';
/* moment */
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

function SummaryChip(props: {
  label: string,
  count?: number,
  startTime?: number,
  endTime?: number,
  userAgent?: string,
  icon?: any,
}) {
  const {
    label = '',
    count = -1,
    startTime = 0,
    endTime = 0,
    userAgent = '',
    icon = ''
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        color="neutral"
        disableElevation
        size="small"
        startIcon={
          <Avatar sx={(theme) => ({height: 28, width: 28, backgroundColor: theme.palette.primary.main})}>
            {count !== -1
              ? (
                <Box sx={{fontSize: 11}}>
                  {count > 999 ? `${(count / 1000).toFixed(1)}k` : count}
                </Box>
              )
              : (userAgent !== ''
                ? (userAgent === 'desktop'
                  ? <ComputerIcon fontSize="small" />
                  : userAgent === "mobile"
                    ? <PhoneIphoneIcon fontSize="small" />
                    : <TuneIcon fontSize="small" />
                  )
                  : <>{icon}</>
                )
            }
          </Avatar>
        }
        sx={{textTransform: 'none'}}
        onClick={handleClick}
      >
        <Stack direction="row" sx={{alignItems: 'center'}}>
          <Typography sx={{display: {xs: 'none', md: 'inline'}, fontSize: 12}}>{label}</Typography>
          <ArrowDropDownIcon
            fontSize="small" color={open ? "primary" : ""}
          />
        </Stack>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1.5,
            p: 1,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography sx={{fontSize: 14}}>{label}</Typography>
        {count !== -1 && (<Typography sx={{fontSize: 14}}>{count} pages processed</Typography>)}
        <Stack direction="row" spacing={0.5}>
          <Typography sx={{fontSize: 14, fontWeight: 600}}>Started</Typography>
          <Typography sx={{fontSize: 14}}>
            {moment.unix(startTime).format("MMM Do YYYY h:mm a")}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={0.5}>
          <Typography sx={{fontSize: 14, fontWeight: 600}}>Run Time</Typography>
          <Typography sx={{fontSize: 14}}>{moment.duration(endTime - startTime).milliseconds().toFixed(2)} s</Typography>
        </Stack>
      </Menu>
    </>
  );
}

export default SummaryChip;
