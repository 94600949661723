import React, { useContext, useState } from 'react';
import { UserContext } from "../../global/context";
/* FLD */
import ItunesContent from "./ItunesContent";
import LoaderButton from "../LoaderButton/LoaderButton";
/* MUI */
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Rating from '@mui/material/Rating';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BackspaceIcon from '@mui/icons-material/Backspace';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

function ExpandCell(props: {content: any}) {
  const { content } = props;
  const [open, setOpen] = useState(false);
  
  return (
    <div>
      <Stack direction="row" alignItems="flex-start" spacing={0.25}>
        <Box>
        {open ?
            <>{content}</>
          : 
            <>{content.substring(0,100)}{content.length > 100 ? '...' : ''}</>
        }
        </Box>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>
    </div>
  );
}

function Itunes() {
  const [user] = useContext(UserContext);
  const [itunesData, setItunesData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("software");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [toolTab, setToolTab] = useState(0);

  const fetchItunes = async () => {
    if (searchTerm !== '') {
      setLoading(true);
      setItunesData({});
      fetch(`${process.env.REACT_APP_API}/itunes?search=${encodeURIComponent(searchTerm)}&type=${encodeURIComponent(searchType)}`)
      .then(response => {
        if (!response.ok) { throw response }
        return response.json();
      })
      .then((data => {
        setError("");
        setItunesData(data);
        setLoading(false);
      }))
      .catch(error => {
        if (error.status === 429) {
          setError("Too many requests - please wait and try again later");
        }
        else {
          setError("Couldn't process your request");
        }
        setItunesData({});
        setLoading(false);
      });
    }
  }

  const handleSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanValue = event.target.value
      .replaceAll(/[^A-Za-z0-9 -]/gi, '')
      .substring(0, 100);
    setSearchTerm(cleanValue);
  };

  const handleSearchType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchType(event.target.value);
  };

  const handleReset = () => {
    setSearchTerm("");
    setItunesData({});
    setError('');
  };

  return (
    <div className="Itunes">
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Stack spacing={2}>
            <Box>
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
                sx={{m: 0.5}}
              >
                Start Over
              </Button>
            </Box>
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          </Stack>
        </Container>
      )}
      {itunesData && itunesData.success && (
        itunesData.results.length > 0 ? (
          <Container
            maxWidth="100%"
            disableGutters={true}
            sx={{m: 0, p: 0, height: {xs: 'calc(100vh - 6.75rem)', sm: 'calc(100vh - 7.25rem)'}, width: '100%'}}
          >
            <Stack direction="row" sx={{height: '3rem', alignItems: 'center'}} spacing={1}>
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
                sx={{p: 1, pl: 1.5, pr: 0.5, minWidth: 30}}
              >
                <Box sx={{display: {xs: 'none', sm: 'inline-flex'}}}>Start Over</Box>
              </Button>
              <Chip
                variant="outlined"
                label={`"${itunesData.search}" in ${itunesData.media}`}
              />
            </Stack>
            <Paper variant="outlined">
              {itunesData.media === "software" && (
                  <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 10.5rem)'}}>
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: 60 }}></TableCell>
                          <TableCell sx={{ minWidth: 250 }}>App</TableCell>
                          <TableCell align="center">Description</TableCell>
                          <TableCell align="center">Rating</TableCell>
                          <TableCell align="center">Categories</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itunesData.results.map((row, key) => (
                          <TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                              <Box
                                component="img"
                                src={row.artworkUrl60}
                                loading="lazy"
                                sx={{width: '100%', height: 'auto%', objectFit: 'cover'}}
                              />
                            </TableCell>
                            <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                              <Stack>
                                <Box>{row.trackName}</Box>
                                <Box>
                                  <Typography
                                    component="a"
                                    href={row.sellerUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={{textDecoration: 'none'}}
                                  >
                                    {row.artistName}
                                  </Typography>
                                </Box>
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                              <ExpandCell key={key} content={row.description} />
                            </TableCell>
                            <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                              <Stack sx={{alignItems: 'center'}}>
                                <Rating name="read-only" value={row.averageUserRating} readOnly />
                                <Box>{row.userRatingCount.toLocaleString()} reviews</Box>
                              </Stack>
                            </TableCell>
                            <TableCell sx={{verticalAlign: 'top' }}>
                              <Stack spacing={0.5} direction="row">
                                {row.genres.length > 0 && row.genres.map((genre, key) => (
                                  <Chip key={key} label={genre} />
                                ))}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
              )}
              {itunesData.media === "music" && (
                <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 10.5rem)'}}>
                  <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 60 }}></TableCell>
                        <TableCell sx={{ minWidth: 350 }}>Song</TableCell>
                        <TableCell align="center">Artist</TableCell>
                        <TableCell align="center">Collection</TableCell>
                        <TableCell align="center">Genre</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itunesData.results.length > 0 && itunesData.results.map((row, key) => (
                        <TableRow
                          key={key}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                            <Box
                              component="img"
                              src={row.artworkUrl60}
                              loading="lazy"
                              sx={{width: '100%', height: 'auto%', objectFit: 'cover'}}
                            />
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.trackName}</Box>
                              <Box>{row.trackPrice > 0 ? `$${row.trackPrice}` : 'Free'}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            {row.artistName}
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.collectionName}</Box>
                              <Box>{row.collectionPrice > 0 ? `$${row.collectionPrice}` : 'Free'}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{verticalAlign: 'top' }}>
                            <Chip label={row.primaryGenreName} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {itunesData.media === "movie" && (
                <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 10.5rem)'}}>
                  <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 60 }}></TableCell>
                        <TableCell sx={{ minWidth: 350 }}>Movie</TableCell>
                        <TableCell align="center">Rating</TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">Director</TableCell>
                        <TableCell align="center">Collection</TableCell>
                        <TableCell align="center">Genre</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itunesData.results.length > 0 && itunesData.results.map((row, key) => (
                        <TableRow
                          key={key}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                            <Box
                              component="img"
                              src={row.artworkUrl60}
                              loading="lazy"
                              sx={{width: '100%', height: 'auto%', objectFit: 'cover'}}
                            />
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.trackName}</Box>
                              <Box>{row.trackPrice > 0 ? `$${row.trackPrice}` : 'Free'}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            {row.contentAdvisoryRating}
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <ExpandCell key={key} content={row.longDescription} />
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            {row.artistName}
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.collectionName}</Box>
                              <Box>{row.collectionPrice > 0 ? `$${row.collectionPrice}` : 'Free'}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{verticalAlign: 'top' }}>
                            <Chip label={row.primaryGenreName} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {itunesData.media === "tvShow" && (
                <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 10.5rem)'}}>
                  <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 60 }}></TableCell>
                        <TableCell sx={{ minWidth: 350 }}>Episode</TableCell>
                        <TableCell align="center">Series</TableCell>
                        <TableCell align="center">Rating</TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">Collection</TableCell>
                        <TableCell align="center">Genre</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itunesData.results.length > 0 && itunesData.results.map((row, key) => (
                        <TableRow
                          key={key}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                            <Box
                              component="img"
                              src={row.artworkUrl60}
                              loading="lazy"
                              sx={{width: '100%', height: 'auto%', objectFit: 'cover'}}
                            />
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.trackName}</Box>
                              <Box>{row.trackPrice > 0 ? `$${row.trackPrice}` : 'Free'}</Box>
                              <Box>{row.shortDescription}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.artistName}</Box>
                              <Box>{row.collectionName}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            {row.contentAdvisoryRating}
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <ExpandCell key={key} content={row.longDescription} />
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.collectionName}</Box>
                              <Box>{row.collectionPrice > 0 ? `$${row.collectionPrice}` : 'Free'}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{verticalAlign: 'top' }}>
                            <Chip label={row.primaryGenreName} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {itunesData.media === "podcast" && (
                <TableContainer component={Paper} sx={{m: 0, p: 0, maxHeight: 'calc(100vh - 10.5rem)'}}>
                  <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 60 }}></TableCell>
                        <TableCell sx={{ minWidth: 350 }}>Episode</TableCell>
                        <TableCell align="center">Podcast</TableCell>
                        <TableCell align="center">Collection</TableCell>
                        <TableCell align="center">Genres</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itunesData.results.length > 0 && itunesData.results.map((row, key) => (
                        <TableRow
                          key={key}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{maxWidth: 60, verticalAlign: 'top' }}>
                            <Box
                              component="img"
                              src={row.artworkUrl60}
                              loading="lazy"
                              sx={{width: '100%', height: 'auto%', objectFit: 'cover'}}
                            />
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.trackName}</Box>
                              <Box>{row.trackPrice > 0 ? `$${row.trackPrice}` : 'Free'}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.artistName}</Box>
                              <Box>{row.collectionName}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ minWidth: 150, maxWidth: 500, verticalAlign: 'top' }}>
                            <Stack>
                              <Box>{row.collectionName}</Box>
                              <Box>{row.collectionPrice > 0 ? `$${row.collectionPrice}` : 'Free'}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{verticalAlign: 'top' }}>
                            <Stack spacing={0.5} direction="row">
                              {row.genres.length > 0 && row.genres.map((genre, key) => (
                                <Chip key={key} label={genre} />
                              ))}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Container>
        ) : (
          <Container maxWidth="lg" sx={{mt: 2}}>
            <Stack spacing={2}>
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleReset}
                  startIcon={<BackspaceIcon />}
                  sx={{m: 0.5}}
                >
                  Start Over
                </Button>
              </Box>
              <Alert variant="outlined" severity="warning">
                <Stack direction="row" spacing={0.5}>
                  <Typography>
                    Couldn't find anything for
                  </Typography>
                  <Typography sx={{fontWeight: 900}}>"{searchTerm}"</Typography>
                </Stack>
              </Alert>
            </Stack>
          </Container>
        )
      )}
      {loading === true && (
        <Container maxWidth="100%" sx={{height: 'calc(100vh - 7rem)'}}>
          <Stack>
            <Skeleton variant="rectangular" sx={{ my: 4 }} />
            <Stack spacing={3}>
              {[...Array(4)].map((item, key) => (
                <Stack key={key} direction="row" spacing={4}>
                  <Skeleton variant="circular" width={60} height={60} />
                  {[...Array(4)].map((item, key) => (
                    <Skeleton key={key} variant="text" sx={{ my: 2, mx: 1, flexGrow: 1}} />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Container>
      )}
      {error === "" && loading === false && Object.keys(itunesData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <Stack direction="row">
            <Stack direction="row"
              spacing={1}
              sx={(theme) => ({
                mx: .5,
                p: 1.5,
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                fontSize: 18,
                transform: "skewx(-7.5deg)"
              })}
            >
              <ScreenSearchDesktopIcon />
              <Typography>
                iTunes Search
              </Typography>
            </Stack>
            {false && (<>
            {user.loggedIn ? (
              <Tabs
                value={toolTab}
                onChange={(e, tab) => setToolTab(tab)}
              >
                <Tab icon={<AddCircleIcon fontSize="small" />} iconPosition="start" label="New" sx={{p: 0.5, minHeight: 46}} />
                <Tab icon={<ScheduleIcon fontSize="small" />} iconPosition="start" label="Upcoming" sx={{p: 0.5, minHeight: 46}} />
                <Tab icon={<TaskAltIcon fontSize="small" />} iconPosition="start" label="Complete" sx={{p: 0.5, minHeight: 46}} />
              </Tabs>
            ) : (
              <Tabs value={0}>
                <Tab label="Crawl Now" />
                <Tab disabled label="Schedule Later with Account" />
              </Tabs>
            )}</>)}
          </Stack>
          <Paper variant="outlined" square>
            <Stack direction="row" spacing={2}>
              {(toolTab === 0) && (
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{p: 2, flexGrow: 1}}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={(theme) => ({
                      height: 45,
                      p: 0.75,
                      border: 'solid',
                      borderWidth: 2,
                      borderRadius: 3,
                      borderColor: theme.palette.primary.main,
                      zIndex: 10, 
                      flexGrow: 1,
                    })}
                  >
                    <Box>
                      <FormControl sx={{width: {xs: 90, md: 150}, backgroundColor: 'white', m: 0.5}} size="small">
                        <Select
                          value={searchType}
                          onChange={handleSearchType}
                        >
                          <MenuItem value={"software"}>Software</MenuItem>
                          <MenuItem value={"music"}>Music</MenuItem>
                          <MenuItem value={"movie"}>Movies</MenuItem>
                          <MenuItem value={"tvShow"}>TV Shows</MenuItem>
                          <MenuItem value={"podcast"}>Podcast</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                      <FormControl fullWidth={true}>
                        <InputBase
                          value={searchTerm}
                          fullWidth={true}
                          placeholder="iTunes Search"
                          error={error !== ' '}
                          sx={{height: 45}}
                          onChange={handleSearchTerm}
                          inputProps={{maxLength: 100}}
                        />
                        <FormHelperText sx={{m: 0, mt: 1}}>{error}</FormHelperText>
                      </FormControl>
                    </Box>
                  </Stack>
                  <LoaderButton
                    onClickCallback={fetchItunes}
                    loading={loading}
                    startIcon={<FormatListNumberedIcon />}
                  >
                    Search iTunes
                  </LoaderButton>
                </Stack>
              )}
            </Stack>
          </Paper>
          <ItunesContent/>
        </Container>
      )}
    </div>
  );
}

export default Itunes;
