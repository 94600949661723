import React, { useEffect, useState } from 'react';
/* FLD */
import AddObjects from "./AddObjects";
/* MUI */
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
/* MUI Icons */
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
/* MUI Colors */
import { grey } from '@mui/material/colors';

function DataTree(props: {
  extractorLabels: [],
  elementId: number,
  elementList: {},
  addElementCallback?: (name: string, type: string, parent: number) => void | null,
  renameElementCallback?: (id: number, name: string) => void | null,
  updateElementCallback?: (id: number, value: string, isExtractor?: boolean, label?: string) => void | null,
  deleteElementCallback?: (id: number) => void | null,
}) {
  const {
    extractorLabels = [],
    elementId = '',
    elementList = {},
    addElementCallback = null,
    renameElementCallback = null,
    updateElementCallback = null,
    deleteElementCallback = null,
  } = props;
  const [thisElement, setThisElement] = useState({});

  useEffect(() => {
    setThisElement(
      elementList.find((element) => element.id === elementId)
    );
  }, [elementId, elementList]);

  return (
    <Box>
      {thisElement !== undefined ? (
        <Box>
          <Box>
            {thisElement.id !== -1 ? (
              <Stack direction="row" spacing={0.5}>
                <IconButton
                  sx={{p: 0.25}}
                  onClick={() => deleteElementCallback && deleteElementCallback(elementId)}
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
                <TextField
                  size="small"
                  variant="standard"
                  placeholder={`${thisElement.type} name`}
                  value={thisElement.name}
                  onChange={(e) => renameElementCallback && renameElementCallback(elementId, e.target.value)}
                  sx={{px: 0.25, backgroundColor: grey[50]}}
                />
                <Box>
                  :
                </Box>
                {thisElement.type === 'field' && (
                  <Autocomplete
                    freeSolo
                    clearOnBlur
                    value={thisElement.label}
                    options={[...extractorLabels]}
                    getOptionLabel={(option) => {return option.label || option || ""}}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        updateElementCallback && updateElementCallback(
                          elementId,
                          newValue,
                          false,
                          newValue
                        );
                      }
                      else if (newValue && newValue.inputValue) {
                        updateElementCallback && updateElementCallback(
                          elementId,
                          newValue.inputValue,
                          false,
                          newValue.inputValue
                        );
                      }
                      else if (newValue && newValue.field) {
                        updateElementCallback && updateElementCallback(
                          elementId,
                          newValue.field,
                          true,
                          newValue.label
                        );
                      }
                    }}
                    onInputChange={(e, newValue) => {
                      updateElementCallback && updateElementCallback(
                        elementId,
                        newValue,
                        false,
                        newValue
                      );
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        {option.type === 'string' ? (
                          <Box>
                            {option.label}
                          </Box>
                        ) : (
                          <Box
                            sx={(theme) => ({
                              p: 0.5,
                              borderRadius: 1,
                              borderWidth: 1,
                              borderStyle: 'dotted',
                              borderColor: theme.palette.primary.main,
                              backgroundColor: grey[50],
                              color: theme.palette.primary.main,
                            })}
                          >
                            {option.label}
                          </Box>
                        )}
                      </li>
                    )}
                    size="small"
                    renderInput={(params) => 
                      <TextField
                        {...params}
                        size="small"
                        variant="standard"
                        placeholder="value"
                        sx={{
                          width: 200,
                          px: 0.25,
                          backgroundColor: grey[50],
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <ManageSearchIcon color={thisElement.isExtractor ? 'primary' : grey[800]} />
                            </InputAdornment>
                          )
                        }}
                      />
                    }
                  />
                )}
                <Box>
                  {thisElement.type === 'list' && '['}
                  {thisElement.type === 'object' && '{'}
                </Box>
              </Stack>
            ) : ('')}
          </Box>
          {thisElement.childIds?.length > 0 ? (
            <Stack sx={{ml: 3}} spacing={0.5}>
             {thisElement.childIds.map((child, index) => (
                <DataTree
                  key={index}
                  extractorLabels={extractorLabels}
                  elementId={child}
                  elementList={elementList}
                  addElementCallback={addElementCallback}
                  renameElementCallback={renameElementCallback}
                  updateElementCallback={updateElementCallback}
                  deleteElementCallback={deleteElementCallback}
                />
              ))}
            </Stack>
          ) : ('')}
          {thisElement.type !== 'field' && (
            <Stack sx={{m: 0.25, ml: 3}} direction="row" spacing={0.5} alignItems="center">
              <AddIcon fontSize="small" />
              <AddObjects addElementCallback={addElementCallback} parent={thisElement.id} />
            </Stack>
          )}
          {thisElement.id !== -1 ? (
            <Box sx={{ml: 3.5}}>
              {thisElement.type === 'list' && ']'}
              {thisElement.type === 'object' && '}'}
            </Box>
          ) : ('')}
        </Box>
      ) : ('')}
    </Box>
  );
}

export default DataTree;
