import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';

function ToolSteps(props: {
  progress: any[],
  toolSteps: any[],
  direction?: string,
}) {
  const {
    progress = [],
    toolSteps = [],
    direction = 'column',
  } = props;

  const ProgressConnector = styled(StepConnector) (({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        backgroundImage:
          `linear-gradient(
            to right,
            ${theme.palette.primary.main}
            0% ${progress[progress.length - 1].progress ? Math.round(progress[progress.length - 1].progress) : 100}%,
            ${theme.palette.grey[400]} 
            ${progress[progress.length - 1].progress ? Math.round(progress[progress.length - 1].progress) : 100}% 100%
          )`,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        backgroundColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 1,
      border: 0,
      backgroundColor: theme.palette.grey[400],
      borderRadius: 1,
    },
  }));

  return (
    <>
      {Object.keys(progress).length !== 0 && (
        <Box>
          <Stepper
            activeStep={toolSteps.findIndex((step) => {
              return step.stage === progress[progress.length - 1].stage
            }) || 0}
            sx={{m: {xs: 0, md: 2}, my: 4}}
            alternativeLabel
            connector={<ProgressConnector />}
          >
            {toolSteps.map((step, index) => (
              <Step key={index}>
                <StepLabel>
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Stack spacing={1} direction={direction} sx={{m: 1}}>
            {progress.map((update, key) => (
              key < Object.keys(progress).length - 1
                ? (<Alert severity="success" key={key}>{update.msg ? update.msg : update}</Alert>)
                : (
                  <Alert
                    severity="info"
                    icon={
                      <CircularProgress size={20} />
                    }
                    key={key}
                  >
                    {update.msg ? update.msg : update}
                  </Alert>
                )
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
}

export default ToolSteps;
