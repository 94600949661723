import React, { useState } from 'react';
/* FLD */
import SiteSpeedGraph from '../SiteSpeedGraph/SiteSpeedGraph';
/* MUI */
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import SearchIcon from '@mui/icons-material/Search';
import SpeedIcon from '@mui/icons-material/Speed';

function FetchResources(props: {
  data: {},
}) {
  const {
    data = {},
  } = props;
  const [urlFilter, setUrlFilter] = useState("");
  const [selectedUrlTypes, setSelectedUrlTypes] = useState(['*']);
  const [sortMode, setSortMode] = useState({
    direction: 'asc',
    column: ''
  });

  const searchUrl = (e) => {
    setUrlFilter(e.target.value);
  };

  const handleSort = (col: string) => {
    setSortMode({
      direction: sortMode.direction === 'asc' ? 'desc' : 'asc',
      column: col
    });
  };

  const formatSize = (filesize) => {
    if (filesize > (1024 * 1024)) {
      return `${(filesize / (1024 * 1024)).toPrecision(2)} mb`;
    }
    if (filesize > 1024) {
      return `${(filesize / 1024).toFixed(2)} kb`;
    }
    return `${filesize} b`;
  };

  const ResourceRow = (props: { data: any }) => {
    const { data } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { border: 'none' }}}>
          <TableCell sx={{border: open ? 'none' : ''}}>
            <IconButton
              size="small"
              onClick={() => setOpen(!open)}
            >
              <ExpandCircleDownRoundedIcon
                color={open ? "primary" : ""}
                sx={{transform: open ? '': 'rotate(-90deg)', transition: '100ms'}}
              />
            </IconButton>
          </TableCell>
          <TableCell sx={{wordWrap: 'break-word', border: open ? 'none' : ''}}>
            {data.name}
          </TableCell>
          <TableCell sx={{border: open ? 'none' : ''}}>
            {formatSize(data.decodedBodySize)}
          </TableCell>
          <TableCell sx={{border: open ? 'none' : ''}}>
            {(data.duration/1000).toPrecision(3)} secs
          </TableCell>
        </TableRow>
        <TableRow sx={{border: 'none'}}>
          <TableCell sx={{ py: 0 }} />
          <TableCell sx={{ py: 0 }} colSpan={3}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Stack direction="row">
                <Box>
                  <Typography sx={{fontWeight: "bold", display: "inline", mr: 0.5}}>Type</Typography>{data.initiatorType}
                  <Divider sx={{m: 1}} />
                  <Typography sx={{fontWeight: "bold", display: "inline", mr: 0.5}}>Start</Typography>{data.fetchStart.toPrecision(4)} ms
                  <Divider sx={{m: 1}} />
                  <Typography sx={{fontWeight: "bold", display: "inline", mr: 0.5}}>Speed</Typography>{((data.transferSize / 1000) / (data.duration / 1000)).toPrecision(3)} kbps
                  <Divider sx={{m: 1}} />
                  <Typography sx={{fontWeight: "bold", display: "inline", mr: 0.5}}>Size</Typography>{data.decodedBodySize.toPrecision(4)} bytes
                </Box>
                <SiteSpeedGraph data={data} />
              </Stack>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <TableContainer component={Paper} sx={{maxHeight: 500, minWidth: 700}}>
      <Table stickyHeader size="small" sx={{tableLayout: 'fixed'}}>
        <TableHead>
          <TableRow>
            <TableCell sx={{width: '1rem'}}>
              <SpeedIcon />
            </TableCell>
            <TableCell>
              <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
                <TableSortLabel
                  active={sortMode.column === 'url'}
                  direction={sortMode.direction}
                  onClick={() => handleSort('url')}
                >
                  URL
                </TableSortLabel>
                <TextField
                  placeholder="Filter URLs"
                  onChange={searchUrl}
                  size="small"
                  sx={{width: 400}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {data
                        .filter((resource) => {return resource.name.includes(urlFilter)}).length}
                        /
                        {data.length}
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl sx={{width: 200}} size="small">
                  <InputLabel>Type</InputLabel>
                  <Select
                    multiple
                    value={selectedUrlTypes}
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        setSelectedUrlTypes(['*']);
                      }
                      else if (e.target.value.includes('*') && !selectedUrlTypes.includes('*')) {
                        setSelectedUrlTypes(['*']);
                      }
                      else {
                        setSelectedUrlTypes(e.target.value.filter(item => item !== '*'));
                      }
                    }}
                  >
                    <MenuItem value={"*"}>
                      <Checkbox size="small" sx={{py: 0}} checked={selectedUrlTypes.includes('*')} />
                      All
                    </MenuItem>
                    {[...new Set(
                      data.map((page) => { return page.initiatorType })
                    )].map((urlType, index) => (
                      <MenuItem value={urlType} key={index}>
                        <Checkbox size="small" sx={{py: 0}} checked={selectedUrlTypes.includes(urlType)} />
                        {urlType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </TableCell>
            <TableCell sx={{fontWeight: 900, width: '5rem'}}>
              <TableSortLabel
                active={sortMode.column === 'size'}
                direction={sortMode.direction}
                onClick={() => handleSort('size')}
              >
                Response Size
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{fontWeight: 900, width: '5rem'}}>
              <TableSortLabel
                active={sortMode.column === 'time'}
                direction={sortMode.direction}
                onClick={() => handleSort('time')}
              >
                Download Time
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .filter((resource) => {return resource.name.includes(urlFilter)})
            .filter((resource) => {return selectedUrlTypes.includes(resource.initiatorType) || selectedUrlTypes.includes('*')})
            .sort((a, b) => {
              if (sortMode.column === 'url') {
                if (sortMode.direction === 'asc') {
                  return a.name.localeCompare(b.name, undefined, { numeric: 'true' });
                }
                else {
                  return b.name.localeCompare(a.name, undefined, { numeric: 'true' });
                }
              }
              if (sortMode.column === 'size') {
                if (sortMode.direction === 'asc') {
                  return a.decodedBodySize > b.decodedBodySize;
                }
                else {
                  return a.decodedBodySize < b.decodedBodySize;
                }
              }
              if (sortMode.column === 'time') {
                if (sortMode.direction === 'asc') {
                  return a.duration > b.duration;
                }
                else {
                  return a.duration < b.duration;
                }
              }
              return false;
            })
            .map((resource, key) => (
              <ResourceRow key={key} data={resource} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FetchResources;
