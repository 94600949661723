import React from 'react';
/* MUI */
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
/* MUI Icons */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function StatusChip(props: {
  status: number,
  previousStatus?: number,
}) {
  const {
    status = -1,
    previousStatus,
  } = props;

  return (
    <Chip
      size="small"
      label={
        <Stack direction="row" alignItems="center">
          <Box>{previousStatus}</Box>
          {previousStatus && (
            <ChevronRightIcon sx={{fontSize: 12}} />
          )}
          <Box>{status}</Box>
        </Stack>
      }
      color={status < 400 ? status < 300 ? status < 200 ? status < 0 ? "default" : "error" : "success" : "warning" : "error" }
      sx={{background: previousStatus && status !== previousStatus && "repeating-linear-gradient(45deg, #999, #999 10px, #aaa 10px, #aaa 20px)"}}
    />
  );
}

export default StatusChip;
