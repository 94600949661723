import React from 'react';
/* MUI */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import CodeIcon from '@mui/icons-material/Code';
import DataObjectIcon from '@mui/icons-material/DataObject';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
/* Image */
import schema from '../../assets/schema.png';

function SchemaContent() {
  return (
    <Stack sx={{mb: 5}}>
      <Grid container sx={{mx: {xs: 0, md: 5}, my: 3}}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <DataObjectIcon fontSize="large" sx={(theme) => ({ color: theme.palette.dark.main })} />
              <Typography variant="h1">
                Find Schema
              </Typography>
            </Stack>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Search for pages that include additional structured data mark-up. Structured data can be used by search engines to generate rich search result snippets and more-accurately index site content. Structured data can also include product pricing, images or video, and FAQ tagging.
            </Typography>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Find other sites that use specific schema types and search for content in structured data. Verify that page schema is correctly marked-up and track changes and updates to structured data over time.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <Paper
            component="img"
            src={schema}
            sx={{mx: 5, width: 400, height: 300}}
          />
        </Grid>
      </Grid>
      <Box sx={{m: 5}}>
        <Grid container spacing={5} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <FormatListNumberedIcon color="primary" />
              <Typography variant="h5">
                Sites with Structured Data
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Title, meta description, and thumbnail</li>
                <li>Summary of all structured data types found on each page</li>
                <li>Detailed list of schema properties and data for each schema definition</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <CodeIcon color="primary" />
              <Typography variant="h5">
                Structured Data Formats
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Inline schema mark-up</li>
                <li>Inline microdata tagging</li>
                <li>JSON+LD markup</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}

export default SchemaContent;
