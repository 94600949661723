import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
/* FLD */
import SummaryChip from "../SummaryChip/SummaryChip";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FilterInput from "../FilterInput/FilterInput";
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
/* MUI Icons */
import BackspaceIcon from '@mui/icons-material/Backspace';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import SearchIcon from '@mui/icons-material/Search';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

function TfidfGrid(props: {
  data: {},
  showKeywordInfo?: (keyword) => void,
  handleReset?: () => void,
}) {
  const {
    data = {},
  } = props;
  const [selectedKeywordFilter, setSelectedKeywordFilter] = useState("");
  const [selectedUrlFilter, setSelectedUrlFilter] = useState("");
  const [filteredData, setFilteredData] = useState({
    columns: [],
    data: [],
  });
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'f',
      sort: 'desc',
    },
  ]);

  const columnCompare = (a, b) => {
    const x = a || '';
    const y = b || '';
    if(sortModel[0].sort === 'asc') {
      if(x === '') return 1;
      if(y === '') return -1;
    }
    return x.localeCompare(y, undefined, { numeric: 'true' });
  };

  const frequencyCompare = (a, b) => {
    const x = a['value'] || 0;
    const y = b['value'] || 0;
    if(x === y) return 0;
    if(x < y) return 1;
    if(x > y) return -1;
  };

  const urlCompare = (a, b) => {
    if(a === b) return 0;
    if(a < b) return 1;
    if(a > b) return -1;
  };

  useEffect(() => {
    const cols = [
      {
        field: 'n',
        headerName: 'Keyword',
        renderCell: KeywordCell,
        renderHeader: KeywordHeader,
        width: 200,
        label: 'Keyword',
        key: 'n',
        type: 'string'
      },
      {
        field: 'fr',
        headerName: 'Frequency',
        renderCell: FrequencyCell,
        label: 'Frequency',
        key: 'f',
        type: 'number',
        sortComparator: frequencyCompare
      },
      {
        field: 'r',
        headerName: 'Most Relevant Page',
        renderCell: UrlCell,
        width: 250,
        label: 'Page',
        key: 'r',
        type: 'string',
        sortComparator: columnCompare
      },
    ];
    let mx = 0;
    if(selectedUrlFilter !== '') {
      const urlIDs = ['n', 'r', 'f'];
      data.pageData.forEach((url) => {
        if(url.url.includes(selectedUrlFilter)) {
          urlIDs.push(url.id);
          cols.push(
            {
              field: url.id,
              headerName: url.url,
              width: 110,
              renderCell: ColorCell,
              renderHeader: UrlHeader,
              label: url.url,
              key: url.id,
              type: 'number',
              sortComparator: urlCompare
            }
          );
        }
      });
      const urlFilteredData = data.tfidf.filter((row) => {
        const filteredRow = Object.entries(row).filter((fields) => {
          return urlIDs.includes(fields[0]);
        });
        return filteredRow.length > 3
      });
      const matchingRows = urlFilteredData.filter((row) => {
        if(row.f > mx && row.n.includes(selectedKeywordFilter)) mx = row.f;
        return row.n.includes(selectedKeywordFilter);
      });
      const freqData = matchingRows.map((row) => {
        let freqRow = row;
        freqRow.fr = { maxFrequency: mx, value: row.f}
        return freqRow
      });
      setFilteredData({
        columns: cols,
        data: freqData,
      });
    }
    else {
      data.pageData && data.pageData.forEach((url) => {
        cols.push(
          {
            field: url.id,
            headerName: url.url,
            width: 110,
            renderCell: ColorCell,
            renderHeader: UrlHeader,
            label: url.url,
            key: url.id,
            type: 'number',
            sortComparator: urlCompare
          }
        );
      });
      const matchingRows = data.tfidf.filter((row) => {
        if(row.f > mx && row.n.includes(selectedKeywordFilter)) mx = row.f;
        return row.n.includes(selectedKeywordFilter);
      });
      const freqData = matchingRows.map((row) => {
        let freqRow = row;
        freqRow.fr = { maxFrequency: mx, value: row.f}
        return freqRow
      });
      setFilteredData({
        columns: cols,
        data: freqData,
      });
    }
    data?.pageData[0] && setSortModel([
      {
        field: data.pageData[0].id,
        sort: 'desc',
      },
    ]);
  }, [data, selectedKeywordFilter, selectedUrlFilter]);

  const showKW = (keyword) => {
    props.showKeywordInfo(keyword);
  };

  const KeywordHeader = (props: {value: any}) => {
    return (
      <React.Fragment>
        {selectedKeywordFilter !== '' ? (
          <Stack direction="row" sx={{alignItems: 'center'}}>
            <SearchIcon />
            {`Containing '${selectedKeywordFilter}'`}
          </Stack>
        ) : 'Keyword'}
      </React.Fragment>
    );
  };

  const UrlHeader = (props: {value: any}) => {
    const value = props.colDef.headerName;
    let label = value;
    let link = false;
    try {
      const urlObj = new URL(value);
      if (urlObj['pathname']) {
        label = urlObj['pathname'];
        link = true;
      };
    } catch {};
    return (
      <Tooltip title={label}>
        {link ? (
          <Typography
            sx={{
              textDecoration: 'underline',
              fontSize: '0.9rem',
            }}
          >
            {label}
          </Typography>
        ) : <>{label}</>
        }
      </Tooltip>
    );
  };

  const KeywordCell = (props: {value: any}) => {
    const { value } = props;
    return (
      <React.Fragment>
        <Box>
          <IconButton onClick={() => { showKW(value); }}>
            <TroubleshootIcon />
          </IconButton>
          {value}
        </Box>
      </React.Fragment>
    );
  };

  const UrlCell = (props: {value: any}) => {
    const { value } = props;
    let label = "-";
    let link = false;
    let url = ""
    if(value !== -1 && data.pageData) {
      if(value) {
        label = value;
        try {
          const urlObj = new URL(value);
          if (urlObj['pathname']) {
            label = urlObj['pathname'];
            url = value;
            link = true;
          };
        } catch {};
      }
    }
    return (
      <React.Fragment>
        {link ? (
          <Box>
            <a href={url} target="_blank" rel="noreferrer">{label}</a>
          </Box>
        ) : <>{label}</>
        }
      </React.Fragment>
    );
  };

  const FrequencyCell = (props: {value: any}) => {
    const { value } = props;
    return (
      <React.Fragment>
        <Stack direction="row" sx={{alignItems: 'center'}} spacing={1}>
          <Typography>{value.value}</Typography>
          <Box sx={{width: 40}}>
            <LinearProgress
              variant="determinate"
              value={Math.round((value.value / value.maxFrequency) * 100)}
            />
          </Box>
        </Stack>
      </React.Fragment>
    );
  };

  const ColorCell = (props: {value: any}) => {
    const { value } = props;
    return (
      <React.Fragment>
        {data.maxScore ? (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              backgroundColor: `rgb(255, ${255 - Math.round((Number(value)/Number(data.maxScore)) * 255)}, ${255 - Math.round((Number(value)/Number(data.maxScore)) * 255)})`,
              textAlign: 'center',
              lineHeight: 2.5
            }}
          >
            {!value || value === 0 ? '-' : `${((Number(value)/Number(data.maxScore)) * 100).toFixed(1)}%`}
          </Box>
        ) : (
          <Box>-</Box>
        )}
      </React.Fragment>
    );
  };

  return (
    <Stack sx={{flexGrow: 1}}>
      <Stack direction="row" sx={{m: 0.5, mb: 0, height: {xs: '9rem', md: '3rem'}}}>
        <Stack direction={{xs: 'column', md: 'row'}} sx={{flexGrow: 1, alignItems: {xs: 'left', md: 'center'}}} spacing={1}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              onClick={props.handleReset}
              startIcon={<BackspaceIcon />}
              sx={{p: 1, pl: 1.5, pr: 0.5, minWidth: 30}}
            >
              <Box sx={{display: {xs: 'none', md: 'inline-flex'}}}>Start Over</Box>
            </Button>
            <Box sx={{display: {xs: 'flex', md: 'none'}}}>
              <SummaryChip
                label="Pages"
                count={data.pageData.length}
                startTime={data.startTime}
                endTime={data.endTime}
              />
            </Box>
          </Stack>
          <FilterInput label="Filter Keywords" value={selectedKeywordFilter} filterChangeCallback={(value) => { setSelectedKeywordFilter(value); }} />
          <FilterInput label="Filter URLs" value={selectedUrlFilter} filterChangeCallback={(value) => { setSelectedUrlFilter(value); }} />
          <Box sx={{display: {xs: 'none', md: 'flex'}}}>
            <SummaryChip
              label="Pages"
              count={data.pageData.length}
              startTime={data.startTime}
              endTime={data.endTime}
            />
          </Box>
        </Stack>
        {filteredData.columns && (
          <CSVLink
            headers={filteredData.columns}
            data={filteredData.data}
            filename={"tfidf-analysis.csv"}
            target="_blank"
          >
            <Button
              variant="outlined"
              startIcon={<DownloadForOfflineIcon />}
              sx={{my: 0.5, display: {xs: 'none', md: 'inline-flex'}}}
            >
              Download CSV
            </Button>
          </CSVLink>
        )}
      </Stack>
      <Box>
        <DataGrid sx={{flexGrow: 1, height: {xs: 'calc(100vh - 16rem)', md: 'calc(100vh - 10rem)'}, overflowY: 'auto', backgroundColor: '#FFF'}}
          density="compact"
          columns={filteredData.columns}
          rows={filteredData.data}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          disableColumnFilter={true}
          disableColumnMenu={true}
        />
      </Box>
    </Stack>
  );
}

export default TfidfGrid;
