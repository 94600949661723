import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LanguageIcon from '@mui/icons-material/Language';
import RuleIcon from '@mui/icons-material/Rule';
import PublicIcon from '@mui/icons-material/Public';
import ScheduleIcon from '@mui/icons-material/Schedule';
/* Image */
import altlang from '../../assets/altlang.png';

function AltLangContent() {
  return (
    <Stack sx={{mb: 5}}>
      <Grid container sx={{mx: {xs: 0, md: 5}, my: 3}}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <LanguageIcon fontSize="large" sx={(theme) => ({ color: theme.palette.dark.main })} />
              <Typography variant="h1">
                Alternate Language Check
              </Typography>
            </Stack>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Check your website to ensure your alternate language tags are configured correctly. Alternate language tags identify pages on your international website that are available for specific countries and languages.
            </Typography>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              You can crawl pages automatically using hreflang tags, check URLs from an XML sitemap, or add your own list of pages and verify that hreflang tags are configured correctly.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <Paper
            component="img"
            src={altlang}
            sx={{mx: 5, width: 400, height: 300}}
          />
        </Grid>
      </Grid>
      <Paper sx={(theme) => ({my: 3, px: 5, py: 2, color: 'white', backgroundColor: theme.palette.primary.main})} elevation={3}>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <ScheduleIcon fontSize="large" />
              <Typography variant="h2">
                Scheduled Verification
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul>
              <li>Check international sites daily, weekly, and monthly</li>
              <li>Track international and translated variations over time</li>
              <li>Stay informed about site, technical, and content changes</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3} sx={{textAlign: 'center'}}>
            <Button
              variant="contained"
              color="light"
              component={Link}
              to="/account/new"
            >
              Create Account
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{m: 5}}>
        <Grid container spacing={5} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <AccountTreeIcon color="primary" />
              <Typography variant="h5">
                Site Crawling
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Check alternate language tags from a starting page</li>
                <li>Check tags on a list of URLs</li>
                <li>Fetch an XML sitemap and check alternate</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <PublicIcon color="primary" />
              <Typography variant="h5">
                Country and Language Targeting
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Check which countries have alternate pages</li>
                <li>Find languages defined for each country and URL</li>
                <li>View map of countries with alternates</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <RuleIcon color="primary" />
              <Typography variant="h5">
                Analysis
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Find URLs with missing tags</li>
                <li>Find incorrect tags</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}

export default AltLangContent;
