import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { ApiContext, TasksContext, UserContext } from "../../global/context";
import { removeTask } from "../../api/task";
/* MUI */
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
/* Mui Icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
/* MUI Colors */
import { grey, red } from '@mui/material/colors';
/* moment */
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

function TaskBox(props: {
  taskType?: string,
  icon?: any,
  tab?: number,
}) {
  const {
    taskType = "",
    icon,
    tab = 0,
  } = props;
  const [user] = useContext(UserContext);
  const [tasks] = useContext(TasksContext);
  const { refreshTasks } = useContext(ApiContext);
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [toolTab, setToolTab] = useState(tab);

  useEffect(() => {
    if (user.loggedIn && tasks.length !== 0) {
      if(taskType !== "") {
        setScheduledTasks(tasks.filter((task) => task[0] === taskType && task[1] === 0));
        setCompletedTasks(tasks.filter((task) => task[0] === taskType && (task[1] === 2 || task[1] === -1)));
      }
      else {
        setScheduledTasks(tasks.filter((task) => task[1] === 0));
        setCompletedTasks(tasks.filter((task) => task[1] === 2 || task[1] === -1));
      }
    }
  }, [user, tasks, taskType]);

  useEffect(() => {
    setToolTab(tab);
  }, [tab]);

  const tools = {
    urlfetch: {
      name: 'URL Performance',
      url: '/url-fetch',
    },
    crawl: {
      name: 'Crawler',
      url: '/crawl',
    },
    altlang: {
      name: 'AltLang',
      url: '/alt-lang',
    },
    tfidf: {
      name: 'Content Analysis',
      url: '/tfidf',
    },
    change: {
      name: 'Change Detection',
      url: '/change',
    },
    itunes: {
      name: 'iTunes Search',
      url: '/itunes-search',
    },
    schema: {
      name: 'Find Schema',
      url: '/schema-search',
    },
    none: {
      name: 'No Tasks',
      url: '',
    }
  };

  const deleteTask = (taskId) => {
    removeTask(taskId).then((data) => {
      if(data?.status === 'success') {
        refreshTasks();
      }
    });
  }

  const taskList = (tasks) => {
    return (
      <Box sx={{flexGrow: 1, maxHeight: "30vh", overflowY: "auto", ...props.sx}}>
        {tasks && tasks.map((task, index) => (
          <Paper key={index} sx={{m: 0.25, p: 1.25}} variant="outlined">
            <Stack direction="row">
              <Stack sx={{flexGrow: 1, alignItems:  {xs: 'left', md: 'center'}}} direction={{xs: 'column', md: 'row'}} spacing={1}>
                <Stack direction="row" sx={{ alignItems: 'center'}} spacing={1}>
                  {task[1] === -1 && (
                    <Chip label="Error" color="warning" />
                  )}
                  {task[1] === 0 && (
                    <Chip label="Scheduled" />
                  )}
                  {task[1] === 1 && (
                    <Chip label="In Progress" />
                  )}
                  {task[1] === 2 && (
                    <Chip label="Complete" color="success" />
                  )}
                  <Typography>
                    {tools[task[0]].name}
                  </Typography>
                </Stack>
                {task[1] === -1 && (
                  <Stack>
                    <Typography sx={{color: grey[500]}}>
                      Scheduled for {moment(task[2]).format("MMM Do YYYY h:mm a")}
                    </Typography>
                    <Typography sx={{color: red[500], fontSize: 12}}>
                      Failed at {moment(task[3]).format("MMM Do YYYY h:mm a")}
                    </Typography>
                  </Stack>
                )}
                {task[1] === 0 && (
                  <Typography sx={{color: grey[500]}}>
                    Scheduled for {moment(task[2]).format("MMM Do YYYY h:mm a")}
                  </Typography>
                )}
                {task[1] === 2 && (
                  <Typography sx={{color: grey[500]}}>
                    {moment(task[3]).format("MMM Do YYYY h:mm a")}
                  </Typography>
                )}
              </Stack>
              {task[1] === 2 && (
                <Button
                  variant="outlined"
                  component={Link}
                  to={`${tools[task[0]].url}/${task[4]}`}
                >
                  View Report
                </Button>
              )}
              {task[1] === 0 && (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<EventBusyIcon />}
                  onClick={() => deleteTask(task[4])}
                >
                  Remove
                </Button>
              )}
            </Stack>
          </Paper>
        ))}
        {tasks && Object.keys(tasks).length === 0 ? (
          <Paper sx={{my: 1, p: 2, color: grey[500]}} variant="outlined">
            No tasks to show
            <Button onClick={refreshTasks} size="small" sx={{ml: 2}}>Refresh List</Button>
          </Paper>
        ) : (
          <Box sx={{p: 2, textAlign: 'center'}}>
            <Button onClick={refreshTasks} size="small">Refresh List</Button>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Stack direction={{xs: 'column', md: 'row'}}>
        {taskType !== '' && (
          <Stack direction="row"
            spacing={1}
            sx={(theme) => ({
              mx: .5,
              p: 1.5,
              color: 'white',
              backgroundColor: theme.palette.primary.main,
              fontSize: 18,
              transform: "skewx(-7.5deg)",
              justifyContent: 'center'
            })}
          >
            {icon}
            <Typography>
              {tools[taskType]?.name}
            </Typography>
          </Stack>
        )}
        {user.loggedIn ? (
          <Tabs
            value={toolTab}
            variant="scrollable"
            scrollButtons="auto"
            onChange={(e, tab) => setToolTab(tab)}
          >
            {taskType !== ''
              ? <Tab icon={<AddCircleIcon fontSize="small" />} iconPosition="start" label="New" sx={{p: 0.5, minHeight: 46}} />
              : <Tab
                  iconPosition="start"
                  label={
                    <Box sx={{mr: tasks.length > 0 ? 5 : 0.5}}>
                      <Badge
                        badgeContent={tasks.length}
                        sx={{
                          '& .MuiBadge-badge': {
                            top: 8,
                            right: -20
                          }
                        }} 
                        color="secondary"
                      >
                        All
                      </Badge>
                    </Box>
                  }
                  sx={{p: 0.5, minHeight: 46}}
                />
            }
            <Tab
              icon={<ScheduleIcon fontSize="small" />}
              iconPosition="start"
              label={
                <Box sx={{mr: scheduledTasks.length > 0 ? 5 : 0.5}}>
                  <Badge
                    badgeContent={scheduledTasks.length}
                    sx={{
                      '& .MuiBadge-badge': {
                        top: 8,
                        right: -20
                      }
                    }} 
                    color="secondary"
                  >
                    Upcoming
                  </Badge>
                </Box>
              }
              sx={{p: 0.5, minHeight: 46}}
            />
            <Tab
              icon={<TaskAltIcon fontSize="small" />}
              iconPosition="start"
              label={
                <Box sx={{mr: completedTasks.length > 0 ? 5 : 0.5}}>
                  <Badge
                    badgeContent={completedTasks.length}
                    sx={{
                      '& .MuiBadge-badge': {
                        top: 8,
                        right: -20
                      }
                    }} 
                    color="primary"
                  >
                    Complete
                  </Badge>
                </Box>
              }
              sx={{p: 0.5, minHeight: 46}}
            />
          </Tabs>
        ) : (
          <Tabs 
            variant="scrollable"
            scrollButtons="auto"
            value={0}
          >
            <Tab label="Check Now" />
            <Tab disabled label="Schedule Later with Account" />
          </Tabs>
        )}
      </Stack>
      <Paper variant="outlined" square>
        {(toolTab === 0) && (
          taskType !== '' ? props.children : taskList(tasks)
        )}
        {toolTab === 1 && (
          taskList(scheduledTasks)
        )}
        {toolTab === 2 && (
          taskList(completedTasks)
        )}
      </Paper>
    </Box>
  );
}

export default TaskBox;
