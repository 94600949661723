import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LanIcon from '@mui/icons-material/Lan';
import RuleIcon from '@mui/icons-material/Rule';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
/* Image */
import tfidf from '../../assets/tfidf.png';

function TfidfContent() {
  return (
    <Stack sx={{mb: 5}}>
      <Grid container sx={{mx: {xs: 0, md: 5}, my: 3}}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <TextSnippetIcon fontSize="large" sx={(theme) => ({ color: theme.palette.dark.main })} />
              <Typography variant="h1">
                TF/IDF Content Analysis
              </Typography>
            </Stack>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Analyze content and keywords across a multiple webpages or pieces of content. Analysis includes most-frequently used keywords, most relevant page or document for each keyword, and a breakdown of the most relevant keywords for each page.
            </Typography>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              You can crawl a site automatically, check URLs from an XML sitemap, add a list of URLs, or copy-and-paste individual blocks of text for content analysis.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <Paper
            component="img"
            src={tfidf}
            sx={{mx: 5, width: 400, height: 300}}
          />
        </Grid>
      </Grid>
      <Paper sx={(theme) => ({my: 3, px: 5, py: 2, color: 'white', backgroundColor: theme.palette.primary.main})} elevation={3}>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <ScheduleIcon fontSize="large" />
              <Typography variant="h2">
                Scheduled Analysis
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul>
              <li>Check pages daily, weekly, and monthly</li>
              <li>Track keyword and content changes over time</li>
              <li>Stay informed about site, keyword, and content changes</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3} sx={{textAlign: 'center'}}>
            <Button
              variant="contained"
              color="light"
              component={Link}
              to="/account/new"
            >
              Create Account
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{m: 5}}>
        <Grid container spacing={5} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <AccountTreeIcon color="primary" />
              <Typography variant="h5">
                Analyze Content
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Crawl pages from a starting URL</li>
                <li>Analyze content from a list of URLs</li>
                <li>Fetch an XML sitemap and check content</li>
                <li>Enter two or more pieces of text content</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <LanIcon color="primary" />
              <Typography variant="h5">
                Keywords
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Find commonly-used keywords across all pages</li>
                <li>Find keywords that are unique to each page</li>
                <li>Find the most relevant page for each keyword</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <RuleIcon color="primary" />
              <Typography variant="h5">
                Analysis
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Add and update on-page keyword targets</li>
                <li>Export keyword and URL data to CSV</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}

export default TfidfContent;
