import React, { useState } from 'react';
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
/* MUI Colors */
import { grey } from '@mui/material/colors';

function FetchExtractor(props: {
  extractorTitle?: string,
  extractorItems?: []
}) {
  const {
    extractorTitle = '',
    extractorItems = [],
  } = props;
  const [showExpanded, setShowExpanded] = useState(false);

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} sm={4}>
        <Stack direction="row" alignItems="center">
          {extractorItems.length > 3 && (
            <IconButton
              onClick={() => setShowExpanded(!showExpanded)}
            >
              <ExpandCircleDownRoundedIcon
                color={showExpanded ? "primary" : ""}
                sx={{transform: showExpanded ? '': 'rotate(-90deg)', transition: '100ms'}}
              />
            </IconButton>
          )}
          <Typography variant="h6" sx={{pl: extractorItems.length > 3 ? 0 : {xs: 0, sm: 5}}}>{extractorTitle}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={8} sx={{maxHeight: 250, overflowY: 'auto'}}>
        <Stack spacing={0.5}>
          {showExpanded ? 
            extractorItems && extractorItems.map((value, key) => (
                <Chip key={key} variant="outlined" label={value} sx={{width: 'fit-content'}} />
             ))
           : (
            <>
              {extractorItems && extractorItems.slice(0, 3).map((value, key) => (
                <Chip key={key} variant="outlined" label={value} sx={{width: 'fit-content'}} />
               ))}
              {extractorItems.length > 3 && (
                <Box>
                  <Button
                    onClick={() => setShowExpanded(!showExpanded)}
                  >
                    {`${extractorItems.length - 3} more`}
                  </Button>
                </Box>
              )}
            </>
          )}
          {extractorItems && extractorItems.length === 0 && (
            <Box sx={{color: grey[600], fontStyle: 'italic'}}>
              None
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default FetchExtractor;
