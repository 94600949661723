import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* icons */
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
/* MUI Colors */
import { green, grey, red } from '@mui/material/colors';

function TextCompare(props: {
  diffs: [],
}) {
  const diffData = props.diffs;
  const [expandedBlocks, setExpandedBlocks] = useState([]);
  const [textBlocks, setTextBlocks] = useState([]);

  useEffect(() => {
    const blocks = [];
    const groups = [];
    let lines = [];
    let thisLine = [];
    let lineType = "";
    diffData.forEach((item, index) => {
      item.string.split(/\r?\n/).forEach((line, key) => {
        item.type === "+" && thisLine.push(
          <Typography
            key={`a${index}${key}`}
            variant="span"
            sx={{
              color: green[700],
              fontSize: 14,
              textDecoration: "underline",
              whiteSpace: 'pre-wrap',
            }}
          >
            {line}
          </Typography>
        );
        item.type === "-" && thisLine.push(
          <Typography
            key={`n${index}${key}`}
            variant="span"
            sx={{
              color: red[700],
              fontSize: 14,
              textDecoration: "line-through",
              textDecorationStyle: "dotted",
              whiteSpace: 'pre-wrap',
            }}
          >
            {line}
          </Typography>
        );
        item.type === " " && thisLine.push(
          <Typography
            key={`b${index}${key}`}
            variant="span"
            sx={{
              color: grey[700],
              fontSize: 14,
              whiteSpace: 'pre-wrap',
            }}
          >
            {line}
          </Typography>
        );
        if(key > 0) {
          lines.push(thisLine);
          thisLine = [];
        }
        if(lineType !== item.type) {
          lineType = item.type;
          groups.push(lines);
          lines = [];
        }
      });
    });
    lines.push(thisLine);
    groups.push(lines);
    let lineCount = 0;
    let HighlightCount = 0;
    groups.forEach((lines, key) => {
      if(lines.length < 6 || expandedBlocks.includes(key)) {
        let length = 6;
        if (expandedBlocks.includes(key)) {
          length = lines.length;
        }
        lines.splice(0,length).forEach((line, item) => {
          lineCount += 1;
          HighlightCount += 1;
          blocks.push(renderRow(lineCount, HighlightCount, line, key, `inline${item}`));
        });
      }
      else {
        lines.splice(0,2).forEach((line, item) => {
          lineCount += 1;
          HighlightCount += 1;
          blocks.push(renderRow(lineCount, HighlightCount, line, key, `start${item}`));
        });
        blocks.push(
          <Stack direction="row" key={`expand${key}`}>
            <Box sx={(theme) => ({
              backgroundColor: theme.palette.secondary.main,
              pr: 0.5,
              minWidth: 35,
              height: 30,
              textAlign: 'right',
            })}>
              <IconButton
                size="small"
                onClick={() => {setExpandedBlocks([...expandedBlocks, key])}}
              >
                <ExpandCircleDownIcon
                  fontSize="inherit"
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    transform: 'rotate(-90deg)',
                  })}
                />
              </IconButton>
            </Box>
            <Box sx={(theme) => ({
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.secondary.main,
              height: 30,
              px: 0.5,
              fontSize: 12,
              flexGrow: 1,
              lineHeight: 2.5,
            })}>
              {lines.length - 2} lines hidden with no changes
            </Box>
          </Stack>
        );
        lineCount += lines.length - 2;
        lines.splice(-2).forEach((line, item) => {
          lineCount += 1;
          HighlightCount += 1;
          blocks.push(renderRow(lineCount, HighlightCount, line, key, `end${item}`));
        });
      }
    });
    setTextBlocks(blocks);
  }, [diffData, expandedBlocks]);
  
  const renderRow = (lineNumber, highlightCount, content, key, item) => {
    return (
      <Stack direction="row" key={`${key}${item}`}>
        <Box sx={{
          backgroundColor: highlightCount % 2 ? grey[300] : grey[200],
          pr: 0.5,
          minWidth: 35,
          fontSize: 10,
          textAlign: 'right',
          lineHeight: 1.5
        }}>{lineNumber}</Box>
        <Box sx={{
          backgroundColor: highlightCount % 2 ? grey[100] : 'white',
          px: 0.5,
          fontSize: 14,
          lineBreak: 'auto',
          flexGrow: 1,
          overflowX: 'auto',
        }}>
          {content}
        </Box>
      </Stack>
    );
  };
  
  return (
    <Stack>
      {textBlocks}
    </Stack>
  );
}

export default TextCompare;
