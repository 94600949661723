import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function BulkText(props: {
  textChangeCallback: () => void,
}) {
  const [text, setText] = useState("");
  const [textList, setTextList] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleAdd = () => {
    if(textList.filter(item => item.text === text).length === 0) {
      const newText = {
        id: textList.length,
        text: text,
      }
      const updatedTexts = [...textList, newText];
      setText("");
      setTextList(updatedTexts);
      props.textChangeCallback && props.textChangeCallback(updatedTexts);
    }
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleBlur = () => {
    setHasFocus(false);
  };

  const handleDelete = (id) => {
    setTextList(textList.filter(item => { return item.id !== id; }));
  };

  const handleDeleteAll = () => {
    setTextList([]);
  }

  return (
    <Paper
      onFocus={handleFocus}
      onBlur={handleBlur}
      elevation={hasFocus ? 3 : 0}
      sx={{maxHeight: hasFocus ? 'auto' : '2.5rem'}}
    >
      <FormControl
        sx={{
          display: 'flex',
          zIndex: 999999,
          ...props.sx
        }}
      >
        <Stack direction="row">
          <InputBase
            id="text"
            placeholder="Text"
            value={text}
            sx={{maxHeight: hasFocus ? 'auto' : '2.5rem', m: 1, flexGrow: 1}}
            multiline
            rows={hasFocus ? 3 : 1}
            onChange={handleChange}
          />
          <IconButton
            variant="outlined"
            size="small"
            sx={{height: "calc(3rem - 3px)", width: 50}}
            onClick={handleAdd}
          >
            <AddCircleIcon />
          </IconButton>
          {textList.length > 0 ? (
            <Chip
              label={`${textList.length} Doc${textList.length > 1 ? 's' : ''}`}
              size="small"
              sx={{mt: 1.3, mr: 1}}
              onDelete={handleDeleteAll}
            />
          ): ('')}
        </Stack>
        {hasFocus ? (
          <Stack
            sx={{maxHeight: 150, overflowY: "auto"}}
          >
            {textList.length < 2 && (
              <Typography sx={{m: 0.5, fontSize: 12}}>
                Please add at least two unique docs
              </Typography>
            )}
            {textList.map((txt, index) => (
              <Stack direction="row" alignItems="center" key={index}>
                <IconButton
                  onMouseDown={(e) => {
                    handleDelete(txt.id);
                    e.preventDefault();
                  }}
                  sx={{height: "2rem", width: "2rem"}}
                >
                  <DeleteForeverIcon />
                </IconButton>
                <Typography sx={{fontSize: 14}}>
                  {txt.text}
                </Typography>
                <Chip
                  size="small"
                  label={`${txt.text.length} characters`}
                  sx={{ml: 1}}
                />
              </Stack>
            ))}
          </Stack>
        ) : ('')}
      </FormControl>
    </Paper>
  );
}

export default BulkText;
