import React from 'react';
import Box from '@mui/material/Box';
/* visx */
import { Mercator } from '@visx/geo';
/* topo */
import * as topojson from "topojson-client";
import topology from './world-topo.json';

function WorldMap(props: {
  countries: [],
  size?: string,
}) {
  const {
    countries = [],
    size = 'large'
  } = props;
  const height = size === 'large' ? 250 : 125;
  const width = size === 'large' ? 400 : 175;

  const centerX = width / 2;
  const centerY = height / 2;
  const scale = (width / 600) * 100;

  const world = topojson.feature(topology, topology.objects.units);

  return (
    <Box className="WorldMap">
      <svg width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="transparent"
          rx={14}
        />
        <Mercator
          data={world.features}
          scale={scale}
          translate={[centerX, centerY + (size === 'large' ? 50 : 20)]}
        >
          {(mercator) => (
            <g>
              {mercator.features.map(({ feature, path, centroid }, i) => (
                <React.Fragment key={i}>
                  <path
                    key={`map-feature-${i}`}
                    d={path || ''}
                    fill={
                      countries
                      && countries.filter((country) => {return country.name === feature.properties.name}).length > 0
                      ? "#4ca7ff" : "#CCCCCC"}
                    strokeWidth={0.5}
                    stroke="#FFF"
                  />
                  {countries
                  && countries.filter((country) => {return country.name === feature.properties.name}).length > 0
                  && (
                    <text
                      fill="#222"
                      x={centroid[0]}
                      y={centroid[1]}
                      fontSize={9}
                      textAnchor="middle"
                    >
                      {feature.properties.name}
                    </text>
                  )}
                </React.Fragment>
              ))}
            </g>
          )}
        </Mercator>
      </svg>
    </Box>
  );
}

export default WorldMap;
