import React, { useEffect, useState } from 'react';
/* MUI */
import Box from '@mui/material/Box';
import FilterInput from "../FilterInput/FilterInput";
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
/* MUI Icons */
import SearchIcon from '@mui/icons-material/Search';

function FetchKeywordGrid(props: {
  data: {},
  showKeywordInfo?: (keyword) => void,
  handleReset?: () => void,
}) {
  const {
    data = {},
  } = props;
  const [selectedKeywordFilter, setSelectedKeywordFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'count',
      sort: 'asc',
    },
  ]);

  const frequencyCompare = (a, b) => {
    const x = a?.['value'] || 0;
    const y = b?.['value'] || 0;
    if(x === y) return 0;
    if(x < y) return 1;
    if(x > y) return -1;
  };

  const KeywordHeader = (props: {value: any}) => {
    return (
      <React.Fragment>
        {selectedKeywordFilter !== '' ? (
          <Stack direction="row" sx={{alignItems: 'center'}}>
            <SearchIcon />
            {`Containing '${selectedKeywordFilter}'`}
          </Stack>
        ) : 'Keyword'}
      </React.Fragment>
    );
  };

  const KeywordCell = (props: {value: any}) => {
    const { value } = props;
    return (
      <React.Fragment>
        <Box>
          {value}
        </Box>
      </React.Fragment>
    );
  };

  const FrequencyCell = (props: {value: any}) => {
    const { value } = props;
    return (
      <React.Fragment>
        <Stack direction="row" sx={{alignItems: 'center'}} spacing={1}>
          <Typography>{value?.value}</Typography>
          <Box sx={{width: 100}}>
            <LinearProgress
              variant="determinate"
              value={Math.round((value?.value / value?.maxFrequency) * 100)}
            />
          </Box>
        </Stack>
      </React.Fragment>
    );
  };

  const cols = [
    {
      field: 'count',
      headerName: 'Frequency',
      renderCell: FrequencyCell,
      width: 150,
      label: 'Frequency',
      key: 'count',
      type: 'number',
      sortComparator: frequencyCompare
    },
    {
      field: 'ngram',
      headerName: 'Keyword',
      renderCell: KeywordCell,
      renderHeader: KeywordHeader,
      flex: 1,
      label: 'Keyword',
      key: 'ngram',
      type: 'string'
    },
  ];

  useEffect(() => {
    let mx = 0;
    const matchingRows = data.filter((row) => {
      if(row.count > mx && row.ngram.includes(selectedKeywordFilter)) mx = row.count;
      return row.ngram.includes(selectedKeywordFilter);
    });
    const freqData = matchingRows.map((row) => {
      const freqRow = {
        id: row.id,
        ngram: row.ngram,
        count: {
            maxFrequency: mx,
            value: row.count
          }
        }
      return freqRow
    });
    setFilteredData(freqData);
  }, [data, selectedKeywordFilter]);

  return (
    <Paper variant="outlined">
      <Stack sx={{flexGrow: 1}}>
        <Box sx={{p: 1}}>
          <FilterInput label="Filter Keywords" value={selectedKeywordFilter} filterChangeCallback={(value) => { setSelectedKeywordFilter(value); }} />
        </Box>
        <Box sx={{height: 400}}>
          <DataGrid
            density="compact"
            columns={cols}
            rows={filteredData}
            onSortModelChange={(model) => setSortModel(model)}
            sortModel={sortModel}
            disableColumnMenu={true}
          />
        </Box>
      </Stack>
    </Paper>
  );
}

export default FetchKeywordGrid;
