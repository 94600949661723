import React from 'react';
/* MUI */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
/* MUI Colors */
import { grey } from '@mui/material/colors';
/* Image */
import itunes from '../../assets/itunes.png';

function ItunesContent() {
  return (
    <Stack sx={{mb: 5}}>
      <Grid container sx={{mx: {xs: 0, md: 5}, my: 3}}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <ScreenSearchDesktopIcon fontSize="large" sx={(theme) => ({ color: theme.palette.dark.main })} />
              <Typography variant="h1">
                iTunes* Search
              </Typography>
            </Stack>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Search the App Store for popular apps and get app description, overall ratings, total reviews, and app categories. Track rankings and reviews for your iOS app or check competitors changes and user feedback.
            </Typography>
            <Typography variant="p" sx={{lineHeight: 1.75}}>
              Search the iTunes* store for music, movies, TV shows, and podcasts that match your search keyword. Track changes in popular releases and get summaries and descriptions for popular and trending entertainment.
              <Typography sx={{fontSize: 10, color: grey[600]}}>
              *iTunes is a registered trademark of Apple Inc.
            </Typography>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <Paper
            component="img"
            src={itunes}
            sx={{mx: 5, width: 400, height: 300}}
          />
        </Grid>
      </Grid>
      <Box sx={{m: 5}}>
        <Grid container spacing={5} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <FormatListNumberedIcon color="primary" />
              <Typography variant="h5">
                Results Include
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>Top-ranked results for each category</li>
                <li>Description of app or media</li>
                <li>Average and total ratings</li>
                <li>Categories</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
              <AppShortcutIcon color="primary" />
              <Typography variant="h5">
                Search Apps and Media
              </Typography>
            </Stack>
            <Typography variant="p">
              <ul>
                <li>App Store description, reviews, and categories for top apps</li>
                <li>Top artists, albums, and genres</li>
                <li>Top movies with ratings, directors, and genre</li>
                <li>Popular TV series with ratings, descriptions, and genres</li>
                <li>Most listened-to podcasts with collections and genres</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}

export default ItunesContent;
