import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiContext } from "../../global/context";
import { useNavigate, useParams } from "react-router-dom";
import { socket } from "../../api/socket";
import { createTasks } from "../../api/task";
/* FLD */
import BulkUrls from "../BulkUrls/BulkUrls";
import CrawlContent from "./CrawlContent";
import CrawlGrid from "./CrawlGrid";
import CrawlUrlTree from "./CrawlUrlTree";
import ExtractorPicker from "../ExtractorPicker/ExtractorPicker";
import ScheduleSelect from "../ScheduleSelect/ScheduleSelect";
import StatusChip from "../StatusChip/StatusChip";
import SummaryChip from "../SummaryChip/SummaryChip";
import TaskBox from "../TaskBox/TaskBox";
import ToolSteps from "../ToolSteps/ToolSteps";
import UrlField from "../UrlField/UrlField";
import UserAgentPicker from "../UserAgentPicker/UserAgentPicker";
/* MUI */
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import BackspaceIcon from '@mui/icons-material/Backspace';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HubIcon from '@mui/icons-material/Hub';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import ShareIcon from '@mui/icons-material/Share';

function Crawl() {
  const { refreshTasks } = useContext(ApiContext);
  const [mode, setMode] = useState("crawl");
  const [pageData, setPageData] = useState({});
  const [requestURL, setRequestURL] = useState("");
  const [urlList, setUrlList] = useState([]);
  const [userAgent, setUserAgent] = useState({mode: "desktop"});
  const [customExtractors, setCustomExtractors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [toolTab, setToolTab] = useState(0);
  const [taskSchedule, setTaskSchedule] = useState({});
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [showURLInfo, setShowURLInfo] = useState('');
  const [URLInfo, setURLInfo] = useState({});
  const navigate = useNavigate();

  let taskId = useParams("taskId");

  const toolSteps = [
    {'stage': 'start', 'label': 'Started'},
    {'stage': 'crawl', 'label': 'Crawling'},
    {'stage': 'content', 'label': 'Analyzing Content'},
  ];

  useEffect(() => {
    socket.on("crawl_started", data => {
      setProgress([]);
      setError("");
      setPageData({});
      setLoading(true);
    });
    socket.on("crawl_complete", (data) => {
      setError("");
      setPageData(data);
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("crawl_failed", (data) => {
      setError("Couldn't process your request");
      setPageData({});
      setLoading(false);
      setProgress([]);
      socket.disconnect();
    });
    socket.on("crawl_progress", (data) => {
      setProgress(prev => [...prev, data]);
    });
    return () => {
      socket.off("crawl_started");
      socket.off("crawl_complete");
      socket.off("crawl_failed");
    }
  }, []);

  useEffect(() => {
    socket.on('disconnect', (response) => {
      if(loading && (response === 'transport close' || response === 'transport error')) {
        setLoading(false);
        setError(() => (
          <Stack spacing={2}><Typography sx={{fontWeight: 900}}>Your request took too long to process</Typography>
            <Box>Create an account to crawl larger sites and run longer processes</Box>
            <Button
              variant="contained"
              component={Link}
              to={'/account/new'}
            >
              Create New Account
            </Button>
          </Stack>
        ));
      }
    });
  }, [loading]);

  useEffect(() => {
    if(taskId.taskId && taskId.taskId !== "" && Object.keys(pageData).length === 0) {
      setError("");
      setLoading(true);
      !socket.connected && socket.connect();
      socket.on("disconnect", (reason) => {
        setLoading(false);
        setProgress([]);
      });
      socket.emit("crawl_start", {
        token: localStorage.getItem('jwt_token'),
        config: {mode: 'report'},
        task: taskId.taskId,
      });
    }
  }, [taskId, pageData]);

  const crawlUrl = () => {
    setError("");
    setPageData({});
    setProgress([]);
    !socket.connected && socket.connect();
    socket.on("disconnect", (reason) => {
      //setError(reason);
      setLoading(false);
      setProgress([]);
    });
    socket.emit("crawl_start", {
      token: localStorage.getItem('jwt_token'),
      config: {
        mode: mode,
        urls: mode === 'list' ? urlList : [{id: 0, url: requestURL}],
        userAgent: userAgent.userAgent ? userAgent.userAgent : '',
        extractors: customExtractors,
        notificationTypes: notificationTypes,
      }
    });
  };

  const handleNewSchedule = () => {
    createTasks(
      'crawl',
      {
        mode: mode,
        urls: mode === 'list' ? urlList : [{id: 0, url: requestURL}],
        userAgent: userAgent.userAgent ? userAgent.userAgent : '',
        extractors: customExtractors,
        notificationTypes: notificationTypes,
      },
      taskSchedule,
    )
    .then((data) => {
      if(data.status === 'success') {
        refreshTasks();
        setToolTab(1);
      }
    });
  };

  const handleScheduleChange = (newSchedule) => { setTaskSchedule(newSchedule); };
  const handleURL = (url) => { setRequestURL(url); };
  const handleUrlList = (urls) => { setUrlList(urls); };
  const handleUserAgent = (ua) => { setUserAgent(ua); };
  const handleCustomExtractors = (extractors) => { setCustomExtractors(extractors); };
  const handleNotificationTypes = (pref) => { setNotificationTypes(pref); };
  const handleShowURLInfo = (url: string) => {
    setShowURLInfo(url);
    setURLInfo(
      pageData.crawlData?.find((page) => page.url === url)
    );
  }

  const handleReset = () => {
    setError("");
    setPageData({});
    setLoading(false);
    navigate("/crawl");
  };

  return (
    <Box>
      {error && (
        <Container maxWidth="lg" sx={{mt: 2}}>
          <Stack spacing={2}>
            <Box>
              <Button
                variant="outlined"
                onClick={handleReset}
                startIcon={<BackspaceIcon />}
                sx={{m: 0.5}}
              >
                Start Over
              </Button>
            </Box>
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          </Stack>
        </Container>
      )}
      <Container maxWidth="lg">
        <ToolSteps progress={progress} toolSteps={toolSteps} direction="column-reverse" />
      </Container>
      {error === "" && loading === false && Object.keys(pageData).length === 0 && (
        <Container maxWidth="lg" sx={{mt: 2, textAlign: 'left'}}>
          <TaskBox
            taskType="crawl"
            icon={<CloudDownloadIcon />}
            tab={toolTab}
          >
            <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{p: 2, flexGrow: 1, minWidth: 0}}>
              <Stack
                direction={{xs: 'column', md: 'row'}}
                spacing={1}
                sx={(theme) => ({
                  height: {xs: 'auto', md: 45},
                  p: 0.75,
                  border: 'solid',
                  borderWidth: 2,
                  borderRadius: 3,
                  borderColor: theme.palette.primary.main,
                  zIndex: 10, 
                  flexGrow: 1,
                  minWidth: 0,
                })}
              >
                <ToggleButtonGroup
                  value={mode}
                  exclusive
                  size="small"
                  onChange={(e, mode) => mode !== null && setMode(mode)}
                >
                  <ToggleButton value="crawl">
                    <ShareIcon />
                  </ToggleButton>
                  <ToggleButton value="xml">
                    xml
                  </ToggleButton>
                  <ToggleButton value="list">
                    <PlaylistAddRoundedIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                {(mode === "crawl" || mode === "xml") && (
                  <Box sx={{flexGrow: 1}}>
                    <UrlField urlChangeCallback={handleURL} />
                  </Box>
                )}
                {mode === "list" && (
                  <Box sx={{flexGrow: 1, minWidth: 0}}>
                    <BulkUrls urlChangeCallback={handleUrlList} />
                  </Box>
                )}
                <Stack direction="row" spacing={0.5}>
                  <UserAgentPicker
                    hideResolution={true}
                    userAgentCallback={handleUserAgent}
                  />
                  <ExtractorPicker
                    customExtractorsCallback={handleCustomExtractors}
                  />
                </Stack>
              </Stack>
              <ScheduleSelect
                startIcon={<HubIcon />}
                scheduleChangeCallback={handleScheduleChange}
                startNowCallback={crawlUrl}
                loading={loading}
                setScheduleCallback={handleNewSchedule}
                setNotificationsCallback={handleNotificationTypes}
              />
            </Stack>
          </TaskBox>
          <CrawlContent />
        </Container>
      )}
      {pageData && pageData.success && (
        <Container
          maxWidth="100%"
          disableGutters={true}
          sx={{m: 0, p: 0, height: {xs: 'calc(100vh - 6.75rem)', sm: 'calc(100vh - 7.25rem)'}, width: '100%'}}
        >
          <Box>
            <Stack direction="row" sx={{height: '3rem', alignItems: 'center'}} spacing={1}>
              <Box>
                <Stack direction="row" spacing={2} sx={{p: 0.5}}>
                  <Button
                    variant="outlined"
                    onClick={handleReset}
                    startIcon={<BackspaceIcon />}
                    sx={{p: 1, pl: 1.5, pr: 1, minWidth: 30}}
                  >
                    <Box sx={{display: {xs: 'none', sm: 'inline-flex'}}}>Start Over</Box>
                  </Button>
                </Stack>
              </Box>
              <Box>
                <Tabs
                  value={selectedTab}
                  onChange={(e, tab) => setSelectedTab(tab)}
                  sx={{height: '3rem'}}
                >
                  <Tab label="URL List" />
                  <Tab label="Site Structure" />
                </Tabs>
              </Box>
              <SummaryChip
                label={pageData.crawlData?.[0].url}
                count={Object.keys(pageData.crawlData).length}
                startTime={pageData.startTime}
                endTime={pageData.endTime}
              />
            </Stack>
          </Box>
          {selectedTab === 0 && (
            <CrawlGrid data={pageData} />
          )}
          {selectedTab === 1 && (
            <Stack direction="row">
              <Box sx={{flexGrow: 1, height: 'calc(100vh - 10rem)', overflowY: 'auto'}}>
                {pageData.crawlData?.filter((row) => {
                  const urlPieces = row.url.split("/");
                  return urlPieces.length < 5 && urlPieces[3] === "";
                }).map((domain, index) => (
                  <CrawlUrlTree 
                    key={index}
                    url={domain.url}
                    status={domain.status.current}
                    urlList={pageData.crawlData}
                    showURLCallback={handleShowURLInfo}
                  />
                ))}
              </Box>
              {showURLInfo !== '' ? (
                <Box sx={{width: {xs: 0, md: 318}}}>
                  <Drawer
                    anchor="right"
                    variant="permanent"
                    open={Boolean(showURLInfo)}
                  >
                    <Box sx={{p: 1, width: 300, textAlign: 'left'}}>
                      <Stack spacing={2}>
                        <Button variant="contained" onClick={() => {
                          setShowURLInfo('');
                          setURLInfo({});
                        }}>
                          Close
                        </Button>
                        <Box>
                          <StatusChip status={URLInfo?.status?.current} sx={{mr: 1}} />
                          {URLInfo?.url}
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">Title</Typography>
                          {URLInfo?.title?.current}
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">Meta Description</Typography>
                          {URLInfo?.description?.current}
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">H1 Headings</Typography>
                          <Box sx={{maxHeight: 100, overflowY: 'auto'}}>
                            <Stack spacing={0.5}>
                              {URLInfo?.h1?.map((value, key) => 
                                <Chip key={key} variant="outlined" label={value.current} sx={{width: 'fit-content'}} />
                              )}
                            </Stack>
                          </Box>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">H2 Headings</Typography>
                          <Box sx={{maxHeight: 100, overflowY: 'auto'}}>
                            <Stack spacing={0.5}>
                              {URLInfo?.h2?.map((value, key) =>
                                <Chip key={key} variant="outlined" label={value.current} sx={{width: 'fit-content'}} />
                              )}
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  </Drawer>
                </Box>
              ) : ('')}
            </Stack>
          )}
        </Container>
      )}
    </Box>
  );
}

export default Crawl;
