import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TasksContext, UserContext } from "../../global/context";
import { fetchUserTasks } from "../../api/user";
/* FLD */
import AccountApis from "./AccountApis";
import AccountDomains from "./AccountDomains";
import AccountExtractors from "./AccountExtractors";
import AccountUserAgents from "./AccountUserAgents";
import TaskBox from "../TaskBox/TaskBox";
/* MUI */
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ScheduleIcon from '@mui/icons-material/Schedule';
/* MUI Colors */
import { grey } from '@mui/material/colors';

function Account(props: { logout: () => void }) {
  const [user] = useContext(UserContext);
  const [tasks] = useContext(TasksContext);
  const maxCredits = 40;

  useEffect(() => {
    if(user.loggedIn && user?.tasks?.length === 0) {
      fetchUserTasks();
    }
  }, [user]);

  return (
    <Box>
      <Container maxWidth="lg" sx={{pt: 2, mb: 3}}>
        {user.loggedIn ? (
          <Stack spacing={1}>
            <Stack direction="row" sx={{my: 2}} alignItems="center">
              <Avatar sx={{m: 1}} />
              <Typography sx={{flexGrow: 1}}>
                {user.username}
              </Typography>
              <Button
                variant="outlined"
                onClick={props.logout}
                startIcon={<LogoutIcon />}
              >
                Log Out
              </Button>
            </Stack>
            <Card variant="outlined">
              <Box sx={{m: 2}}>
                <Stack direction="row" spacing={0.5}>
                  <ScheduleIcon color="primary" />
                  <Typography variant="h6" sx={{m: 2}}>History</Typography>
                </Stack>
                <Typography variant="p" sx={{fontSize: 14}}>
                  See how many credits used and scheduled for this month for any jobs that have been completed or will run in the future.
                </Typography>
              </Box>
              {tasks &&
                tasks.length > 0
              ? (
                <Box sx={{maxHeight: "50vh", overflowY: "auto"}}>
                  <Box sx={{mx: 2}}>
                    <Typography variant="h7">
                      Credits Used ({tasks.filter((task) => { return task[1] === 2 }).length})
                      / Scheduled ({tasks.filter((task) => { return task[1] !== 2 }).length})</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Box sx={{width: "90%"}}>
                        <LinearProgress
                          variant="buffer"
                          value={
                            tasks.filter((task) => task[1] === 2).length < maxCredits
                            ? ((tasks.filter((task) => task[1] === 2).length / maxCredits) * 100)
                            : 100
                          }
                          valueBuffer={((tasks.filter((task) => task[1] === 2).length / maxCredits) * 100) + ((tasks.filter((task) => task[1] !== 2).length / maxCredits) * 100)}
                          color={
                            tasks.filter((task) => task[1] === 2).length > (maxCredits * 0.9)
                            || (tasks.filter((task) => task[1] === 2).length + tasks.filter((task) => task[1] !== 2).length) > maxCredits
                            ? tasks.filter((task) => task[1] === 2).length >= maxCredits
                            ? 'error' : 'warning' : 'primary'
                          }
                          sx={{
                            "& .MuiLinearProgress-dashed": {
                            animation: 'none',
                            backgroundImage: 'none',
                            backgroundColor: grey[100]
                            }
                          }}
                        />
                      </Box>
                      <Typography>{tasks.length} / {maxCredits}</Typography>
                    </Stack>
                  </Box>
                  <TaskBox />
                </Box>
              ) : (
                <Paper sx={{my: 1, p: 2, color: grey[500]}} variant="outlined">
                  No History
                </Paper>
              )}
            </Card>
            <Card sx={{p: 2}} variant="outlined">
              <AccountDomains />
            </Card>
            <Card sx={{p: 2}} variant="outlined">
              <AccountUserAgents />
            </Card>
            <Card variant="outlined">
              <AccountExtractors />
            </Card>
            <Card sx={{p: 2}} variant="outlined">
              <AccountApis />
            </Card>
          </Stack>
        ) : (
          <Container maxWidth="sm">
            <Stack spacing={5}>
              <Card variant="outlined" sx={{p:3, textAlign: 'center'}}>
                <Typography sx={{mb: 2}}>
                  Don't have an account yet?
                </Typography>
                <Button
                  variant="contained"
                  component={Link}
                  to={'/account/new'}
                  startIcon={<AddCircleIcon />}
                >
                  Create New Account
                </Button>
              </Card>
              <Card variant="outlined" sx={{p:3, textAlign: 'center'}}>
                <Typography sx={{mb: 2}}>
                  Already have an account?
                </Typography>
                <Button
                  variant="outlined"
                  component={Link}
                  to={'/account/login'}
                  startIcon={<LoginIcon />}
                >
                  Login Now
                </Button>
              </Card>
            </Stack>
          </Container>
        )}
      </Container>
    </Box>
  );
}

export default Account;
