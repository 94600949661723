import React from 'react';
/* MUI */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
/* MUI Icons */
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import GppGoodIcon from '@mui/icons-material/GppGood';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import WarningIcon from '@mui/icons-material/Warning';
/* MUI Colors */
import { lightGreen } from '@mui/material/colors';
import { grey } from '@mui/material/colors';
import { red } from '@mui/material/colors';

function FetchComparison(props: {
  newValue: any,
  oldValue: any,
  type: string,
  label: string,
  target: string,
}) {
  const {
    newValue = undefined,
    oldValue = undefined,
    type = undefined,
    label = '',
    target = 'higher'
  } = props;
  const diffPercent = type === 'number' ? (((newValue - oldValue) / oldValue) * 100) : 0;

  return (
    <Box>
      <Box sx={{display: 'inline-block'}}>
        <Stack
          spacing={1}
          alignItems={type === 'number' ? 'center' : 'left'}
          direction={type === 'number' ? 'row' : 'column'}
        >
          <Box>
            {newValue === undefined
              || (type === 'string' && newValue === '')
            ? (
              <Box sx={{color: grey[600], fontStyle: 'italic'}}>
                None
              </Box>
            ) : (
              `${newValue} ${label}`
            )}
          </Box>
          {oldValue
            && type === 'number'
            && (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  p: 1,
                  borderRadius: 2,
                  backgroundColor: grey[200],
                  fontSize: 14,
                }}
              >
                {diffPercent < 0 && (
                  <ArrowCircleDownIcon sx={{color: target === 'higher' ? red[500] : lightGreen[700]}} />
                )}
                {diffPercent > 0 && (
                  <ArrowCircleUpIcon sx={{color: target === 'higher' ? lightGreen[700] : red[500]}} />
                )}
                {diffPercent === 0 && (
                  <ModeStandbyIcon sx={{color: grey[500]}} />
                )}
                <Box
                  sx={{
                    color: 
                      diffPercent === 0 ? grey[500]
                      : target === 'higher' ? 
                        diffPercent < 0 ? red[500] : lightGreen[700]
                        : diffPercent > 0 ? red[500] : lightGreen[700],
                    fontWeight: 600,
                  }}
                >
                  {type === 'number' && (
                    `${diffPercent.toFixed(1)}%`
                  )}
                </Box>
                <Box>
                  {oldValue} {label}
                </Box>
              </Stack>
            )
          }
          {oldValue && (
            <Box sx={{display: 'flex'}}>
              {type === 'string'
                && newValue !== oldValue
                && (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{
                      p: 1,
                      borderRadius: 2,
                      backgroundColor: grey[200],
                      fontSize: 14,
                    }}
                  >
                    <WarningIcon sx={{color: red[500]}} />
                    <Box>
                      {oldValue}
                    </Box>
                  </Stack>
                )
              }
              {type === 'string'
                && newValue === oldValue
                && (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{
                      p: 1,
                      borderRadius: 2,
                      backgroundColor: grey[200],
                      fontSize: 12,
                    }}
                  >
                    <GppGoodIcon fontSize="small" sx={{color: lightGreen[700]}} />
                    <Box>
                      No Changes
                    </Box>
                  </Stack>
              )}
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

export default FetchComparison;
