import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext, ApiContext } from "../../global/context";
import { fetchUser } from "../../api/user";
/* FLD */
import LoaderButton from "../LoaderButton/LoaderButton";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
/* MUI Colors */
import { red } from '@mui/material/colors';

function AccountLogin(props: { logout: () => void }) {
  const [user, setUser] = useContext(UserContext);
  const { refreshTasks } = useContext(ApiContext);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = () => {
    setLoading(true);
    fetchUser(loginEmail, loginPassword)
    .then((data) => {
      if(data?.status === 'success') {
        localStorage.setItem('jwt_token', data.user.access_token);
        setLoginError("");
        setUser({
          loggedIn: true,
          username: data.user.username,
          userid: data.user.id,
          preferences: data.user.preferences,
        });
        refreshTasks();
        setLoading(false);
        navigate('/account/');
      }
      else {
        setLoginError(data.error);
        setLoading(false);
      }
    });
  };

  return (
    <Container maxWidth="sm">
      {!user.loggedIn ? (
        <Box>
          <Typography
            variant="h1"
            className="slant-header"
            sx={{py: 3}}
          >
            Login to your account
          </Typography>
          <Stack spacing={5} sx={{mb: 3}}>
            <Card variant="outlined" sx={{p: 3, textAlign: 'center'}}>
              <Stack direction="column" spacing={2}>
                <Box>
                  <TextField
                    id="login-email"
                    label="Email"
                    type="email"
                    onChange={(event) => {
                      setLoginEmail(event.target.value);
                    }}
                    sx={{width: 300}}
                  />
                </Box>
                <Box>
                  <TextField
                    id="login-password"
                    label="Password"
                    type="password"
                    onChange={(event) => {
                      setLoginPassword(event.target.value);
                    }}
                    sx={{width: 300}}
                  />
                </Box>
                <Box sx={{fontSize: 10, color: red[700]}}>{loginError}</Box>
                <Box>
                  <LoaderButton
                    variant="contained"
                    onClickCallback={handleLogin}
                    startIcon={<LoginIcon />}
                    loading={loading}
                    sx={{width: 300}}
                  >
                    Login
                  </LoaderButton>
                </Box>
              </Stack>
            </Card>
            <Card variant="outlined" sx={{p:3, textAlign: 'center'}}>
              <Typography sx={{mb: 2}}>
                Don't have an account yet?
              </Typography>
              <Button
                variant="outlined"
                component={Link}
                to={'/account/new'}
                startIcon={<AddCircleIcon />}
              >
                Create New Account
              </Button>
            </Card>
          </Stack>
        </Box>
      ) : (
        <Box sx={{pt: 3}}>
          <Card variant="outlined" sx={{p: 3, textAlign: 'center'}}>
            <Button
              variant="outlined"
              onClick={props.logout}
              startIcon={<LogoutIcon />}
            >
              Log Out
            </Button>
          </Card>
        </Box>
      )}
    </Container>
  );
}

export default AccountLogin;
