import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from "../../global/context";
/* MUI */
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
/* MUI Icons */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
/* MUI Colors */
import { green, orange, red } from '@mui/material/colors';

function UrlField(props: {
  domainOnly: boolean,
  validateCallback: () => void,
  urlChangeCallback: () => void,
  hideSuggestions?: boolean,
  userAgentCallback: () => void,
  value?: string,
}) {
  const {
    domainOnly = false,
    hideSuggestions = false,
    value = '',
  } = props;
  const [user] = useContext(UserContext);
  const [url, setUrl] = useState(value);
  const [error, setError] = useState(" ");
  const [suggestions, setSuggestions] = useState([]);
  const [verifying, setVerifying] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState("");

  useEffect(() => {
    setUrl(value);
  }, [value])

  const handleChange = (newVal) => {
    setVerificationStatus("");
    let validURL = true;
    let cleanURL = newVal
      .replaceAll(/[^-\]_.~!*'();:@&=+$,/?%#[A-z0-9]/gi, '')
      .replace(/http[s]?:\/\/http[s]?:\/\//, 'https://');
    setUrl(cleanURL);
    let parsedURL = null;
    try {
      parsedURL = new URL(newVal);
    }
    catch (e) {
      parsedURL = null;
      validURL = false;
    }
    if(parsedURL !== null) {
      setError(" ");
      if(domainOnly) {
        if(parsedURL.pathname !== "/") {
          validURL = false;
          setError("Domain only");
        }
        else {
          setError(" ");
        }
        parsedURL.pathname = "/";
        cleanURL = parsedURL.toString().substring(0,parsedURL.toString().length - 1);
        setUrl(cleanURL);
      }
      if(parsedURL.protocol === "") {
        validURL = false;
        setError("Missing scheme");
      }
      if(parsedURL.host === "") {
        validURL = false;
        setError("Missing host");
      }
      if(newVal.substring(0,7) !== "http://" && newVal.substring(0,8) !== "https://") {
        //setError("Missing scheme");
      }
    }
    else {
      validURL = false;
      setError("Incomplete URL");
    }
    props.urlChangeCallback && props.urlChangeCallback(cleanURL);
    props.validateCallback && props.validateCallback(validURL);
    if (!hideSuggestions
      && user.preferences
      && user.preferences.domains
      && user.preferences.domains.length > 0
    ) {
      setSuggestions(
        user.preferences.domains.filter((domain) => domain.name.includes(cleanURL))
      );
    }
  };

  const handleFocus = () => {
    url === "" && setUrl("https://");
  };

  const handleBlur = () => {
    url === "https://" && setUrl("");
    setSuggestions([]);
  };

  const selectURL = (e, url: string) => {
    e.preventDefault();
    handleChange(url);
    setSuggestions([]);
  };

  const verifyURL = () => {
    setVerifying(true);
    fetch(
      `${process.env.REACT_APP_API}/url-verify`, {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({url: url})
    })
    .then(response => {
      if (!response.ok) { throw response }
      return response.json();
    })
    .then((data) => {
      setVerificationStatus(data.status);
      setVerifying(false);
    })
    .catch(error => {
      setVerificationStatus('Error');
      setVerifying(false);
    });
  }

  return (
    <FormControl fullWidth={true}>
      <InputBase
        fullWidth={true}
        placeholder="https://"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => handleChange(e.target.value)}
        value={url}
        type="url"
        sx={{height: 45, backgroundColor: 'white'}}
        startAdornment={props.startAdornment}
        endAdornment={
          error === ' ' ? (
            url !== '' && (
              verificationStatus === '' ? (
                <LoadingButton
                  variant="outlined"
                  size="small"
                  sx={{width: 120, p: 0.5, textTransform: 'none', whiteSpace: 'nowrap'}}
                  onClick={verifyURL}
                  loading={verifying}
                >
                  Check URL
                </LoadingButton>
              ) : (
                verificationStatus === 200 ? (
                  <CheckCircleIcon sx={{ color: green[500] }} />
                ) : (
                  <WarningIcon sx={{ color: orange[500] }} />
                )
              )
            )
          ) : (
            <WarningIcon sx={{ color: red[500] }} />
          )
        }
      />
      {suggestions.length > 0 ? (
        <Paper>
          <List dense sx={{m: 0, p: 0}}>
            {suggestions.slice(0,4).map((suggestion, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onMouseDown={(e) => selectURL(e, suggestion.name)} >
                  {suggestion.name}
                  {suggestion.category !== '' ? (
                    <Chip label={suggestion.category} size="small" sx={{ml: 2}} />
                  ) : ('')}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      ) : ('')}
    </FormControl>    
  );
}

export default UrlField;
