import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* icons */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function BulkUrls(props: {
  urlChangeCallback: () => void,
}) {
  const [urls, setUrls] = useState([]);
  const [urlList, setUrlList] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);

  const handleChange = (newVal) => {
    const newUrls = newVal.target.value.split("\n");
    const cleanUrls = [];
    newUrls.forEach((url) => {
      if(url.substring(0,7) !== "http://" && url.substring(0,8) !== "https://") {
        url = `https://${url}`;
      }
      cleanUrls.push(url);
    })
    setUrls(cleanUrls);
  };

  const handleAdd = () => {
    const newList = [];
    const uniqueList = [];
    urls.forEach((url) => {
      if(
        uniqueList.filter(item => item === url).length === 0
        && urlList.filter(item => item.url === url).length === 0
        && url !== "http://"
        && url !== "https://"
      ) {
        uniqueList.push(url);
      }
    })
    uniqueList.forEach((url) => {
      if(urlList.filter(item => item.url === url).length === 0) {
        newList.push(url);
      }
    })
    const newUrls = newList.map((item, index) => {
      return {
        id: (urlList[urlList.length - 1] ? urlList[urlList.length - 1].id + 1 : 0) + index,
        url: item
      }
    });
    const updatedUrls = [...urlList, ...newUrls];
    setUrls([]);
    setUrlList(updatedUrls);
    props.urlChangeCallback && props.urlChangeCallback(updatedUrls);
    setHasFocus(true);
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleBlur = () => {
    setHasFocus(false);
  };

  const handleDelete = (id) => {
    setUrlList(urlList.filter(item => { return item.id !== id; }));
  };

  const handleDeleteAll = () => {
    setUrlList([]);
  }

  return (
    <Paper
      onFocus={handleFocus}
      onBlur={handleBlur}
      elevation={hasFocus ? 3 : 0}
      sx={{maxHeight: hasFocus ? 'auto' : '2.5rem'}}
    >
      <FormControl
        sx={{
          display: 'flex',
          zIndex: 999999,
          ...props.sx
        }}
      >
        <Stack direction="row">
          <InputBase
            id="urls"
            placeholder="URLs"
            value={urls.join("\n")}
            sx={{maxHeight: hasFocus ? 'auto' : '2.5rem', ml: 1, pt: hasFocus ? 1.3 : 1, flexGrow: 1}}
            multiline
            rows={hasFocus ? 3 : 1}
            onChange={handleChange}
          />
          <IconButton
            variant="outlined"
            size="small"
            sx={{height: "calc(3rem - 3px)", width: 50}}
            onClick={handleAdd}
          >
            <AddCircleIcon />
          </IconButton>
          {urlList.length > 0 ? (
            <Chip
              label={`${urlList.length} URL${urlList.length > 1 ? 's' : ''}`}
              size="small"
              sx={{mt: 1.3, mr: 1}}
              onDelete={handleDeleteAll}
            />
          ): ('')}
        </Stack>
        {hasFocus ? (
          <Stack
            sx={{maxHeight: 130, overflow: "auto"}}
          >
            {urlList.map((url, index) => (
              <Stack direction="row" alignItems="center" key={index}>
                <IconButton
                  onMouseDown={(e) => {
                    handleDelete(url.id);
                    e.preventDefault();
                  }}
                  sx={{height: "2rem", width: "2rem"}}
                >
                  <DeleteForeverIcon />
                </IconButton>
                <Typography sx={{fontSize: 14}}>
                  {url.url}
                </Typography>
              </Stack>
            ))}
          </Stack>
        ) : ('')}
      </FormControl>
    </Paper>
  );
}

export default BulkUrls;
