import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { BackendContext, UserContext } from "../../global/context";
import './ScheduleSelect.css';
/* MUI */
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
/* MUI Icons */
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ReportIcon from '@mui/icons-material/Report';
import VerifiedIcon from '@mui/icons-material/Verified';
/* date picker */
import Moment from 'moment';
import { extendMoment } from 'moment-range';
//import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
const moment = extendMoment(Moment);

function ScheduleSelect(props: {
  startIcon?: any,
  loading?: boolean,
  scheduleChangeCallback?: () => void,
  startNowCallback?: () => void,
  setScheduleCallback?: () => void,
  setNotificationsCallback?: () => void,
}) {
  const {
    startIcon = undefined,
    loading = false,
    scheduleChangeCallback = null,
    startNowCallback = null,
    setScheduleCallback = null,
    setNotificationsCallback = null,
  } = props;
  const [user] = useContext(UserContext);
  const [backend] = useContext(BackendContext);
  const [mode, setMode] = useState(startNowCallback ? "now" : "weekly");
  const [anchorEl, setAnchorEl] = useState(null);
  const [highlightedDate, setHighlightedDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMode, setSelectedMode] = useState(startNowCallback ? "now" : "weekly");
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [edited, setEdited] = useState(false);
  const dateButton = useRef();
  const open = Boolean(anchorEl);

  const handleClick = () => {
    setAnchorEl(dateButton.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setToggleMode = (mode) => {
    setMode(mode);
    setEdited(true);
  };

  const handleChange = () => {
  };

  const handleSave = () => {
    setSelectedDate(highlightedDate);
    setSelectedMode(mode);
    setEdited(false);
    handleClose();
    scheduleChangeCallback && scheduleChangeCallback({
      'mode': mode,
      'start': highlightedDate,
    });
    setNotificationsCallback && setNotificationsCallback(notificationTypes);
  };

  const renderDay = (day, selectedDays, pickersDayProps) => {
    const formattedDate = moment(day['$d']).format("YYYY-MM-DD");
    const calendarDate = moment(day['$d'], "ddd  MMM DD YYYY hh:mm:ss Z");

    let dayType = "day";

    if(pickersDayProps.outsideCurrentMonth) {
      dayType = "day-outside";
    }
    else {
      if(calendarDate.isBefore(moment().format("YYYY-MM-DDThh:mm:ssZ"))) {
        dayType = 'day-past';
      }
      if(formattedDate === moment().format("YYYY-MM-DD")) {
        dayType = "today";
      }
      if(calendarDate.isAfter(moment(highlightedDate).format("YYYY-MM-DDThh:mm:ssZ"))) {
        if(mode === "daily" && moment(calendarDate).isBetween(moment(highlightedDate),moment(highlightedDate).add(10, 'd'))) {
          dayType = 'future-highlighted';
        }
        if(mode === "weekly" && moment(calendarDate).day() === moment(highlightedDate).day()) {
          dayType = 'future-highlighted';
        }
        if(mode === "monthly" && moment(calendarDate).date() === moment(highlightedDate).date()) {
          dayType = 'future-highlighted';
        }
      }
      if(formattedDate === highlightedDate) {
        dayType = "day-highlighted";
      }
    }
    return (
      <Box className="ScheduleSelect-calendar-cell" key={formattedDate}>
        <Box className={`ScheduleSelect-calendar-${dayType}`}>
          <div
            onClick={() => {
              if (moment(day['$d']).isAfter(moment())) {
                setEdited(true);
                setHighlightedDate(formattedDate);
              }
            }}
          >
            {day['$D']}
          </div>
        </Box>
      </Box>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonGroup>
        <LoadingButton
          variant="contained"
          sx={{height: '3.5rem'}}
          loading={loading}
          onClick={() => {
            if (selectedMode === 'now') {
              startNowCallback();
            } else {
              if(selectedDate !== null) {
                setScheduleCallback();
              }
              else {
                handleClick();
              }
            }
          }}
          startIcon={startIcon}
          disabled={!backend.connected || anchorEl !== null}
        >
          {backend.connecting ? (
            <Stack>
              <>Connecting</>
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            </Stack>
          ) : (
            !backend.connected ? (
              <>Unavailable</>
            ) : (
              selectedMode === 'now' && startNowCallback ? (
                <Box sx={{whiteSpace: 'nowrap'}}>Start Now</Box>
              ) : (
                selectedDate !== null ? (
                  <Box sx={{whiteSpace: 'nowrap'}}>
                  {selectedMode === 'daily' && (`Daily `)}
                  {selectedMode === 'weekly' && (`Weekly `)}
                  {selectedMode === 'monthly' && (`Monthly `)}
                  {` - ${moment(selectedDate).format("MMM D")}`}
                  </Box>
                ) : (
                  <Box sx={{whiteSpace: 'nowrap'}}>
                    Select date
                  </Box>
                )
              )
            )
          )}
        </LoadingButton>
        <Button
          size="small"
          variant="contained"
          ref={dateButton}
          onClick={handleClick}
          disabled={!backend.connected}
          sx={{height: '3.5rem', m: 0, p: 0}}
        >
          {!startNowCallback ? (
             <Badge color="warning" variant="dot" overlap="circular">
              <CalendarMonthIcon />
            </Badge>
          ) : mode === 'now' ? (
            <CalendarMonthIcon />
          ) : (
             <Badge color="secondary" badgeContent={mode === 'daily' ? 10 : mode === 'weekly' ? 4 : 2} overlap="circular">
              <CalendarMonthIcon />
            </Badge>
          )}
        </Button>
        <Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                minHeight: 400,
                minWidth: 400,
              },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
          >
            <Box sx={{p: 1}}>
              <Stack direction="row">
                <Box sx={{flexGrow: 1}}>
                  <ToggleButtonGroup
                    value={mode}
                    exclusive
                    size="small"
                    onChange={(e, mode) => mode !== null && setToggleMode(mode)}  
                    disabled={!user.loggedIn}
                  >
                    {startNowCallback ? (
                      <ToggleButton value="now">
                        Now
                      </ToggleButton>
                  ) : ('')}
                    <ToggleButton value="daily">
                      Daily
                    </ToggleButton>
                    <ToggleButton value="weekly">
                      Weekly
                    </ToggleButton>
                    <ToggleButton value="monthly">
                      Monthly
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!edited}
                >
                  Save
                </Button>
              </Stack>
              <Box sx={{position: 'relative'}}>
                <Box>
                  <Stack direction="row" spacing={2} sx={{p: 1.5}}>
                    <Box sx={{position: 'relative'}}>
                      <Box
                        sx={{
                          opacity: `${mode === 'now' || !user.loggedIn ? '0.1' : '1'}`,
                          pointerEvents: `${mode === 'now' ? 'none' : 'auto'}`,
                        }}
                      >
                        <CalendarPicker
                          onChange={handleChange}
                          renderDay={renderDay}
                          minDate={moment().format("YYYY-MM-DD")}
                          maxDate={moment().add(3, 'months').format("YYYY-MM-DD")}
                        />
                      </Box>
                      {user?.loggedIn && mode === 'now' && (
                        <Box sx={{
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                        }}>
                          <Stack sx={{flexGrow: 1, px: 8, py: 15, textAlign: 'center'}} spacing={3}>
                            <Box sx={{fontWeight: 700}}>
                              Select refresh interval to schedule future request
                            </Box>
                          </Stack>
                        </Box>
                      )}
                    </Box>
                    {setNotificationsCallback && (
                      <Box
                        sx={{
                          opacity: `${!user.loggedIn ? '0.1' : '1'}`,
                        }}
                      >
                        <Divider orientation="vertical" flexItem />
                        <Stack
                          spacing={1}
                          sx={{
                            mr: 1.5,
                            pt: 1.5,
                          }}
                        >
                          <Box
                            sx={{
                              my: 1,
                              fontSize: 16,
                              fontWeight: 600,
                            }}
                          >
                            Email Notifications
                          </Box>
                          <Stack direction="row" alignItems="center">
                            <FormControlLabel
                              label="Task Succeeded"
                              sx={{flexGrow: 1}}
                              control={
                                <Checkbox
                                  checked={notificationTypes.includes('task-succeeded')}
                                  onChange={() => {
                                    setNotificationTypes(
                                      notificationTypes.includes('task-succeeded') ?
                                      notificationTypes.filter((type) => type !== 'task-succeeded')
                                      : [...notificationTypes, 'task-succeeded']
                                    );
                                    setEdited(true);
                                  }}
                                />
                              }
                            />
                            <VerifiedIcon color="success" />
                          </Stack>
                          <Stack direction="row" alignItems="center">
                            <FormControlLabel
                              label="Major Changes"
                              sx={{flexGrow: 1}}
                              disabled={mode === 'now'}
                              control={
                                <Checkbox
                                  checked={notificationTypes.includes('major-changes')}
                                  onChange={() => {
                                    setNotificationTypes(
                                      notificationTypes.includes('major-changes') ?
                                      notificationTypes.filter((type) => type !== 'major-changes')
                                      : [...notificationTypes, 'major-changes']
                                    );
                                    setEdited(true);
                                  }}
                                />
                              }
                            />
                            <ChangeCircleIcon color="warning" />
                          </Stack>
                          <Stack direction="row" alignItems="center">
                            <FormControlLabel
                              label="Task Failed"
                              sx={{flexGrow: 1}}
                              control={
                                <Checkbox
                                  checked={notificationTypes.includes('task-failed')}
                                  onChange={() => {
                                    setNotificationTypes(
                                      notificationTypes.includes('task-failed') ?
                                      notificationTypes.filter((type) => type !== 'task-failed')
                                      : [...notificationTypes, 'task-failed']
                                    );
                                    setEdited(true);
                                  }}
                                />
                              }
                            />
                            <ReportIcon color="error" />
                          </Stack>
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Box>
                {!user.loggedIn && (
                  <Box sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}>
                    <Stack sx={{flexGrow: 1, px: 10, py: 8, textAlign: 'center'}} spacing={3}>
                      <Box sx={{fontWeight: 700}}>
                        Please create an account now to schedule future requests
                      </Box>
                      <Button
                        component={Link}
                        to={'/account/new'}
                        variant="contained"
                      >
                        Create Account
                      </Button>
                    </Stack>
                  </Box>
                )}
              </Box>
            </Box>
          </Menu>
        </Box>
      </ButtonGroup>
    </LocalizationProvider>
  );
}

export default ScheduleSelect;
