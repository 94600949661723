
export const createTasks = async (type, config, schedule) => {
  let taskError = "";
  const token = localStorage.getItem('jwt_token');

  if(token) {
    let tasks = {
      'type': type,
      'config': config,
      'schedule': schedule,
    }
    const response = fetch(
      `${process.env.REACT_APP_API}/user/tasks/add`, {
          method: 'post',
          headers: {'Content-Type':'application/json',
                    'Authorization': `Bearer ${token}`},
          body: JSON.stringify(tasks)
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch(error => {
      if (error.status === 429) {
        taskError = "Too many requests - please wait and try again later";
      }
      else {
        taskError = "Couldn't process your request";
      }
    });
    return response;
  }
  else {
    const response = {
      status: 'failed',
      error: 'Missing JWT',
    }
    return response;
  }
};

export const removeTask = async (taskId) => {
  let taskError = "";
  const token = localStorage.getItem('jwt_token');

  if(token) {
    let tasks = {
      'id': taskId,
    }
    const response = fetch(
      `${process.env.REACT_APP_API}/user/tasks/remove`, {
          method: 'post',
          headers: {'Content-Type':'application/json',
                    'Authorization': `Bearer ${token}`},
          body: JSON.stringify(tasks)
    })
    .then((response) => {
      if (!response.ok) { throw response }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch(error => {
      if (error.status === 429) {
        taskError = "Too many requests - please wait and try again later";
      }
      else {
        taskError = "Couldn't process your request";
      }
    });
    return response;
  }
  else {
    const response = {
      status: 'failed',
      error: 'Missing JWT',
    }
    return response;
  }
};
